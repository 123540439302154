import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Card,
    CardImg,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api, { urlAPI } from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const CampaignList = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [campaign, setCampaign] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [campaignView, setCampaignView] = useState({});

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    useEffect(
        function () {
            async function loadCampaign() {
                const userType = Number(
                    window.localStorage.getItem(TOKEN_USERTYPE)
                );
                const branchId = Number(
                    window.localStorage.getItem(TOKEN_BRANCHID)
                );

                const responseGetCampaign = await api.get(
                    `/campaign${
                        userType === 3
                            ? "-tabloid/" + branchId
                            : userType !== 0
                            ? `-by-branch-all/${branchId}`
                            : "/all"
                    }`
                );
                if (responseGetCampaign.data.error) {
                    alert("Erro ao buscar as Camapnhas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetCampaign.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else setCampaign(responseGetCampaign.data.data);
                }
            }

            loadCampaign();
        },
        [props.history]
    );

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações da Campanha</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Nome: {campaignView.name}</p>
                        <Card
                            style={{
                                marginBottom: 40,
                            }}
                        >
                            <CardImg
                                top
                                src={`${urlAPI}/${
                                    campaignView.cover?.split("/")[
                                        campaignView.cover?.split("/").length -
                                            1
                                    ]
                                }`}
                                alt={campaignView.name}
                            />
                            <Card.Body>
                                <Card.Title>Preview da Imagem.</Card.Title>
                            </Card.Body>
                        </Card>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Campanhas
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome",
                                        sortable: true,
                                        selector: "name",
                                    },

                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <ButtonGroup aria-label="Basic example">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            setCampaignView(
                                                                cellInfo
                                                            );
                                                            handleShowModalInfo();
                                                        }}
                                                    >
                                                        Visualizar
                                                    </Button>
                                                    {/*<Button variant="secondary">Editar</Button>*/}
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => {
                                                            var trash = window.confirm(
                                                                "Para Excluir pressione OK!"
                                                            );
                                                            if (
                                                                trash === true
                                                            ) {
                                                                async function deleteCampaign(
                                                                    id
                                                                ) {
                                                                    try {
                                                                        const responseDeleteCampaign = await api.delete(
                                                                            `/campaign/${id}`
                                                                        );
                                                                        if (
                                                                            responseDeleteCampaign.status ===
                                                                            204
                                                                        ) {
                                                                            setShowAlert(
                                                                                true
                                                                            );
                                                                            setAlertTitle(
                                                                                "Campanha excluída com sucesso!"
                                                                            );
                                                                            setAlertText(
                                                                                `A Campanha ${cellInfo.name} foi excluída com sucesso!`
                                                                            );
                                                                            setAlertVariant(
                                                                                "success"
                                                                            );

                                                                            const updatedList = campaign.filter(
                                                                                (
                                                                                    itemCampaign,
                                                                                    index
                                                                                ) =>
                                                                                    itemCampaign.id !==
                                                                                    cellInfo.id
                                                                            );
                                                                            setCampaign(
                                                                                updatedList
                                                                            );
                                                                        }
                                                                    } catch (err) {
                                                                        setShowAlert(
                                                                            true
                                                                        );
                                                                        setAlertTitle(
                                                                            "Não foi possível excluir a campanha!"
                                                                        );
                                                                        setAlertText(
                                                                            `A Campanha ${cellInfo.name} não pode ser excluída. Contate o suporte!`
                                                                        );
                                                                        setAlertVariant(
                                                                            "danger"
                                                                        );
                                                                    }
                                                                }
                                                                deleteCampaign(
                                                                    cellInfo.id
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        },
                                    },
                                ]}
                                data={campaign}
                            />
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(CampaignList);
