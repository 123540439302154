import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Table,
} from "react-bootstrap";

import Select from "react-select";
import CurrencyInput from "react-currency-input";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const BigListCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [products, setProducts] = useState([]);

    const [bigListName, setBigListName] = useState(null);

    // const [adLink, setAdLink] = useState(null);
    // const [branchSelected, setBranchSelected] = useState(null);
    // const [file, setFile] = useState(null);
    // const [showBranches, setShowBranches] = useState(false);

    const [productsToBigList, setProductsToBigList] = useState([]);

    useEffect(
        function () {
            // async function getBranches() {
            //     const responseGetBranches = await api.get("/branch");
            //     if (responseGetBranches.data.error) {
            //         alert("Erro ao buscar os CPFs. Contate o suporte!");
            //         setTimeout(function () {
            //             props.history.push("/dashboard");
            //         }, 2000);
            //     } else {
            //         setBranches(responseGetBranches.data.data);
            //         setShowBranches(true);
            //     }
            // }

            // const userType = Number(
            //     window.localStorage.getItem(TOKEN_USERTYPE)
            // );
            // const branchId = Number(
            //     window.localStorage.getItem(TOKEN_BRANCHID)
            // );
            // if (userType === 1) {
            //     setBranchSelected(branchId);
            //     setShowBranches(false);
            // } else {
            //     getBranches();
            // }

            async function getProducts() {
                const responseGetProducts = await api.get(`/product`);
                if (responseGetProducts.data.error) {
                    alert("Erro ao buscar os produtos. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    let productsToSelect = [
                        { value: "", label: "Selecione um produto" },
                    ];
                    await responseGetProducts.data.data.map((product) =>
                        productsToSelect.push({
                            value: product.id,
                            label: product.name,
                            price: product.price,
                            code: product.code,
                        })
                    );
                    setProducts((oldArray) => [
                        ...oldArray,
                        ...productsToSelect,
                    ]);
                }
            }
            getProducts();
        },
        [props.history]
    );

    function addProductToBigList(e) {
        setProductsToBigList((prevState) => [
            ...prevState,
            { product: "", price: "" },
        ]);
    }

    // function handleChange(e) {
    //     if (["name", "age"].includes(e.target.className.split(" ")[0])) {
    //         let products = [...productsToBigList];
    //         products[e.target.dataset.id][e.target.className.split(" ")[0]] =
    //             e.target.value;
    //         setProductsToBigList(products);
    //     }
    // }

    function handleDeleteLine(id) {
        let products = [...productsToBigList];
        setProductsToBigList(products);
    }

    function handleSubmit(e) {
        e.preventDefault();

        console.log(productsToBigList);

        //     const formData = new FormData();
        //     formData.append("image_ad", file);
        //     formData.append("name", adName);
        //     if (adLink !== null) {
        //         formData.append("link", adLink);
        //     }
        //     formData.append("branch", branchSelected);

        //     async function postAdCreate() {
        //         const responsePostAdCreate = await api.post("/ad", formData);

        //         if (
        //             responsePostAdCreate.data.error ||
        //             responsePostAdCreate.status === 500
        //         ) {
        //             setShowAlert(true);
        //             setAlertTitle("Problemas ao criar o anúncio.");
        //             setAlertText(
        //                 `Tivemos problemas ao tentar criar o anúncio para a empresa selecionada. Por favor contate o suporte!`
        //             );
        //             setAlertVariant("danger");
        //         } else {
        //             setShowAlert(true);
        //             setAlertTitle("Anúncio criado com sucesso!");
        //             setAlertText(
        //                 `Voce sera automaticamente direcionado para a lista`
        //             );
        //             setAlertVariant("success");

        //             setTimeout(() => {
        //                 props.history.push("/ad-list");
        //             }, 5000);
        //         }
        //     }

        //     postAdCreate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                                closeAlert={() => setShowAlert(false)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={bigListName}
                                        onChange={(e) => {
                                            setBigListName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome desejado para o listão.
                                    </Form.Text>
                                </Form.Group>

                                <Button
                                    variant="primary"
                                    onClick={addProductToBigList}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    Adicionar produto
                                </Button>

                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th width="10%">#</th>
                                            <th width="40%">Produto</th>
                                            <th width="40%">Preço</th>
                                            <th width="10%">Excluir</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productsToBigList.map((val, idx) => {
                                            let productId = `product-${idx}`,
                                                priceId = `price-${idx}`;
                                            return (
                                                <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td>
                                                        <Select
                                                            placeholder="Selecione um produto"
                                                            name={productId}
                                                            data-id={idx}
                                                            id={productId}
                                                            className="product"
                                                            value={
                                                                productsToBigList[
                                                                    idx
                                                                ].product
                                                            }
                                                            onChange={(
                                                                selectedOption
                                                            ) => {
                                                                let products = [
                                                                    ...productsToBigList,
                                                                ];
                                                                products[
                                                                    idx
                                                                ].product = selectedOption;
                                                                setProductsToBigList(
                                                                    products
                                                                );
                                                            }}
                                                            options={products}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={
                                                                productsToBigList[
                                                                    idx
                                                                ].price
                                                            }
                                                            onChangeEvent={(
                                                                event,
                                                                maskedvalue,
                                                                floatvalue
                                                            ) => {
                                                                let products = [
                                                                    ...productsToBigList,
                                                                ];
                                                                products[
                                                                    idx
                                                                ].price = floatvalue;
                                                                setProductsToBigList(
                                                                    products
                                                                );
                                                            }}
                                                        />
                                                        {/* <Form.Control
                                                            type="text"
                                                            name={priceId}
                                                            data-id={idx}
                                                            id={priceId}
                                                            className="price"
                                                            value={
                                                                productsToBigList[
                                                                    idx
                                                                ].price
                                                            }
                                                            // onChange={
                                                            //     handleChange
                                                            // }
                                                        /> */}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            variant="outline-danger"
                                                            onClick={() =>
                                                                handleDeleteLine(
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>

                                <Button variant="success" type="submit">
                                    Gerar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o código do produto
                                                    corretamente.
                                                </li>
                                                <li>
                                                    - Digite o preço
                                                    corretamente para não haver
                                                    erros na divulgação.
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(BigListCreate);
