import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@empd/reactable'
import '@empd/reactable/lib/styles.css'

import { Container, Row, Col, ButtonGroup, Button, Breadcrumb, Spinner, Alert, Modal, Image } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import ShowInfo from '../../components/ShowInfo'

import api from "../../service/api";

const DirectMailList = (props) => {
    const [directMails, setDirectMails] = useState([])
    const [emptyDataElement, setEmptyDataElement] = useState(<Spinner animation="grow" />)
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [directMailView, setDirectMailView] = useState({});
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('') 

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);
    
    useEffect(function () {
        async function loadDirectMails () {
            const responseDirectMails = await api.get('/direct-mail') 
            if (responseDirectMails.data.error) {
                alert('Erro ao buscar as malas direta. Contate o suporte!')
                setTimeout(function () {
                    props.history.push('/dashboard')
                }, 2000)
            } else {
                if (responseDirectMails.data.data.length === 0)
                    setEmptyDataElement(
                        <Alert variant="dark">
                            Não existem registros.
                        </Alert>
                    )
                else {
                    const getDirectMailsBeautiful = async () => {
                        return Promise.all(responseDirectMails.data.data.map((directMailItem, index) => ({...directMailItem, branchEmail: directMailItem.branch.email})))
                    }
                    getDirectMailsBeautiful().then((directMailsBeautiful) => {
                        setDirectMails(directMailsBeautiful)
                    })
                }                    
            }
        }

        loadDirectMails()
    }, [props.history])

    return (
        <>
            <CustomNavbar/>
            <main>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mala Direta</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Image
                            src={'https://rxy-imagens.s3.amazonaws.com/mala_direta_atacado_colchoes_backgrounds/' + directMailView.file}
                            className="img-fluid"
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                                closeAlert={() => setShowAlert(false)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/dashboard')}
                            >
                                Dashboard
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Listar Malas Diretas</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}                                
                                columns={
                                    [
                                        {
                                            name: 'Nome',
                                            sortable: true,
                                            selector: 'name'
                                        },
                                        {
                                            name: 'E-mail Empresa',
                                            sortable: true,
                                            selector: 'branchEmail'
                                        },
                                        {
                                            name: 'Opções',
                                            cell: function (cellInfo) {
                                                return (
                                                    <ButtonGroup aria-label="Basic example">
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                setDirectMailView(cellInfo)
                                                                handleShowModalInfo()
                                                            }}
                                                        >
                                                            Visualizar
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                var trash = window.confirm('Para Excluir pressione OK!')
                                                                if(trash === true){
                                                                    async function deleteDirectMail(id) {
                                                                        try {
                                                                            const responseDeleteLooseDirectMailBackground = await api.delete(`/direct-mail/${id}`)
                                                                            if (responseDeleteLooseDirectMailBackground.status === 204) {
                                                                                setShowAlert(true)
                                                                                setAlertTitle('Mala Direta excluída com sucesso!')
                                                                                setAlertText(`A Mala Direta ${cellInfo.name} foi excluída com sucesso!`)
                                                                                setAlertVariant('success')

                                                                                const updatedList = directMails.filter((itemDirectMail, index) => itemDirectMail.id !== cellInfo.id)
                                                                                setDirectMails(updatedList)
                                                                            }
                                                                        } catch (err) {
                                                                            setShowAlert(true)
                                                                            setAlertTitle('Não foi possível excluir a a Mala Direta!')
                                                                            setAlertText(`A Mala Direta ${cellInfo.email} não pode ser excluída. Contate o suporte!`)
                                                                            setAlertVariant('danger')
                                                                        }
                                                                    }
                                                                    deleteDirectMail(cellInfo.id)
                                                                }
                                                                
                                                            }}
                                                        >                                                        
                                                            Excluir
                                                        </Button>
                                                    </ButtonGroup>
                                                )
                                            }
                                        }
                                    ]
                                }
                                data={directMails}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(DirectMailList)