import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

import TabloidItem from "../TabloidItem";
import TabloidItemAdd from "../TabloidItemAdd";

function TabloidItemList(props) {
    const [items, setItems] = useState([]);
    const [baseItemsIsSeted, setBaseItemsIsSeted] = useState(false);

    useEffect(() => {
        if (props.baseItems.length > 0) {
            const isUpdated = JSON.parse(
                window.localStorage.getItem("isUpdated")
            );

            if (!baseItemsIsSeted) {
                setItems(props.baseItems);
                setBaseItemsIsSeted(true);
            }

            if (isUpdated) {
                setItems(props.baseItems);
                setBaseItemsIsSeted(true);
                
                window.localStorage.setItem("isUpdated", false)
            }
        } else {
            const tabloidItems = JSON.parse(
                window.localStorage.getItem("tabloidItems")
            );
            if (tabloidItems !== null) setItems(tabloidItems);
        }
    }, [baseItemsIsSeted, props, props.baseItems]);

    function removeProductOrAd(index) {
        const remove = async (index) => {
            const removeConfirm = window.confirm(
                "Deseja realmente remover este produto/anúncio?"
            );

            if (removeConfirm) {
                let tabloidItems = items.slice();
                tabloidItems.splice(index, 1);
                if (tabloidItems.length <= 0) {
                    setItems([]);
                    window.localStorage.removeItem("tabloidItems");
                } else {
                    setItems(tabloidItems);
                    window.localStorage.setItem(
                        "tabloidItems",
                        JSON.stringify(tabloidItems)
                    );
                }
            }
        };

        remove(index);
    }

    function moveUp(indexItem) {
        let tabloidItems = items.slice();
        tabloidItems.splice(
            indexItem - 1 < 0 ? tabloidItems.length : indexItem - 1,
            0,
            tabloidItems.splice(indexItem, 1)[0]
        );
        setItems(tabloidItems);
        window.localStorage.setItem(
            "tabloidItems",
            JSON.stringify(tabloidItems)
        );
    }

    function moveDown(indexItem) {
        let tabloidItems = items.slice();
        tabloidItems.splice(
            indexItem + 1 === tabloidItems.length ? 0 : indexItem + 1,
            0,
            tabloidItems.splice(indexItem, 1)[0]
        );
        setItems(tabloidItems);
        window.localStorage.setItem(
            "tabloidItems",
            JSON.stringify(tabloidItems)
        );
    }

    function edit(indexItem) {
        refreshItems();
    }

    function refreshItems() {
        const tabloidItems = JSON.parse(
            window.localStorage.getItem("tabloidItems")
        );
        setItems(tabloidItems);
    }

    return (
        <>
            <Row>
                <Col md="9">
                    <h3>Produtos</h3>
                </Col>
                <Col md="3">
                    <TabloidItemAdd
                        baseData={props.baseData}
                        onAdd={() => {
                            refreshItems();
                        }}
                    />
                </Col>
            </Row>
            {items.map((item, index) => (
                <TabloidItem
                    key={index}
                    index={index}
                    item={item}
                    onRemove={removeProductOrAd}
                    onMoveUp={moveUp}
                    onMoveDown={moveDown}
                    onEdit={edit}
                    baseData={props.baseData}
                    creating={props.creating}
                />
            ))}
        </>
    );
}

export default TabloidItemList;
