import React, { useState, useRef, useEffect } from "react";

import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import CurrencyInput from "react-currency-input";

import api from "../../service/api";
import { TOKEN_BRANCHID } from "../../service/tokens";

function TabloidItemEdit(props) {
    const [baseData, setBaseData] = useState(props.baseData);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isFirstRun = useRef(true);
    useEffect(() => {
        setBaseData(props.baseData);
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
    }, [props.baseData, props.history]);

    const [adOrProduct, setAdOrProduct] = useState(undefined);

    // [{value: id, label: name, campoAMais: value}]
    const [ads, setAds] = useState([
        { id: 0, name: "Escolha..." },
        { id: 1, name: "Teste" },
        { id: 2, name: "Teste 2" },
        { id: 3, name: "Teste 3" },
    ]);
    const [campaigns, setCampaigns] = useState([]);
    const [products, setProducts] = useState([]);

    const [adSelected, setAdSelected] = useState(undefined);
    const [adSelectedIndex, setAdSelectedIndex] = useState(undefined);
    const [productSelected, setProductSelected] = useState(undefined);
    const [productSelectedIndex, setProductSelectedIndex] = useState(undefined);
    const [campaignSelected, setCampaignSelected] = useState(undefined);
    const [campaignSelectedIndex, setCampaignSelectedIndex] = useState(
        undefined
    );

    const [paymentXCarne, setPaymentXCarne] = useState(undefined);
    const [paymentXCreditCard, setPaymentXCreditCard] = useState(undefined);
    const [useInterests, setUseInterests] = useState(false);
    const [withoutInterestCarne, setWithoutInterestCarne] = useState(undefined);
    const [withoutInterestCreditCard, setWithoutInterestCreditCard] = useState(
        undefined
    );
    const [useCardCarne, setUseCardCarne] = useState(false);
    const [creditCard, setCreditCard] = useState(false);
    const [carne, setCarne] = useState(false);
    const [sightPriceOne, setSightPriceOne] = useState(undefined);
    const [sightPriceTwo, setSightPriceTwo] = useState(undefined);
    const [percentSightPriceOne, setPercentSightPriceOne] = useState(undefined);
    const [percentStateSightPriceOne, setPercentStateSightPriceOne] = useState(
        undefined
    );
    const [percentSightPriceTwo, setPercentSightPriceTwo] = useState(undefined);
    const [percentStateSightPriceTwo, setPercentStateSightPriceTwo] = useState(
        undefined
    );
    const [distributionCenter, setDistributionCenter] = useState(undefined);
    const [addtionalInformationOne, setAddtionalInformationOne] = useState(
        undefined
    );
    const [addtionalInformationTwo, setAddtionalInformationTwo] = useState(
        undefined
    );
    const [paymentX, setPaymentX] = useState(undefined);
    const [sightPrice, setSightPrice] = useState(undefined);
    const [pricePayment, setPricePayment] = useState(undefined);
    const [totalAmount, setTotalAmount] = useState(undefined);
    const [entrance, setEntrance] = useState(undefined);
    const [onlySightPrice, setOnlySightPrice] = useState(undefined);

    const [changes, setChanges] = useState(false);

    const reset = () => {
        setAdOrProduct(undefined);
        setAdSelected(undefined);
        setProductSelected(undefined);
        setCampaignSelected(undefined);
        setPaymentXCarne(undefined);
        setPaymentXCreditCard(undefined);
        setUseInterests(false);
        setWithoutInterestCarne(false);
        setWithoutInterestCreditCard(false);
        setUseCardCarne(false);
        setCreditCard(false);
        setCarne(false);
        setSightPriceOne(undefined);
        setSightPriceTwo(undefined);
        setPercentSightPriceOne(undefined);
        setPercentStateSightPriceOne(undefined);
        setPercentSightPriceTwo(undefined);
        setPercentStateSightPriceTwo(undefined);
        setDistributionCenter(undefined);
        setAddtionalInformationOne(undefined);
        setAddtionalInformationTwo(undefined);
        setAdSelectedIndex(undefined);
        setProductSelectedIndex(undefined);
        setCampaignSelectedIndex(undefined);
        setPricePayment(undefined);
        setSightPrice(undefined);
        setPaymentX(undefined);
        setTotalAmount(undefined);
        setEntrance(undefined);
    };

    const updateListStorage = (objectToStore, index) => {
        console.log(objectToStore);
        let newItems;
        if (window.localStorage.getItem("tabloidItems") !== null) {
            const tabloidItems = JSON.parse(
                window.localStorage.getItem("tabloidItems")
            );
            tabloidItems.splice(index, 1);
            tabloidItems.splice(index, 0, objectToStore);

            newItems = [...tabloidItems];

            window.localStorage.setItem(
                "tabloidItems",
                JSON.stringify(newItems)
            );
        }
    };

    const save = () => {
        console.log("Edit Product or Ad");
        if (adOrProduct === 0) {
            if (adSelected === undefined || adSelected === 0) {
                alert("Nenhum anúncio selecionado.");
                return;
            } else {
                if (baseData.ad.id !== adSelected) {
                    updateListStorage(
                        {
                            type: "ad",
                            id: baseData.id,
                            ad: {
                                id: adSelected,
                                name: ads[adSelectedIndex].name,
                            },
                            changes: true,
                        },
                        props.index
                    );
                }
            }
        } else if (adOrProduct === 1) {
            if (productSelected === undefined || productSelected === 0) {
                alert("Nenhum produto selecionado.");
                return;
            } else {
                if (changes) {
                    updateListStorage(
                        {
                            type: "product",
                            id: baseData.id,
                            changes: true,
                            product: productSelectedIndex
                                ? {
                                      id: productSelected.value,
                                      code: products[productSelectedIndex].code,
                                      name:
                                          products[productSelectedIndex].label,
                                  }
                                : baseData.product,
                            withoutInterestCarne: !baseData.isGazinTabloid
                                ? withoutInterestCarne
                                : carne
                                ? withoutInterestCarne
                                : undefined,
                            withoutInterestCreditCard: !baseData.isGazinTabloid
                                ? withoutInterestCreditCard
                                : creditCard
                                ? withoutInterestCreditCard
                                : undefined,
                            creditCard: creditCard,
                            carne: carne,
                            paymentXCreditCard: paymentXCreditCard,
                            paymentXCarne: paymentXCarne,
                            sightPriceOne: sightPriceOne,
                            sightPriceTwo: sightPriceTwo,
                            percentSightPriceOne: percentSightPriceOne,
                            percentStateSightPriceOne: percentStateSightPriceOne,
                            percentSightPriceTwo: percentSightPriceTwo,
                            percentStateSightPriceTwo: percentStateSightPriceTwo,
                            distributionCenter: distributionCenter,
                            addtionalInformationOne: addtionalInformationOne,
                            addtionalInformationTwo: addtionalInformationTwo,
                            campaign:
                                campaignSelected !== baseData.campaign?.id
                                    ? {
                                          id: campaignSelected,
                                          name:
                                              campaigns[campaignSelectedIndex]
                                                  .name,
                                      }
                                    : baseData.campaign !== undefined
                                    ? {
                                          id: baseData.campaign.id,
                                          name: baseData.campaign.name,
                                      }
                                    : undefined,
                            paymentX: paymentX,
                            pricePayment: pricePayment,
                            totalAmount: totalAmount,
                            sightPrice: sightPrice,
                            entrance: entrance,
                            onlySightPrice: onlySightPrice,
                        },
                        props.index
                    );
                }
            }
        } else {
            alert("O tipo não foi selecionado");
            return;
        }

        reset();
        handleClose();
        props.onEdit();
    };

    return (
        <>
            <Button
                variant="warning"
                style={{
                    width: "100%",
                    marginTop: 5,
                }}
                onClick={() => {
                    setAdOrProduct(baseData.type === "ad" ? 0 : 1);
                    setAdSelected(baseData.ad?.id);
                    if (baseData.product) {
                        setProductSelected({
                            value: baseData.product.id,
                            label: baseData.product.name,
                            price: 0,
                            code: baseData.product.code,
                        });
                    }
                    setCampaignSelected(baseData.campaign?.id);
                    setPaymentXCarne(baseData.paymentXCarne);
                    setPaymentXCreditCard(baseData.paymentXCreditCard);
                    setUseInterests(baseData.useInterests);
                    setUseInterests(
                        baseData.withoutInterestCarne !== undefined ||
                            baseData.withoutInterestCreditCard !== undefined
                            ? true
                            : false
                    );
                    setWithoutInterestCarne(baseData.withoutInterestCarne);
                    setWithoutInterestCreditCard(
                        baseData.withoutInterestCreditCard
                    );
                    setUseCardCarne(
                        baseData.creditCard !== undefined ||
                            baseData.carne !== undefined
                            ? true
                            : false
                    );
                    setCreditCard(baseData.creditCard);
                    setCarne(baseData.carne);
                    setDistributionCenter(baseData.distributionCenter);
                    setSightPriceOne(baseData.sightPriceOne);
                    setSightPriceTwo(baseData.sightPriceTwo);
                    setPercentSightPriceOne(baseData.percentSightPriceOne);
                    setPercentSightPriceTwo(baseData.percentSightPriceTwo);
                    setPercentStateSightPriceOne(
                        baseData.percentStateSightPriceOne
                    );
                    setPercentStateSightPriceTwo(
                        baseData.percentStateSightPriceTwo
                    );
                    setAddtionalInformationOne(
                        baseData.addtionalInformationOne
                    );
                    setAddtionalInformationTwo(
                        baseData.addtionalInformationTwo
                    );
                    setCampaigns((oldArray) => [
                        { id: 0, name: "Escolha..." },
                        ...baseData.campaigns,
                    ]);
                    setProducts(baseData.products);
                    setAds(baseData.ads);
                    setPricePayment(baseData.pricePayment);
                    setSightPrice(baseData.sightPrice);
                    setPaymentX(baseData.paymentX);
                    setTotalAmount(baseData.totalAmount);
                    setEntrance(baseData.entrance);
                    setOnlySightPrice(baseData.onlySightPrice);
                    handleShow();
                }}
            >
                Editar
            </Button>

            <Modal
                show={show}
                onHide={() => {
                    handleClose();
                    reset();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Editar Anúncio ou Produto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(event) => event.preventDefault()}>
                        {adOrProduct === 0 ? (
                            <Form.Group controlId="formAd">
                                <Form.Label>Anúncio</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        const idAndIndex = value.split("-");

                                        setAdSelected(parseInt(idAndIndex[0]));
                                        setAdSelectedIndex(
                                            parseInt(idAndIndex[1])
                                        );
                                    }}
                                >
                                    {ads.map((adItem, index) => (
                                        <option
                                            key={index}
                                            value={`${adItem.id}-${index}`}
                                            selected={adItem.id === adSelected}
                                        >{`${adItem.name}`}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        ) : adOrProduct === 1 ? (
                            <>
                                <Form.Group controlId="formProduct">
                                    <Form.Label>Produto</Form.Label>
                                    <Select
                                        placeholder="Selecione um produto"
                                        value={productSelected}
                                        onChange={(selectedOption) => {
                                            setProductSelected(selectedOption);
                                            setProductSelectedIndex(
                                                products.indexOf(selectedOption)
                                            );
                                            setChanges(true);
                                        }}
                                        options={products}
                                    />
                                </Form.Group>

                                {props.baseData.isGazinTabloid ? (
                                    <>
                                        <Form.Group controlId="formQtdCarne">
                                            <Form.Label>
                                                Qtd. Parcelas Carnê (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Ex: 5"
                                                value={paymentXCarne}
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;
                                                    if (value === "")
                                                        setPaymentXCarne(
                                                            undefined
                                                        );
                                                    else
                                                        setPaymentXCarne(value);
                                                    setChanges(true);
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a quantidade de parcelas
                                                corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formQtdCreditCard">
                                            <Form.Label>
                                                Qtd. Parcelas Cartão de Crédito
                                                (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Ex: 3"
                                                value={paymentXCreditCard}
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;
                                                    if (value === "")
                                                        setPaymentXCreditCard(
                                                            undefined
                                                        );
                                                    else
                                                        setPaymentXCreditCard(
                                                            value
                                                        );

                                                    setChanges(true);
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a quantidade de parcelas
                                                corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </>
                                ) : (
                                    <>
                                        <Form.Group controlId="formEntrance">
                                            <Form.Check
                                                id="formEntrance"
                                                type="checkbox"
                                                label="Com entrada?"
                                                checked={entrance}
                                                onChange={() => {
                                                    setEntrance(!entrance);
                                                    setChanges(true);
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formOnlySightPrice">
                                            <Form.Check
                                                id="formOnlySightPrice"
                                                type="checkbox"
                                                label="Somente preço à vista"
                                                checked={onlySightPrice}
                                                onChange={() => {
                                                    setOnlySightPrice(
                                                        !onlySightPrice
                                                    );
                                                    setChanges(true);
                                                }}
                                            />
                                        </Form.Group>
                                    </>
                                )}

                                <Form.Group controlId="formUseInterests">
                                    <Form.Check
                                        id="formUseInterests"
                                        type="checkbox"
                                        label="Usar sem juros? (Isso habilitará os 2 campos abaixo)"
                                        checked={useInterests}
                                        onChange={() => {
                                            setChanges(true);

                                            if (!useInterests === false) {
                                                setWithoutInterestCarne(false);
                                                setWithoutInterestCreditCard(
                                                    false
                                                );
                                            }

                                            setUseInterests(!useInterests);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formWithoutInterestCarne">
                                    <Form.Check
                                        type="checkbox"
                                        label="Sem juros no carnê!"
                                        disabled={!useInterests}
                                        checked={withoutInterestCarne}
                                        onChange={() => {
                                            setWithoutInterestCarne(
                                                !withoutInterestCarne
                                            );
                                            setChanges(true);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formWithoutInterestCreditCard">
                                    <Form.Check
                                        type="checkbox"
                                        label="Sem juros no cartão de crédito!"
                                        disabled={!useInterests}
                                        checked={withoutInterestCreditCard}
                                        onChange={() => {
                                            setWithoutInterestCreditCard(
                                                !withoutInterestCreditCard
                                            );

                                            setChanges(true);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formUseCardCarne">
                                    <Form.Check
                                        id="formUseCardCarne"
                                        type="checkbox"
                                        label="Usar carnê e cartão? (Isso habilitará os 2 campos abaixo)"
                                        checked={useCardCarne}
                                        onChange={() => {
                                            if (!useCardCarne === false) {
                                                setCreditCard(false);
                                                setCarne(false);
                                            }

                                            setUseCardCarne(!useCardCarne);
                                            setChanges(true);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCreditCard">
                                    <Form.Check
                                        type="checkbox"
                                        label="Habilitar cartão de crédito!"
                                        disabled={!useCardCarne}
                                        checked={creditCard}
                                        onChange={() => {
                                            setCreditCard(!creditCard);
                                            setChanges(true);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCarne">
                                    <Form.Check
                                        type="checkbox"
                                        label="Habilitar carnê!"
                                        disabled={!useCardCarne}
                                        checked={carne}
                                        onChange={() => {
                                            setCarne(!carne);
                                            setChanges(true);
                                        }}
                                    />
                                </Form.Group>

                                {props.baseData.isGazinTabloid ? (
                                    <>
                                        <Form.Group controlId="formSightPriceOne">
                                            <Form.Label>
                                                Preço À Vista 1 (opcional)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">
                                                        R$
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={sightPriceOne}
                                                    onChangeEvent={(
                                                        event,
                                                        maskedvalue,
                                                        floatvalue
                                                    ) => {
                                                        setSightPriceOne(
                                                            floatvalue
                                                        );
                                                        setChanges(true);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwo">
                                            <Form.Label>
                                                Preço À Vista 2 (opcional)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">
                                                        R$
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={sightPriceTwo}
                                                    onChangeEvent={(
                                                        event,
                                                        maskedvalue,
                                                        floatvalue
                                                    ) => {
                                                        setSightPriceTwo(
                                                            floatvalue
                                                        );
                                                        setChanges(true);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentSightPriceOne">
                                            <Form.Label>
                                                Percentual Preço À Vista 1
                                                (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;

                                                    if (value === "") {
                                                        setPercentSightPriceOne(
                                                            undefined
                                                        );
                                                    } else {
                                                        setPercentSightPriceOne(
                                                            event.target.value
                                                        );
                                                    }

                                                    setChanges(true);
                                                }}
                                            >
                                                <option value="">
                                                    Escolha...
                                                </option>
                                                <option
                                                    value="5"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "5"
                                                    }
                                                >
                                                    5%
                                                </option>
                                                <option
                                                    value="7"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "7"
                                                    }
                                                >
                                                    7%
                                                </option>
                                                <option
                                                    value="9"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "9"
                                                    }
                                                >
                                                    9%
                                                </option>
                                                <option
                                                    value="12"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "12"
                                                    }
                                                >
                                                    12%
                                                </option>
                                                <option
                                                    value="13"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "13"
                                                    }
                                                >
                                                    13%
                                                </option>
                                                <option
                                                    value="EXT"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "EXT"
                                                    }
                                                >
                                                    EXT
                                                </option>
                                                <option
                                                    value="INT"
                                                    selected={
                                                        percentSightPriceOne ===
                                                        "INT"
                                                    }
                                                >
                                                    INT
                                                </option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateSightPriceOne">
                                            <Form.Label>
                                                Estado Percentual Preço À Vista
                                                1 (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;
                                                    if (value === "0") {
                                                        setPercentStateSightPriceOne(
                                                            undefined
                                                        );
                                                    } else {
                                                        setPercentStateSightPriceOne(
                                                            value
                                                        );
                                                    }

                                                    setChanges(true);
                                                }}
                                            >
                                                <option value="0">
                                                    Escolha...
                                                </option>
                                                <option
                                                    value="AC"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "AC"
                                                    }
                                                >
                                                    Acre
                                                </option>
                                                <option
                                                    value="AL"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "AL"
                                                    }
                                                >
                                                    Alagoas
                                                </option>
                                                <option
                                                    value="AP"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "AP"
                                                    }
                                                >
                                                    Amapá
                                                </option>
                                                <option
                                                    value="AM"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "AM"
                                                    }
                                                >
                                                    Amazonas
                                                </option>
                                                <option
                                                    value="BA"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "BA"
                                                    }
                                                >
                                                    Bahia
                                                </option>
                                                <option
                                                    value="CE"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "CE"
                                                    }
                                                >
                                                    Ceará
                                                </option>
                                                <option
                                                    value="DF"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "DF"
                                                    }
                                                >
                                                    Distrito Federal
                                                </option>
                                                <option
                                                    value="ES"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "ES"
                                                    }
                                                >
                                                    Espírito Santo
                                                </option>
                                                <option
                                                    value="GO"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "GO"
                                                    }
                                                >
                                                    Goiás
                                                </option>
                                                <option
                                                    value="MA"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "MA"
                                                    }
                                                >
                                                    Maranhão
                                                </option>
                                                <option
                                                    value="MT"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "MT"
                                                    }
                                                >
                                                    Mato Grosso
                                                </option>
                                                <option
                                                    value="MS"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "MS"
                                                    }
                                                >
                                                    Mato Grosso do Sul
                                                </option>
                                                <option
                                                    value="MG"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "MG"
                                                    }
                                                >
                                                    Minas Gerais
                                                </option>
                                                <option
                                                    value="PA"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "PA"
                                                    }
                                                >
                                                    Pará
                                                </option>
                                                <option
                                                    value="PB"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "PB"
                                                    }
                                                >
                                                    Paraíba
                                                </option>
                                                <option
                                                    value="PR"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "PR"
                                                    }
                                                >
                                                    Paraná
                                                </option>
                                                <option
                                                    value="PE"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "PE"
                                                    }
                                                >
                                                    Pernambuco
                                                </option>
                                                <option
                                                    value="PI"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "PI"
                                                    }
                                                >
                                                    Piauí
                                                </option>
                                                <option
                                                    value="RJ"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "RJ"
                                                    }
                                                >
                                                    Rio de Janeiro
                                                </option>
                                                <option
                                                    value="RN"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "RN"
                                                    }
                                                >
                                                    Rio Grande do Norte
                                                </option>
                                                <option
                                                    value="RS"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "RS"
                                                    }
                                                >
                                                    Rio Grande do Sul
                                                </option>
                                                <option
                                                    value="RO"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "RO"
                                                    }
                                                >
                                                    Rondônia
                                                </option>
                                                <option
                                                    value="RR"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "RR"
                                                    }
                                                >
                                                    Roraima
                                                </option>
                                                <option
                                                    value="SC"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "SC"
                                                    }
                                                >
                                                    Santa Catarina
                                                </option>
                                                <option
                                                    value="SP"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "SP"
                                                    }
                                                >
                                                    São Paulo
                                                </option>
                                                <option
                                                    value="SE"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "SE"
                                                    }
                                                >
                                                    Sergipe
                                                </option>
                                                <option
                                                    value="TO"
                                                    selected={
                                                        percentStateSightPriceOne ===
                                                        "TO"
                                                    }
                                                >
                                                    Tocantins
                                                </option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentSightPriceTwo">
                                            <Form.Label>
                                                Percentual Preço À Vista 2
                                                (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;

                                                    if (value === "") {
                                                        setPercentSightPriceTwo(
                                                            null
                                                        );
                                                    } else {
                                                        setPercentSightPriceTwo(
                                                            event.target.value
                                                        );
                                                    }

                                                    setChanges(true);
                                                }}
                                            >
                                                <option value="">
                                                    Escolha...
                                                </option>
                                                <option
                                                    value="5"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "5"
                                                    }
                                                >
                                                    5%
                                                </option>
                                                <option
                                                    value="7"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "7"
                                                    }
                                                >
                                                    7%
                                                </option>
                                                <option
                                                    value="9"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "9"
                                                    }
                                                >
                                                    9%
                                                </option>
                                                <option
                                                    value="12"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "12"
                                                    }
                                                >
                                                    12%
                                                </option>
                                                <option
                                                    value="13"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "13"
                                                    }
                                                >
                                                    13%
                                                </option>
                                                <option
                                                    value="EXT"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "EXT"
                                                    }
                                                >
                                                    EXT
                                                </option>
                                                <option
                                                    value="INT"
                                                    selected={
                                                        percentSightPriceTwo ===
                                                        "INT"
                                                    }
                                                >
                                                    INT
                                                </option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateSightPriceTwo">
                                            <Form.Label>
                                                Estado Percentual Preço À Vista
                                                2 (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;
                                                    if (value === "0") {
                                                        setPercentStateSightPriceTwo(
                                                            undefined
                                                        );
                                                    } else {
                                                        setPercentStateSightPriceTwo(
                                                            value
                                                        );
                                                    }

                                                    setChanges(true);
                                                }}
                                            >
                                                <option value="0">
                                                    Escolha...
                                                </option>
                                                <option
                                                    value="AC"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "AC"
                                                    }
                                                >
                                                    Acre
                                                </option>
                                                <option
                                                    value="AL"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "AL"
                                                    }
                                                >
                                                    Alagoas
                                                </option>
                                                <option
                                                    value="AP"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "AP"
                                                    }
                                                >
                                                    Amapá
                                                </option>
                                                <option
                                                    value="AM"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "AM"
                                                    }
                                                >
                                                    Amazonas
                                                </option>
                                                <option
                                                    value="BA"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "BA"
                                                    }
                                                >
                                                    Bahia
                                                </option>
                                                <option
                                                    value="CE"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "CE"
                                                    }
                                                >
                                                    Ceará
                                                </option>
                                                <option
                                                    value="DF"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "DF"
                                                    }
                                                >
                                                    Distrito Federal
                                                </option>
                                                <option
                                                    value="ES"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "ES"
                                                    }
                                                >
                                                    Espírito Santo
                                                </option>
                                                <option
                                                    value="GO"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "GO"
                                                    }
                                                >
                                                    Goiás
                                                </option>
                                                <option
                                                    value="MA"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "MA"
                                                    }
                                                >
                                                    Maranhão
                                                </option>
                                                <option
                                                    value="MT"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "MT"
                                                    }
                                                >
                                                    Mato Grosso
                                                </option>
                                                <option
                                                    value="MS"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "MS"
                                                    }
                                                >
                                                    Mato Grosso do Sul
                                                </option>
                                                <option
                                                    value="MG"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "MG"
                                                    }
                                                >
                                                    Minas Gerais
                                                </option>
                                                <option
                                                    value="PA"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "PA"
                                                    }
                                                >
                                                    Pará
                                                </option>
                                                <option
                                                    value="PB"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "PB"
                                                    }
                                                >
                                                    Paraíba
                                                </option>
                                                <option
                                                    value="PR"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "PR"
                                                    }
                                                >
                                                    Paraná
                                                </option>
                                                <option
                                                    value="PE"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "PE"
                                                    }
                                                >
                                                    Pernambuco
                                                </option>
                                                <option
                                                    value="PI"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "PI"
                                                    }
                                                >
                                                    Piauí
                                                </option>
                                                <option
                                                    value="RJ"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "RJ"
                                                    }
                                                >
                                                    Rio de Janeiro
                                                </option>
                                                <option
                                                    value="RN"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "RN"
                                                    }
                                                >
                                                    Rio Grande do Norte
                                                </option>
                                                <option
                                                    value="RS"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "RS"
                                                    }
                                                >
                                                    Rio Grande do Sul
                                                </option>
                                                <option
                                                    value="RO"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "RO"
                                                    }
                                                >
                                                    Rondônia
                                                </option>
                                                <option
                                                    value="RR"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "RR"
                                                    }
                                                >
                                                    Roraima
                                                </option>
                                                <option
                                                    value="SC"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "SC"
                                                    }
                                                >
                                                    Santa Catarina
                                                </option>
                                                <option
                                                    value="SP"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "SP"
                                                    }
                                                >
                                                    São Paulo
                                                </option>
                                                <option
                                                    value="SE"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "SE"
                                                    }
                                                >
                                                    Sergipe
                                                </option>
                                                <option
                                                    value="TO"
                                                    selected={
                                                        percentStateSightPriceTwo ===
                                                        "TO"
                                                    }
                                                >
                                                    Tocantins
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                    </>
                                ) : (
                                    <>
                                        <Form.Group controlId="formPaymentX">
                                            <Form.Label>
                                                Qtd. Parcelas (opcional)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Ex: 5"
                                                value={paymentX}
                                                onChange={(event) => {
                                                    const {
                                                        value,
                                                    } = event.target;
                                                    if (value === "")
                                                        setPaymentX(undefined);
                                                    else {
                                                        if (
                                                            totalAmount !==
                                                            undefined
                                                        ) {
                                                            setPricePayment(
                                                                totalAmount /
                                                                    parseInt(
                                                                        value
                                                                    )
                                                            );
                                                        } else if (
                                                            sightPrice !==
                                                            undefined
                                                        ) {
                                                            setPricePayment(
                                                                sightPrice /
                                                                    parseInt(
                                                                        value
                                                                    )
                                                            );
                                                        }
                                                        setPaymentX(value);
                                                        setChanges(true);
                                                    }
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a quantidade de parcelas
                                                corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPrice">
                                            <Form.Label>
                                                Preço À Vista (opcional)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">
                                                        R$
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={sightPrice}
                                                    onChangeEvent={(
                                                        event,
                                                        maskedvalue,
                                                        floatvalue
                                                    ) => {
                                                        if (
                                                            paymentX !==
                                                            undefined
                                                        ) {
                                                            setPricePayment(
                                                                floatvalue /
                                                                    paymentX
                                                            );
                                                        }

                                                        setSightPrice(
                                                            floatvalue
                                                        );
                                                        setTotalAmount(
                                                            floatvalue
                                                        );
                                                        setChanges(true);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formPricePayment">
                                            <Form.Label>
                                                Valor Parcela (opcional)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">
                                                        R$
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={pricePayment}
                                                    onChangeEvent={(
                                                        event,
                                                        maskedvalue,
                                                        floatvalue
                                                    ) => {
                                                        setPricePayment(
                                                            floatvalue
                                                        );
                                                        setChanges(true);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formTotalAmount">
                                            <Form.Label>
                                                Total À Prazo (opcional)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1">
                                                        R$
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={totalAmount}
                                                    onChangeEvent={(
                                                        event,
                                                        maskedvalue,
                                                        floatvalue
                                                    ) => {
                                                        if (
                                                            paymentX !==
                                                            undefined
                                                        ) {
                                                            setPricePayment(
                                                                floatvalue /
                                                                    paymentX
                                                            );
                                                        }

                                                        setTotalAmount(
                                                            floatvalue
                                                        );
                                                        setChanges(true);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </>
                                )}

                                <Form.Group controlId="formAddtionalInformationOne">
                                    <Form.Label>
                                        Informação adicional 1 (opcional)
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: 110V"
                                        maxLength="30"
                                        value={addtionalInformationOne}
                                        onChange={(event) => {
                                            setAddtionalInformationOne(
                                                event.target.value
                                            );

                                            setChanges(true);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        O preenchimento é opcional.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formAddtionalInformationTwo">
                                    <Form.Label>
                                        Informação adicional 2 (opcional)
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ex: Branco"
                                        maxLength="30"
                                        value={addtionalInformationTwo}
                                        onChange={(event) => {
                                            setAddtionalInformationTwo(
                                                event.target.value
                                            );

                                            setChanges(true);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        O preenchimento é opcional.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formCampaign">
                                    <Form.Label>Campanha (opcional)</Form.Label>
                                    <Form.Control
                                        as="select"
                                        // disabled={
                                        //     campaignSelected !== undefined
                                        // }
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            // alert(value);
                                            const idAndKey = value.split("-");

                                            setCampaignSelected(idAndKey[0]);
                                            setCampaignSelectedIndex(
                                                idAndKey[1]
                                            );

                                            setChanges(true);
                                        }}
                                    >
                                        {campaigns.map(
                                            (campaignItem, index) => (
                                                <option
                                                    key={index}
                                                    selected={
                                                        campaignItem.id ===
                                                        parseInt(
                                                            campaignSelected
                                                        )
                                                    }
                                                    value={`${campaignItem.id}-${index}`}
                                                >{`${campaignItem.name}`}</option>
                                            )
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </>
                        ) : null}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleClose();
                            reset();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={save}>
                        Salvar!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TabloidItemEdit;
