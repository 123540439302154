import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";

import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const CPFAuthorizedList = (props) => {
    const [cpfsAuthorized, setCpfsAuthorized] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [cpfAuthorizedView, setCpfAuthorizedView] = useState({ branch: {} });
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [userTypeLogged, setUserTypeLogged] = useState(
        Number(window.localStorage.getItem(TOKEN_USERTYPE))
    );
    const [branchId, setBranchId] = useState(
        Number(window.localStorage.getItem(TOKEN_BRANCHID))
    );

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    useEffect(
        function () {
            async function loadcpf() {
                const responseGetcpf = await api.get(
                    userTypeLogged === 0
                        ? "/cpf-authorized"
                        : `/cpf-authorized-by-branch/${branchId}`
                );
                if (responseGetcpf.data.error) {
                    alert("Erro ao buscar os CPFs. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetcpf.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else {
                        const getCPFsBeautiful = async () => {
                            return Promise.all(
                                responseGetcpf.data.data.map(
                                    (cpfItem, index) => ({
                                        ...cpfItem,
                                        cpf: formataCPF(cpfItem.cpf),
                                    })
                                )
                            );
                        };
                        getCPFsBeautiful().then((cpfsBeautiful) => {
                            setCpfsAuthorized(cpfsBeautiful);
                        });
                    }
                }
            }

            loadcpf();
        },
        [branchId, props.history, userTypeLogged]
    );

    function formataCPF(cpf) {
        if (cpf === undefined) return;
        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");

        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    return (
        <>
            <CustomNavbar />
            <main
                style={{
                    marginBottom: 40,
                }}
            >
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>CPFs Autorizado</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>ID: {cpfAuthorizedView.id}</p>
                        <p>CPF: {formataCPF(cpfAuthorizedView.cpf)}</p>
                        <p>Empresa: {cpfAuthorizedView.branch.name}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar CPFs Autorizados
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: false,
                                        selector: "id",
                                    },
                                    {
                                        name: "CPF",
                                        sortable: false,
                                        selector: "cpf",
                                    },
                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <ButtonGroup aria-label="Basic example">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            setCpfAuthorizedView(
                                                                cellInfo
                                                            );
                                                            handleShowModalInfo();
                                                        }}
                                                    >
                                                        Visualizar
                                                    </Button>
                                                    {/*  <Button variant="secondary">Editar</Button>*/}
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => {
                                                            var trash = window.confirm(
                                                                "Para Excluir pressione OK!"
                                                            );
                                                            if (
                                                                trash === true
                                                            ) {
                                                                async function deleteCPFAuthorized(
                                                                    id
                                                                ) {
                                                                    try {
                                                                        const responseDeleteCpfAuthorized = await api.delete(
                                                                            `/cpf-authorized/${id}`
                                                                        );
                                                                        if (
                                                                            responseDeleteCpfAuthorized.status ===
                                                                            204
                                                                        ) {
                                                                            setShowAlert(
                                                                                true
                                                                            );
                                                                            setAlertTitle(
                                                                                "CPF excluído com sucesso!"
                                                                            );
                                                                            setAlertText(
                                                                                `O CPF ${cellInfo.cpf} foi excluída com sucesso!`
                                                                            );
                                                                            setAlertVariant(
                                                                                "success"
                                                                            );

                                                                            const updatedList = cpfsAuthorized.filter(
                                                                                (
                                                                                    itemCpfsAuthorized,
                                                                                    index
                                                                                ) =>
                                                                                    itemCpfsAuthorized.id !==
                                                                                    cellInfo.id
                                                                            );
                                                                            setCpfsAuthorized(
                                                                                updatedList
                                                                            );
                                                                        }
                                                                    } catch (err) {
                                                                        setShowAlert(
                                                                            true
                                                                        );
                                                                        setAlertTitle(
                                                                            "Não foi possível excluir o CPF!"
                                                                        );
                                                                        setAlertText(
                                                                            `O CPF ${cellInfo.cpf} não pode ser excluído. Contate o suporte!`
                                                                        );
                                                                        setAlertVariant(
                                                                            "danger"
                                                                        );
                                                                    }
                                                                }
                                                                deleteCPFAuthorized(
                                                                    cellInfo.id
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        },
                                    },
                                ]}
                                data={cpfsAuthorized}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(CPFAuthorizedList);
