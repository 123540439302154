import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";
import { logout } from "../../service/auth";
import { TOKEN_BRANCHID, TOKEN_USERTYPE } from "../../service/tokens";

const CpfAuthorizedCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [branches, setBranches] = useState([]);
    const [cpf, setCPF] = useState("");
    const [branchSelected, setBranchSelected] = useState(0);
    const [branchSelectedName, setBranchSelectedName] = useState("");

    const [userTypeLogged, setUserTypeLogged] = useState(
        Number(window.localStorage.getItem(TOKEN_USERTYPE))
    );
    const [branchId, setBranchId] = useState(
        Number(window.localStorage.getItem(TOKEN_BRANCHID))
    );

    useEffect(
        function () {
            async function getBranches() {
                if (branchId === null) {
                    logout();
                    props.history.push("/");
                }

                const responseGetBranches = await api.get("/branch");
                if (responseGetBranches.data.error) {
                    alert("Erro ao buscar os CPFs. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    let branches = responseGetBranches.data.data;
                    if (userTypeLogged === 1)
                        branches = responseGetBranches.data.data.filter(
                            (item) => item.id === branchId
                        );

                    setBranches(branches);
                }
            }

            if (userTypeLogged === 0) {
                getBranches();
            } else {
                setBranchSelected(branchId);
            }
        },
        [branchId, props.history, userTypeLogged]
    );

    function validarCPF(cpf) {
        let add;
        cpf = cpf.replace(/[^\d]+/g, "");
        if (cpf === "") return false;
        // Elimina CPFs invalidos conhecidos
        if (
            cpf.length != 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        )
            return false;
        // Valida 1o digito
        add = 0;
        let i;
        let rev;
        for (i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) rev = 0;
        if (rev != parseInt(cpf.charAt(9))) return false;
        // Valida 2o digito
        add = 0;
        for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) rev = 0;
        if (rev != parseInt(cpf.charAt(10))) return false;
        return true;
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (!cpf) {
            alert("CPF não foi preenchido!");
            return;
        }

        if (!validarCPF(cpf)) {
            alert("O CPF informado não é válido! Por favor verifique.");
            return;
        }

        if (!branchSelected) {
            alert("Empresa não selecionada!");
            return;
        }

        async function postCPFAuthorized() {
            try {
                const responsePostCPFAuthorized = await api.post(
                    "/cpf-authorized",
                    {
                        cpf,
                        branch:
                            userTypeLogged === 0 ? branchSelected : branchId,
                    }
                );

                if (
                    responsePostCPFAuthorized.data.error ||
                    responsePostCPFAuthorized.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas para autorizar o CPF.");
                    setAlertText(
                        `Tivemos problemas ao tentar autorizar o CPF ${cpf} para a empresa selecionada. Por favor contate o suporte!`
                    );
                    setAlertVariant("danger");
                } else {
                    setShowAlert(true);
                    setAlertTitle("CPF Autorizado com sucesso!");
                    setAlertText(`O CPF ${cpf} foi autorizado para a empresa selecionada. Agora é necessário criar a conta no aplicativo Cartão Express Partners. 
                    Em alguns segundos você será redirecionado.`);
                    setAlertVariant("success");

                    setTimeout(() => {
                        props.history.push("/cpf-authorized-list");
                    }, 5000);
                }
            } catch (err) {
                setShowAlert(true);
                setAlertTitle("Problemas para autorizar o CPF.");
                setAlertText(
                    `Tivemos problemas ao tentar autorizar o CPF ${cpf} para a empresa selecionada. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            }
        }
        postCPFAuthorized();
    }

    return (
        <>
            <CustomNavbar />
            <main>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formCpf">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="CPF"
                                        value={cpf}
                                        onChange={(e) => {
                                            setCPF(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o CPF que deseja liberar para a
                                        empresa a seguir.
                                    </Form.Text>
                                </Form.Group>

                                {userTypeLogged === 0 ? (
                                    <Form.Group controlId="formCNPJ">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={branchSelectedName}
                                            onChange={(e) => {
                                                const valueSplited = e.target.value.split(
                                                    "-"
                                                );
                                                setBranchSelected(
                                                    valueSplited[0]
                                                );
                                                setBranchSelectedName(
                                                    valueSplited[1]
                                                );
                                            }}
                                            list="selectBranches"
                                        />
                                        <datalist id="selectBranches">
                                            <option>Escolha...</option>
                                            {branches.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={`${item.id}-${item.name} = ${item.email}`}
                                                >{`${item.cnpj} - ${item.email}`}</option>
                                            ))}
                                        </datalist>
                                    </Form.Group>
                                ) : null}
                                <Button variant="success" type="submit">
                                    Autorizar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o seu CPF sem
                                                    pontos
                                                </li>
                                                <li>
                                                    - Verifique se o CNPJ da
                                                    Empresa esta correto.
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(CpfAuthorizedCreate);
