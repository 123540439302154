import React from "react";

import { Row, Col, Button, Image } from "react-bootstrap";
import TabloidItemEdit from "../TabloidItemEdit";

function TabloidItem(props) {
    const { item } = props;

    function testNullOrMessage(
        testItem,
        messageNull,
        messageFalse,
        messageTrue
    ) {
        try {
            if (testItem === null || testItem === undefined) return messageNull;
            if (testItem) return messageTrue;
            else return messageFalse;
        } catch (error) {
            return messageNull;
        }
    }

    function testNullOrPrice(testItem, one) {
        if (testItem !== null && testItem !== undefined)
            return `${one ? "Preço 1:" : "Preço 2:"} ${testItem.toLocaleString(
                "pt-BR",
                {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "BRL",
                }
            )}`;
        else
            return `${one ? "Preço 1:" : "Preço 2:"} ${Number(0).toLocaleString(
                "pt-BR",
                {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "BRL",
                }
            )}`;
    }

    function testNullOrPricePartner(testItem) {
        if (testItem !== null && testItem !== undefined)
            return `${testItem.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "BRL",
            })}`;
        else
            return `${Number(0).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "BRL",
            })}`;
    }

    return (
        <Row
            style={{
                borderBottom: "1px solid #353A40",
                paddingBottom: 10,
                paddingTop: 10,
            }}
        >
            <Col md="3">
                <Image
                    src={testNullOrMessage(
                        item.product,
                        "https://via.placeholder.com/150?text=Anuncio",
                        "https://via.placeholder.com/150?text=Anuncio",
                        `https://circular.rxy.com.br/api/public/imgs/${item.product?.code}_face.png`
                    )}
                    fluid
                    alt="Foto do produto ou anuncio"
                />
            </Col>
            {item.type === "ad" ? (
                <Col md="6">
                    <p>
                        {testNullOrMessage(
                            item.ad,
                            "Sem anúncio.",
                            "",
                            `Anúncio: ${item.ad?.name}`
                        )}
                    </p>
                </Col>
            ) : (
                <Col md="6">
                    <p>
                        {testNullOrMessage(
                            item.product,
                            item.ad?.name,
                            "",
                            item.product?.name
                        )}
                    </p>
                    <p>
                        {testNullOrMessage(
                            item.withoutInterestCarne,
                            "",
                            "Com juros no carnê",
                            "Sem juros no carnê"
                        )}
                    </p>
                    <p>
                        {testNullOrMessage(
                            item.withoutInterestCreditCard,
                            "",
                            "Com juros no cartão de crédito",
                            "Sem juros no cartão de crédito"
                        )}
                    </p>
                    {props.baseData.isGazinTabloid ? (
                        <>
                            <p>
                                {testNullOrMessage(
                                    item.creditCard,
                                    "",
                                    "Pagamento no cartão desabilitado!",
                                    "Pagamento no cartão habilitado!"
                                )}
                            </p>
                            <p>
                                {testNullOrMessage(
                                    item.carne,
                                    "",
                                    "Pagamento no carnê desabilitado!",
                                    "Pagamento no carnê habilitado!"
                                )}
                            </p>
                            <p>
                                {testNullOrMessage(
                                    item.paymentXCreditCard,
                                    "",
                                    "",
                                    `${item.paymentXCreditCard}X no cartão de crédito`
                                )}
                            </p>
                            <p>
                                {testNullOrMessage(
                                    item.paymentXCarne,
                                    "",
                                    "",
                                    `${item.paymentXCarne}X no carnê`
                                )}
                            </p>
                            <p>
                                {testNullOrPrice(item.sightPriceOne, true)} -{" "}
                                {testNullOrMessage(
                                    item.percentSightPriceOne,
                                    `Sem percentual - ${
                                        item.percentStateSightPriceOne
                                            ? item.percentStateSightPriceOne
                                            : "Sem Estado"
                                    }`,
                                    "",
                                    `${item.percentSightPriceOne}% - ${
                                        item.percentStateSightPriceOne
                                            ? item.percentStateSightPriceOne
                                            : ""
                                    }`
                                )}
                            </p>
                            <p>
                                {testNullOrPrice(item.sightPriceTwo, false)} -{" "}
                                {testNullOrMessage(
                                    item.percentSightPriceTwo,
                                    `Sem percentual - ${
                                        item.percentStateSightPriceTwo
                                            ? item.percentStateSightPriceTwo
                                            : "Sem Estado"
                                    }`,
                                    "",
                                    `${item.percentSightPriceTwo}% - ${
                                        item.percentStateSightPriceTwo
                                            ? item.percentStateSightPriceTwo
                                            : ""
                                    }`
                                )}
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                {"À Vista " +
                                    testNullOrPricePartner(item.sightPrice)}
                            </p>
                            <p>
                                {"Total À Prazo " +
                                    testNullOrPricePartner(item.totalAmount)}
                            </p>
                            <p>
                                {"Valor Parcela " +
                                    testNullOrPricePartner(item.pricePayment)}
                            </p>
                            <p>
                                {testNullOrMessage(
                                    item.paymentX,
                                    "",
                                    ``,
                                    `Parcelas: ${item.paymentX}x`
                                )}
                            </p>
                            <p>
                                {testNullOrMessage(
                                    item.entrance,
                                    "",
                                    `Sem entrada`,
                                    `Com entranda`
                                )}
                            </p>
                            <p>
                                {testNullOrMessage(
                                    item.onlySightPrice,
                                    "",
                                    `Todos os preços`,
                                    `Somente preço à vista`
                                )}
                            </p>
                        </>
                    )}
                    <p>
                        {testNullOrMessage(
                            item.addtionalInformationOne,
                            "Sem informação adicional 1.",
                            "",
                            `Inf. Adc. 1: ${item.addtionalInformationOne}`
                        )}
                    </p>
                    <p>
                        {testNullOrMessage(
                            item.addtionalInformationTwo,
                            "Sem informação adicional 2.",
                            "",
                            `Inf. Adc. 2: ${item.addtionalInformationTwo}`
                        )}
                    </p>
                    <p>
                        {testNullOrMessage(
                            item.campaign,
                            "Sem campanha.",
                            "",
                            `Campanha: ${item.campaign?.name}`
                        )}
                    </p>
                </Col>
            )}
            <Col md="3">
                <Button
                    variant="success"
                    style={{
                        width: "100%",
                    }}
                    onClick={() => {
                        props.onMoveUp(props.index);
                    }}
                >
                    Subir
                </Button>
                <Button
                    variant="primary"
                    style={{
                        width: "100%",
                        marginTop: 5,
                    }}
                    onClick={() => {
                        props.onMoveDown(props.index);
                    }}
                >
                    Descer
                </Button>
                {!props.creating ? (
                    <TabloidItemEdit
                        baseData={{
                            ...item,
                            campaigns: props.baseData.campaigns,
                            products: props.baseData.products,
                            ads: props.baseData.ads,
                            isGazinTabloid: props.baseData.isGazinTabloid,
                        }}
                        index={props.index}
                        onEdit={props.onEdit}
                    />
                ) : null}
                <Button
                    variant="danger"
                    style={{
                        width: "100%",
                        marginTop: 5,
                    }}
                    onClick={() => {
                        props.onRemove(props.index);
                    }}
                >
                    Excluir
                </Button>
            </Col>
        </Row>
    );
}

export default TabloidItem;
