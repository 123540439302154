import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import { DateTime } from 'luxon'
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Card,
    CardImg,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api, { urlAPI } from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const BranchTabloidList = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [branchTabloid, setBranchTabloid] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [branchTabloidView, setBranchTabloidView] = useState({});

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    useEffect(
        function () {
            async function loadBranchTabloid() {
                const userType = Number(
                    window.localStorage.getItem(TOKEN_USERTYPE)
                );
                const branchId = Number(
                    window.localStorage.getItem(TOKEN_BRANCHID)
                );

                const responseGetBranchTabloid = await api.get(
                    `/branch-tabloid/${branchId}/list`
                );
                if (responseGetBranchTabloid.data.error) {
                    alert("Erro ao buscar os Tabloides. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetBranchTabloid.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else setBranchTabloid(responseGetBranchTabloid.data.data);
                }
            }

            loadBranchTabloid();
        },
        [props.history]
    );
    
    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações do Tabloide Fale com Vendedor </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Nome: {branchTabloidView.name}</p>
                        <p>Data Inicio: {DateTime.fromFormat(branchTabloidView.startDate ? branchTabloidView.startDate : '' , 'yyyy-LL-dd').toFormat('dd/LL/yyyy')}</p>
                        <p>Data Final: {DateTime.fromFormat(branchTabloidView.endDate ? branchTabloidView.endDate : '', 'yyyy-LL-dd').toFormat('dd/LL/yyyy')}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal> 
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Tabloides Fale com vendedor
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    

                                    {
                                        name: "Nome",
                                        sortable: true,
                                        selector: "name",
                                    },
                                    
                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <ButtonGroup aria-label="Basic example">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            setBranchTabloidView(
                                                                cellInfo
                                                            );
                                                            handleShowModalInfo();
                                                        }}
                                                    >
                                                        Visualizar
                                                    </Button>
                                                    <Button variant="secondary"
                                                            onClick={() => {
                                                                props.history.push(
                                                                    `/branch-tabloid-edit/${cellInfo.id}/`,
                                                                    {
                                                                        branchTabloid: cellInfo
                                                                    }
                                                                )
                                                            }}
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => {
                                                            var trash = window.confirm(
                                                                "Para Excluir pressione OK!"
                                                            );
                                                            if (
                                                                trash === true
                                                            ) {
                                                                async function deleteBranchTabloid(
                                                                    id
                                                                ) {
                                                                    try {
                                                                        const responseDeleteBranchTabloid = await api.delete(
                                                                            `/branch-tabloid/${id}`
                                                                        );
                                                                        if (
                                                                            responseDeleteBranchTabloid.status ===
                                                                            204
                                                                        ) {
                                                                            setShowAlert(
                                                                                true
                                                                            );
                                                                            setAlertTitle(
                                                                                "Tabloide excluído com sucesso!"
                                                                            );
                                                                            setAlertText(
                                                                                `O Tabloide ${cellInfo.name} foi excluída com sucesso!`
                                                                            );
                                                                            setAlertVariant(
                                                                                "success"
                                                                            );

                                                                            const updatedList = branchTabloid.filter(
                                                                                (
                                                                                    itemCampaign,
                                                                                    index
                                                                                ) =>
                                                                                    itemCampaign.id !==
                                                                                    cellInfo.id
                                                                            );
                                                                            setBranchTabloid(
                                                                                updatedList
                                                                            );
                                                                        }
                                                                    } catch (err) {
                                                                        setShowAlert(
                                                                            true
                                                                        );
                                                                        setAlertTitle(
                                                                            "Não foi possível excluir o abloide!"
                                                                        );
                                                                        setAlertText(
                                                                            `O Tabloide ${cellInfo.name} não pode ser excluído. Contate o suporte!`
                                                                        );
                                                                        setAlertVariant(
                                                                            "danger"
                                                                        );
                                                                    }
                                                                }
                                                                deleteBranchTabloid(
                                                                    cellInfo.id
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        },
                                    },
                                ]}
                                data={branchTabloid}
                            />
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(BranchTabloidList);
