export const TOKEN_KEY =
    "iashdihasdbamdbamsndbioweqiueoqiwena,smdnm,asnd2981u9823rkjhewfkjb";
export const TOKEN_BRANCHID =
    "asdjan,msdna,msfnoeiru9348yurwhefkshfkhsef9w8e9fyh";
export const TOKEN_USERTYPE =
    "josidu29ecu2398u4n293mxrkpkx2u31983y1982ynx12xm10uex,";
export const TOKEN_NAMEUSER = "o34ur9c8y498rc3y478xjy438t7c56tx384tyx39tx938ty";
export const TOKEN_USERID =
    "aoy83ryc8723ry87wyrjx87w3yr87w3ycriacny3t7c93n87crt9rtca3rt873y87a83cn73yc";
export const TOKEN_BRANCHNAME =
    "x9n893787tw8rg93rtx0mr87T3RC7N30R7T80xn3wzrgGKJAGERnc*&Tgqgt48gq384gmz8g384";
