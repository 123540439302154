import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import "@empd/reactable/lib/styles.css";

import { trim, size } from "lodash";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Spinner,
    Card,
    CardImg,
    Form,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import InputMask from "react-input-mask";

import api from "../../service/api";
import { TOKEN_USERID } from "../../service/tokens";

const UserProfile = (props) => {
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [occupation, setOccupation] = useState("");
    const [isWhatsAppPhone, setIsWhatsAppPhone] = useState(false);

    const [mask, setMask] = useState("(99) 99999-9999");

    const phoneRef = useRef(null);

    useEffect(
        function () {
            async function loadUser() {
                const userId = Number(
                    window.localStorage.getItem(TOKEN_USERID)
                );

                const responseGetUser = await api.get(`/user/${userId}`);

                if (responseGetUser.data.error) {
                    alert(
                        "Erro ao buscar os dados do usuário. Contate o suporte!"
                    );
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setEmail(responseGetUser.data.data.user.email);
                    setPhone(responseGetUser.data.data.user.phone);
                    setName(responseGetUser.data.data.user.name);
                    setOccupation(responseGetUser.data.data.user.occupation);
                    setIsWhatsAppPhone(
                        responseGetUser.data.data.user.isWhatsAppPhone
                    );
                }
            }

            loadUser();
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function postUserProfile() {
            const userId = Number(window.localStorage.getItem(TOKEN_USERID));

            const responsePostUserProfile = await api.post(
                `/user/${userId}/update-profile`,
                {
                    email: email,
                    phone: phoneRef.current.value,
                    name: name,
                    occupation: occupation,
                    isWhatsAppPhone: isWhatsAppPhone,
                }
            );

            if (
                responsePostUserProfile.data.error ||
                responsePostUserProfile.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar seus dados.");
                setAlertText(
                    `Tivemos problemas ao atualizar seus dados. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Dados atualizados com sucesso!");
                setAlertText(
                    `Você será redirecionado automaticamente para a home.`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/dashboard");
                }, 3000);
            }
        }

        postUserProfile();

        // console.log(phoneRef.current);
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Atualizar perfil
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o e-mail corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formPhone">
                                    <Form.Label>Telefone</Form.Label>
                                    <InputMask
                                        mask={mask}
                                        maskChar="_"
                                        ref={phoneRef}
                                        onBlur={(e) => {
                                            if (
                                                size(
                                                    trim(e.target.value, "_")
                                                ) === 14
                                            ) {
                                                setMask("(99) 9999-9999");
                                                console.log(e.target.value);
                                            }
                                        }}
                                        onFocus={(e) => {
                                            if (
                                                size(
                                                    trim(e.target.value, "_")
                                                ) === 14
                                            ) {
                                                setMask("(99) 99999-9999");
                                                console.log(e.target.value);
                                            }
                                        }}
                                        value={phone}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            console.log(value);
                                            setPhone(value);
                                        }}
                                    >
                                        {(inputProps) => (
                                            <Form.Control type="tel" />
                                        )}
                                    </InputMask>
                                    <Form.Text className="text-muted">
                                        Digite o telefone corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formOccupation">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={occupation}
                                        onChange={(e) => {
                                            setOccupation(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o cargo corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formIsWhatsAppPhone">
                                    <Form.Check
                                        type="checkbox"
                                        label="Telefone com WhatsApp"
                                        checked={isWhatsAppPhone}
                                        onChange={() =>
                                            setIsWhatsAppPhone(!isWhatsAppPhone)
                                        }
                                    />
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Atualizar!
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(UserProfile);
