import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    Form,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Dropdown,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const CardLayoutList = (props) => {
    const [cardLayouts, setCardLayouts] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    useEffect(
        function () {
            async function loadCardLayouts() {
                const responseGetCardLayouts = await api.get(`/card-layout`);
                if (responseGetCardLayouts.data.error) {
                    alert("Erro ao buscar os layouts. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetCardLayouts.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else setCardLayouts(responseGetCardLayouts.data.data);
                }
            }

            loadCardLayouts();
        },
        [props.history]
    );

    return (
        <>
            <CustomNavbar />
            <main>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Layouts
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome",
                                        sortable: true,
                                        selector: "name",
                                    },
                                    {
                                        name: "Nome",
                                        sortable: true,
                                        cell: (cellInfo) =>
                                            `${cellInfo.branch.name} - ${cellInfo.branch.email}`,
                                    },
                                    {
                                        name: "Layout Gazin?",
                                        sortable: false,
                                        cell: (cellInfo) =>
                                            cellInfo.isGazinLayout
                                                ? "Sim"
                                                : "Não",
                                    },
                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                    >
                                                        Opções
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="primary"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    props.history.push(
                                                                        `/layouts/${cellInfo.id}/edit`,
                                                                        {
                                                                            product: cellInfo,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Editar
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                variant="danger"
                                                                onClick={() => {
                                                                    var trash = window.confirm(
                                                                        "Para Excluir pressione OK!"
                                                                    );
                                                                    if (
                                                                        trash ===
                                                                        true
                                                                    ) {
                                                                        async function deleteCardLayout(
                                                                            id
                                                                        ) {
                                                                            try {
                                                                                const responseDeleteCardLayout = await api.delete(
                                                                                    `/card-layout/${id}`
                                                                                );
                                                                                if (
                                                                                    responseDeleteCardLayout.status ===
                                                                                    204
                                                                                ) {
                                                                                    setShowAlert(
                                                                                        true
                                                                                    );
                                                                                    setAlertTitle(
                                                                                        "Layout excluído com sucesso!"
                                                                                    );
                                                                                    setAlertText(
                                                                                        `O layout ${cellInfo.name} foi excluído com sucesso!`
                                                                                    );
                                                                                    setAlertVariant(
                                                                                        "success"
                                                                                    );

                                                                                    const updatedList = cardLayouts.filter(
                                                                                        (
                                                                                            itemCardLayout,
                                                                                            index
                                                                                        ) =>
                                                                                            itemCardLayout.id !==
                                                                                            cellInfo.id
                                                                                    );
                                                                                    setCardLayouts(
                                                                                        updatedList
                                                                                    );
                                                                                }
                                                                            } catch (err) {
                                                                                setShowAlert(
                                                                                    true
                                                                                );
                                                                                setAlertTitle(
                                                                                    "Não foi possível excluir o layout!"
                                                                                );
                                                                                setAlertText(
                                                                                    `O layout ${cellInfo.name} não pode ser excluído. Contate o suporte!`
                                                                                );
                                                                                setAlertVariant(
                                                                                    "danger"
                                                                                );
                                                                            }
                                                                        }
                                                                        deleteCardLayout(
                                                                            cellInfo.id
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Excluir
                                                            </Button>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            );
                                        },
                                    },
                                ]}
                                data={cardLayouts}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(CardLayoutList);
