import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const CardLayoutCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [branches, setBranches] = useState([]);
    const [fonts, setFonts] = useState([]);

    const [name, setName] = useState(null);
    const [isGazinLayout, setIsGazinLayout] = useState(false);
    const [file, setFile] = useState(null);
    const [branch, setBranch] = useState(null);

    const [nameClientFont, setNameClientFont] = useState(null);
    const [nameClientFontColor, setNameClientFontColor] = useState(null);
    const [nameClientFontSize, setNameClientFontSize] = useState(null);
    const [nameClientX, setNameClientX] = useState(null);
    const [nameClientY, setNameClientY] = useState(null);

    const [productCodeFont, setProductCodeFont] = useState(null);
    const [productCodeFontColor, setProductCodeFontColor] = useState(null);
    const [productCodeFontSize, setProductCodeFontSize] = useState(null);
    const [productCodeX, setProductCodeX] = useState(null);
    const [productCodeY, setProductCodeY] = useState(null);

    const [nameProductFont, setNameProductFont] = useState(null);
    const [nameProductFontColor, setNameProductFontColor] = useState(null);
    const [nameProductFontSize, setNameProductFontSize] = useState(null);
    const [nameProductX, setNameProductX] = useState(null);
    const [nameProductY, setNameProductY] = useState(null);

    const [featuresFont, setFeaturesFont] = useState(null);
    const [featuresFontColor, setFeaturesFontColor] = useState(null);
    const [featuresFontSize, setFeaturesFontSize] = useState(null);

    const [featureOneX, setFeatureOneX] = useState(null);
    const [featureOneY, setFeatureOneY] = useState(null);

    const [featureTwoX, setFeatureTwoX] = useState(null);
    const [featureTwoY, setFeatureTwoY] = useState(null);

    const [featureThreeX, setFeatureThreeX] = useState(null);
    const [featureThreeY, setFeatureThreeY] = useState(null);

    const [featureFourX, setFeatureFourX] = useState(null);
    const [featureFourY, setFeatureFourY] = useState(null);

    const [paymentXCarneFont, setPaymentXCarneFont] = useState(null);
    const [paymentXCarneFontColor, setPaymentXCarneFontColor] = useState(null);
    const [paymentXCarneFontSize, setPaymentXCarneFontSize] = useState(null);
    const [paymentXCarneX, setPaymentXCarneX] = useState(null);
    const [paymentXCarneY, setPaymentXCarneY] = useState(null);

    const [paymentXCreditCardFont, setPaymentXCreditCardFont] = useState(null);
    const [
        paymentXCreditCardFontColor,
        setPaymentXCreditCardFontColor,
    ] = useState(null);
    const [
        paymentXCreditCardFontSize,
        setPaymentXCreditCardFontSize,
    ] = useState(null);
    const [paymentXCreditCardX, setPaymentXCreditCardX] = useState(null);
    const [paymentXCreditCardY, setPaymentXCreditCardY] = useState(null);

    const [textCarneFont, setTextCarneFont] = useState(null);
    const [textCarneFontColor, setTextCarneFontColor] = useState(null);
    const [textCarneFontSize, setTextCarneFontSize] = useState(null);
    const [textCarneX, setTextCarneX] = useState(null);
    const [textCarneY, setTextCarneY] = useState(null);

    const [withoutInterestCarneFont, setWithoutInterestCarneFont] = useState(
        null
    );
    const [
        withoutInterestCarneFontColor,
        setWithoutInterestCarneFontColor,
    ] = useState(null);
    const [
        withoutInterestCarneFontSize,
        setWithoutInterestCarneFontSize,
    ] = useState(null);
    const [withoutInterestCarneX, setWithoutInterestCarneX] = useState(null);
    const [withoutInterestCarneY, setWithoutInterestCarneY] = useState(null);

    const [textCreditCardFont, setTextCreditCardFont] = useState(null);
    const [textCreditCardFontColor, setTextCreditCardFontColor] = useState(
        null
    );
    const [textCreditCardFontSize, setTextCreditCardFontSize] = useState(null);
    const [textCreditCardX, setTextCreditCardX] = useState(null);
    const [textCreditCardY, setTextCreditCardY] = useState(null);

    const [
        withoutInterestCreditCardFont,
        setWithoutInterestCreditCardFont,
    ] = useState(null);
    const [
        withoutInterestCreditCardFontColor,
        setWithoutInterestCreditCardFontColor,
    ] = useState(null);
    const [
        withoutInterestCreditCardFontSize,
        setWithoutInterestCreditCardFontSize,
    ] = useState(null);
    const [
        withoutInterestCreditCardX,
        setWithoutInterestCreditCardX,
    ] = useState(null);
    const [
        withoutInterestCreditCardY,
        setWithoutInterestCreditCardY,
    ] = useState(null);

    const [textSightPriceFont, setTextSightPriceFont] = useState(null);
    const [textSightPriceFontColor, setTextSightPriceFontColor] = useState(
        null
    );
    const [textSightPriceFontSize, setTextSightPriceFontSize] = useState(null);
    const [textSightPriceX, setTextSightPriceX] = useState(null);
    const [textSightPriceY, setTextSightPriceY] = useState(null);

    const [sightPriceOneFont, setSightPriceOneFont] = useState(null);
    const [sightPriceOneFontColor, setSightPriceOneFontColor] = useState(null);
    const [sightPriceOneFontSize, setSightPriceOneFontSize] = useState(null);
    const [sightPriceOneX, setSightPriceOneX] = useState(null);
    const [sightPriceOneY, setSightPriceOneY] = useState(null);
    const [
        sightPriceOneCentsFontSize,
        setSightPriceOneCentsFontSize,
    ] = useState(null);
    const [sightPriceOneCentsX, setSightPriceOneCentsX] = useState(null);
    const [sightPriceOneCentsY, setSightPriceOneCentsY] = useState(null);
    const [sightPriceOneMaxWidth, setSightPriceOneMaxWidth] = useState(null);

    const [sightPriceTwoFont, setSightPriceTwoFont] = useState(null);
    const [sightPriceTwoFontColor, setSightPriceTwoFontColor] = useState(null);
    const [sightPriceTwoFontSize, setSightPriceTwoFontSize] = useState(null);
    const [sightPriceTwoX, setSightPriceTwoX] = useState(null);
    const [sightPriceTwoY, setSightPriceTwoY] = useState(null);
    const [
        sightPriceTwoCentsFontSize,
        setSightPriceTwoCentsFontSize,
    ] = useState(null);
    const [sightPriceTwoCentsX, setSightPriceTwoCentsX] = useState(null);
    const [sightPriceTwoCentsY, setSightPriceTwoCentsY] = useState(null);
    const [sightPriceTwoMaxWidth, setSightPriceTwoMaxWidth] = useState(null);

    const [percentStateOneFont, setPercentStateOneFont] = useState(null);
    const [percentStateOneFontColor, setPercentStateOneFontColor] = useState(
        null
    );
    const [percentStateOneFontSize, setPercentStateOneFontSize] = useState(
        null
    );
    const [percentStateOneX, setPercentStateOneX] = useState(null);
    const [percentStateOneY, setPercentStateOneY] = useState(null);

    const [percentStateTwoFont, setPercentStateTwoFont] = useState(null);
    const [percentStateTwoFontColor, setPercentStateTwoFontColor] = useState(
        null
    );
    const [percentStateTwoFontSize, setPercentStateTwoFontSize] = useState(
        null
    );
    const [percentStateTwoX, setPercentStateTwoX] = useState(null);
    const [percentStateTwoY, setPercentStateTwoY] = useState(null);

    const [
        addtionalInformationOneFont,
        setAddtionalInformationOneFont,
    ] = useState(null);
    const [
        addtionalInformationOneFontColor,
        setAddtionalInformationOneFontColor,
    ] = useState(null);
    const [
        addtionalInformationOneFontSize,
        setAddtionalInformationOneFontSize,
    ] = useState(null);
    const [addtionalInformationOneX, setAddtionalInformationOneX] = useState(
        null
    );
    const [addtionalInformationOneY, setAddtionalInformationOneY] = useState(
        null
    );

    const [
        addtionalInformationTwoFont,
        setAddtionalInformationTwoFont,
    ] = useState(null);
    const [
        addtionalInformationTwoFontColor,
        setAddtionalInformationTwoFontColor,
    ] = useState(null);
    const [
        addtionalInformationTwoFontSize,
        setAddtionalInformationTwoFontSize,
    ] = useState(null);
    const [addtionalInformationTwoX, setAddtionalInformationTwoX] = useState(
        null
    );
    const [addtionalInformationTwoY, setAddtionalInformationTwoY] = useState(
        null
    );

    const [textPaymentXPriceFont, setTextPaymentXPriceFont] = useState(null);
    const [
        textPaymentXPriceFontColor,
        setTextPaymentXPriceFontColor,
    ] = useState(null);
    const [textPaymentXPriceFontSize, setTextPaymentXPriceFontSize] = useState(
        null
    );
    const [textPaymentXPriceX, setTextPaymentXPriceX] = useState(null);
    const [textPaymentXPriceY, setTextPaymentXPriceY] = useState(null);

    const [dollarSignFont, setDollarSignFont] = useState(null);
    const [dollarSignFontColor, setDollarSignFontColor] = useState(null);
    const [dollarSignFontSize, setDollarSignFontSize] = useState(null);
    const [dollarSignX, setDollarSignX] = useState(null);
    const [dollarSignY, setDollarSignY] = useState(null);

    const [sightPriceFont, setSightPriceFont] = useState(null);
    const [sightPriceFontColor, setSightPriceFontColor] = useState(null);
    const [sightPriceFontSize, setSightPriceFontSize] = useState(null);
    const [
        sightPriceFontSizeHighlight,
        setSightPriceFontSizeHighlight,
    ] = useState(null);
    const [sightPriceX, setSightPriceX] = useState(null);
    const [sightPriceY, setSightPriceY] = useState(null);
    const [sightPriceCentsFontSize, setSightPriceCentsFontSize] = useState(
        null
    );
    const [
        sightPriceCentsFontSizeHighlight,
        setSightPriceCentsFontSizeHighlight,
    ] = useState(null);
    const [sightPriceCentsX, setSightPriceCentsX] = useState(null);
    const [sightPriceCentsY, setSightPriceCentsY] = useState(null);
    const [sightPriceMaxWidth, setSightPriceMaxWidth] = useState(null);

    const [pricePaymentFont, setPricePaymentFont] = useState(null);
    const [pricePaymentFontColor, setPricePaymentFontColor] = useState(null);
    const [pricePaymentFontSize, setPricePaymentFontSize] = useState(null);
    const [
        pricePaymentFontSizeHighlight,
        setPricePaymentFontSizeHighlight,
    ] = useState(null);
    const [pricePaymentX, setPricePaymentX] = useState(null);
    const [pricePaymentY, setPricePaymentY] = useState(null);
    const [pricePaymentCentsFontSize, setPricePaymentCentsFontSize] = useState(
        null
    );
    const [
        pricePaymentCentsFontSizeHighlight,
        setPricePaymentCentsFontSizeHighlight,
    ] = useState(null);
    const [pricePaymentCentsX, setPricePaymentCentsX] = useState(null);
    const [pricePaymentCentsY, setPricePaymentCentsY] = useState(null);
    const [pricePaymentMaxWidth, setPricePaymentMaxWidth] = useState(null);

    const [totalAmountFont, setTotalAmountFont] = useState(null);
    const [totalAmountFontColor, setTotalAmountFontColor] = useState(null);
    const [totalAmountFontSize, setTotalAmountFontSize] = useState(null);
    const [totalAmountX, setTotalAmountX] = useState(null);
    const [totalAmountY, setTotalAmountY] = useState(null);

    useEffect(
        function () {
            async function getBranches() {
                const responseGetBranches = await api.get("/branch");
                if (responseGetBranches.data.error) {
                    alert("Erro ao buscar as empresas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setBranches(responseGetBranches.data.data);
                }
            }

            async function getFonts() {
                const responseGetFonts = await api.get("/font");
                if (responseGetFonts.data.error) {
                    alert("Erro ao buscar as Fontes. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setFonts(responseGetFonts.data.data);
                }
            }

            getBranches();
            getFonts();
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append("example_image", file);
        formData.append("name", name);
        formData.append("isGazinLayout", isGazinLayout);
        formData.append("branch", branch);
        formData.append("nameClientFont", nameClientFont);
        formData.append("nameClientFontColor", nameClientFontColor);
        formData.append("nameClientFontSize", nameClientFontSize);
        formData.append("nameClientX", nameClientX);
        formData.append("nameClientY", nameClientY);
        formData.append("nameProductFont", nameProductFont);
        formData.append("nameProductFontColor", nameProductFontColor);
        formData.append("nameProductFontSize", nameProductFontSize);
        formData.append("nameProductX", nameProductX);
        formData.append("nameProductY", nameProductY);
        formData.append("productCodeFont", productCodeFont);
        formData.append("productCodeFontColor", productCodeFontColor);
        formData.append("productCodeFontSize", productCodeFontSize);
        formData.append("productCodeX", productCodeX);
        formData.append("productCodeY", productCodeY);
        formData.append("featuresFont", featuresFont);
        formData.append("featuresFontColor", featuresFontColor);
        formData.append("featuresFontSize", featuresFontSize);
        formData.append("featureOneX", featureOneX);
        formData.append("featureOneY", featureOneY);
        formData.append("featureTwoX", featureTwoX);
        formData.append("featureTwoY", featureTwoY);
        formData.append("featureThreeX", featureThreeX);
        formData.append("featureThreeY", featureThreeY);
        formData.append("featureFourX", featureFourX);
        formData.append("featureFourY", featureFourY);
        formData.append("paymentXCarneFont", paymentXCarneFont);
        formData.append("paymentXCarneFontColor", paymentXCarneFontColor);
        formData.append("paymentXCarneFontSize", paymentXCarneFontSize);
        formData.append("paymentXCarneX", paymentXCarneX);
        formData.append("paymentXCarneY", paymentXCarneY);
        formData.append("paymentXCreditCardFont", paymentXCreditCardFont);
        formData.append(
            "paymentXCreditCardFontColor",
            paymentXCreditCardFontColor
        );
        formData.append(
            "paymentXCreditCardFontSize",
            paymentXCreditCardFontSize
        );
        formData.append("paymentXCreditCardX", paymentXCreditCardX);
        formData.append("paymentXCreditCardY", paymentXCreditCardY);
        formData.append("textCarneFont", textCarneFont);
        formData.append("textCarneFontColor", textCarneFontColor);
        formData.append("textCarneFontSize", textCarneFontSize);
        formData.append("textCarneX", textCarneX);
        formData.append("textCarneY", textCarneY);
        formData.append("withoutInterestCarneFont", withoutInterestCarneFont);
        formData.append(
            "withoutInterestCarneFontColor",
            withoutInterestCarneFontColor
        );
        formData.append(
            "withoutInterestCarneFontSize",
            withoutInterestCarneFontSize
        );
        formData.append("withoutInterestCarneX", withoutInterestCarneX);
        formData.append("withoutInterestCarneY", withoutInterestCarneY);
        formData.append("textCreditCardFont", textCreditCardFont);
        formData.append("textCreditCardFontColor", textCreditCardFontColor);
        formData.append("textCreditCardFontSize", textCreditCardFontSize);
        formData.append("textCreditCardX", textCreditCardX);
        formData.append("textCreditCardY", textCreditCardY);
        formData.append(
            "withoutInterestCreditCardFont",
            withoutInterestCreditCardFont
        );
        formData.append(
            "withoutInterestCreditCardFontColor",
            withoutInterestCreditCardFontColor
        );
        formData.append(
            "withoutInterestCreditCardFontSize",
            withoutInterestCreditCardFontSize
        );
        formData.append(
            "withoutInterestCreditCardX",
            withoutInterestCreditCardX
        );
        formData.append(
            "withoutInterestCreditCardY",
            withoutInterestCreditCardY
        );
        formData.append("textSightPriceFont", textSightPriceFont);
        formData.append("textSightPriceFontColor", textSightPriceFontColor);
        formData.append("textSightPriceFontSize", textSightPriceFontSize);
        formData.append("textSightPriceX", textSightPriceX);
        formData.append("textSightPriceY", textSightPriceY);
        formData.append("sightPriceOneFont", sightPriceOneFont);
        formData.append("sightPriceOneFontColor", sightPriceOneFontColor);
        formData.append("sightPriceOneFontSize", sightPriceOneFontSize);
        formData.append("sightPriceOneX", sightPriceOneX);
        formData.append("sightPriceOneY", sightPriceOneY);
        formData.append(
            "sightPriceOneCentsFontSize",
            sightPriceOneCentsFontSize
        );
        formData.append("sightPriceOneCentsX", sightPriceOneCentsX);
        formData.append("sightPriceOneCentsY", sightPriceOneCentsY);
        formData.append("sightPriceOneMaxWidth", sightPriceOneMaxWidth);
        formData.append("sightPriceTwoFont", sightPriceTwoFont);
        formData.append("sightPriceTwoFontColor", sightPriceTwoFontColor);
        formData.append("sightPriceTwoFontSize", sightPriceTwoFontSize);
        formData.append("sightPriceTwoX", sightPriceTwoX);
        formData.append("sightPriceTwoY", sightPriceTwoY);
        formData.append(
            "sightPriceTwoCentsFontSize",
            sightPriceTwoCentsFontSize
        );
        formData.append("sightPriceTwoCentsX", sightPriceTwoCentsX);
        formData.append("sightPriceTwoCentsY", sightPriceTwoCentsY);
        formData.append("sightPriceTwoMaxWidth", sightPriceTwoMaxWidth);
        formData.append("percentStateOneFont", percentStateOneFont);
        formData.append("percentStateOneFontColor", percentStateOneFontColor);
        formData.append("percentStateOneFontSize", percentStateOneFontSize);
        formData.append("percentStateOneX", percentStateOneX);
        formData.append("percentStateOneY", percentStateOneY);
        formData.append("percentStateTwoFont", percentStateTwoFont);
        formData.append("percentStateTwoFontColor", percentStateTwoFontColor);
        formData.append("percentStateTwoFontSize", percentStateTwoFontSize);
        formData.append("percentStateTwoX", percentStateTwoX);
        formData.append("percentStateTwoY", percentStateTwoY);
        formData.append(
            "addtionalInformationOneFont",
            addtionalInformationOneFont
        );
        formData.append(
            "addtionalInformationOneFontColor",
            addtionalInformationOneFontColor
        );
        formData.append(
            "addtionalInformationOneFontSize",
            addtionalInformationOneFontSize
        );
        formData.append("addtionalInformationOneX", addtionalInformationOneX);
        formData.append("addtionalInformationOneY", addtionalInformationOneY);
        formData.append(
            "addtionalInformationTwoFont",
            addtionalInformationTwoFont
        );
        formData.append(
            "addtionalInformationTwoFontColor",
            addtionalInformationTwoFontColor
        );
        formData.append(
            "addtionalInformationTwoFontSize",
            addtionalInformationTwoFontSize
        );
        formData.append("addtionalInformationTwoX", addtionalInformationTwoX);
        formData.append("addtionalInformationTwoY", addtionalInformationTwoY);
        formData.append("textPaymentXPriceFont", textPaymentXPriceFont);
        formData.append(
            "textPaymentXPriceFontColor",
            textPaymentXPriceFontColor
        );
        formData.append("textPaymentXPriceFontSize", textPaymentXPriceFontSize);
        formData.append("textPaymentXPriceX", textPaymentXPriceX);
        formData.append("textPaymentXPriceY", textPaymentXPriceY);
        formData.append("dollarSignFont", dollarSignFont);
        formData.append("dollarSignFontColor", dollarSignFontColor);
        formData.append("dollarSignFontSize", dollarSignFontSize);
        formData.append("dollarSignX", dollarSignX);
        formData.append("dollarSignY", dollarSignY);
        formData.append("sightPriceFont", sightPriceFont);
        formData.append("sightPriceFontColor", sightPriceFontColor);
        formData.append("sightPriceFontSize", sightPriceFontSize);
        formData.append(
            "sightPriceFontSizeHighlight",
            sightPriceFontSizeHighlight
        );
        formData.append("sightPriceX", sightPriceX);
        formData.append("sightPriceY", sightPriceY);
        formData.append("sightPriceCentsFontSize", sightPriceCentsFontSize);
        formData.append(
            "sightPriceCentsFontSizeHighlight",
            sightPriceCentsFontSizeHighlight
        );
        formData.append("sightPriceCentsX", sightPriceCentsX);
        formData.append("sightPriceCentsY", sightPriceCentsY);
        formData.append("sightPriceMaxWidth", sightPriceMaxWidth);
        formData.append("pricePaymentFont", pricePaymentFont);
        formData.append("pricePaymentFontColor", pricePaymentFontColor);
        formData.append("pricePaymentFontSize", pricePaymentFontSize);
        formData.append(
            "pricePaymentFontSizeHighlight",
            pricePaymentFontSizeHighlight
        );
        formData.append("pricePaymentX", pricePaymentX);
        formData.append("pricePaymentY", pricePaymentY);
        formData.append("pricePaymentCentsFontSize", pricePaymentCentsFontSize);
        formData.append(
            "pricePaymentCentsFontSizeHighlight",
            pricePaymentCentsFontSizeHighlight
        );
        formData.append("pricePaymentCentsX", pricePaymentCentsX);
        formData.append("pricePaymentCentsY", pricePaymentCentsY);
        formData.append("pricePaymentMaxWidth", pricePaymentMaxWidth);
        formData.append("totalAmountFont", totalAmountFont);
        formData.append("totalAmountFontColor", totalAmountFontColor);
        formData.append("totalAmountFontSize", totalAmountFontSize);
        formData.append("totalAmountX", totalAmountX);
        formData.append("totalAmountY", totalAmountY);

        async function postCardLayoutCreate() {
            const responsePostCardLayoutCreate = await api.post(
                "/card-layout",
                formData
            );

            if (
                responsePostCardLayoutCreate.data.error ||
                responsePostCardLayoutCreate.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao criar o layout.");
                setAlertText(
                    `Tivemos problemas ao tentar criar o layout para a empresa selecionada. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Layout criado com sucesso!");
                setAlertText(
                    `Voce sera automaticamente direcionado para a lista`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/layouts-list");
                }, 5000);
            }
        }

        postCardLayoutCreate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome Layout</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome Layout"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome desejado para o Layout.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formIsGazinLayout">
                                    <Form.Check
                                        type="checkbox"
                                        label="Layout Gazin?"
                                        checked={isGazinLayout}
                                        onChange={(e) => {
                                            setIsGazinLayout(!isGazinLayout);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCNPJ">
                                    <Form.Label>Empresa</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={branch}
                                        onChange={(e) => {
                                            setBranch(e.target.value);
                                        }}
                                    >
                                        <option>Escolha...</option>
                                        {branches.map((item, index) => (
                                            <option
                                                value={item.id}
                                            >{`${item.cnpj} - ${item.email}`}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formFile">
                                    <Form.Label>Imagem de Exemplo</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            setFile(e.target.files[0]);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Escolha a imagem correta.
                                    </Form.Text>
                                </Form.Group>

                                <Card>
                                    <Card.Header>Dados do Cliente</Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formNameClienteFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={nameClientFont}
                                                onChange={(e) => {
                                                    setNameClientFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formNameClientFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={nameClientFontColor}
                                                onChange={(e) => {
                                                    setNameClientFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formNameClientFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={nameClientFontSize}
                                                onChange={(e) => {
                                                    setNameClientFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formNameClientX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={nameClientX}
                                                onChange={(e) => {
                                                    setNameClientX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formNameClientY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={nameClientY}
                                                onChange={(e) => {
                                                    setNameClientY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>Dados do Produto</Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formNameClienteFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={nameProductFont}
                                                onChange={(e) => {
                                                    setNameProductFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formNameProductFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={nameProductFontColor}
                                                onChange={(e) => {
                                                    setNameProductFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formNameProductFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={nameProductFontSize}
                                                onChange={(e) => {
                                                    setNameProductFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formNameProductX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={nameProductX}
                                                onChange={(e) => {
                                                    setNameProductX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formNameProductY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={nameProductY}
                                                onChange={(e) => {
                                                    setNameProductY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Código do Produto
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formProductCodeFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={productCodeFont}
                                                onChange={(e) => {
                                                    setProductCodeFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formProductCodeFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={productCodeFontColor}
                                                onChange={(e) => {
                                                    setProductCodeFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formProductCodeFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={productCodeFontSize}
                                                onChange={(e) => {
                                                    setProductCodeFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formProductCodeX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={productCodeX}
                                                onChange={(e) => {
                                                    setProductCodeX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formProductCodeY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={productCodeY}
                                                onChange={(e) => {
                                                    setProductCodeY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados das Características
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formFeaturesFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={featuresFont}
                                                onChange={(e) => {
                                                    setFeaturesFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formFeaturesFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={featuresFontColor}
                                                onChange={(e) => {
                                                    setFeaturesFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeaturesFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={featuresFontSize}
                                                onChange={(e) => {
                                                    setFeaturesFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureOneX">
                                            <Form.Label>
                                                Posição Carac. 1 X
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 1 X"
                                                value={featureOneX}
                                                onChange={(e) => {
                                                    setFeatureOneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureOneY">
                                            <Form.Label>
                                                Posição Carac. 1 Y
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 1 Y"
                                                value={featureOneY}
                                                onChange={(e) => {
                                                    setFeatureOneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureTwoX">
                                            <Form.Label>
                                                Posição Carac. 2 X
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 2 X"
                                                value={featureTwoX}
                                                onChange={(e) => {
                                                    setFeatureTwoX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureTwoY">
                                            <Form.Label>
                                                Posição Carac. 2 Y
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 2 Y"
                                                value={featureTwoY}
                                                onChange={(e) => {
                                                    setFeatureTwoY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureThreeX">
                                            <Form.Label>
                                                Posição Carac. 3 X
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 3 X"
                                                value={featureThreeX}
                                                onChange={(e) => {
                                                    setFeatureThreeX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureThreeY">
                                            <Form.Label>
                                                Posição Carac. 3 Y
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 3 Y"
                                                value={featureThreeY}
                                                onChange={(e) => {
                                                    setFeatureThreeY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureFourX">
                                            <Form.Label>
                                                Posição Carac. 4 X
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 4 X"
                                                value={featureFourX}
                                                onChange={(e) => {
                                                    setFeatureFourX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFeatureFourY">
                                            <Form.Label>
                                                Posição Carac. 4 Y
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Carac. 4 Y"
                                                value={featureFourY}
                                                onChange={(e) => {
                                                    setFeatureFourY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados da Parcela no Carnê
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formPaymentXCarneFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={paymentXCarneFont}
                                                onChange={(e) => {
                                                    setPaymentXCarneFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCarneFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={paymentXCarneFontColor}
                                                onChange={(e) => {
                                                    setPaymentXCarneFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCarneFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={paymentXCarneFontSize}
                                                onChange={(e) => {
                                                    setPaymentXCarneFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCarneX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={paymentXCarneX}
                                                onChange={(e) => {
                                                    setPaymentXCarneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCarneY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={paymentXCarneY}
                                                onChange={(e) => {
                                                    setPaymentXCarneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados da Parcela no Cartão de Crédito
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formPaymentXCreditCardFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={paymentXCreditCardFont}
                                                onChange={(e) => {
                                                    setPaymentXCreditCardFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCreditCardFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={
                                                    paymentXCreditCardFontColor
                                                }
                                                onChange={(e) => {
                                                    setPaymentXCreditCardFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCreditCardFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    paymentXCreditCardFontSize
                                                }
                                                onChange={(e) => {
                                                    setPaymentXCreditCardFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCreditCardX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={paymentXCreditCardX}
                                                onChange={(e) => {
                                                    setPaymentXCreditCardX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPaymentXCreditCardY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={paymentXCreditCardY}
                                                onChange={(e) => {
                                                    setPaymentXCreditCardY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Texto (no carnê)
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formTextCarneFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={textCarneFont}
                                                onChange={(e) => {
                                                    setTextCarneFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCarneFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={textCarneFontColor}
                                                onChange={(e) => {
                                                    setTextCarneFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCarneFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={textCarneFontSize}
                                                onChange={(e) => {
                                                    setTextCarneFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCarneX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={textCarneX}
                                                onChange={(e) => {
                                                    setTextCarneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCarneY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={textCarneY}
                                                onChange={(e) => {
                                                    setTextCarneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Sem Juros no Carnê
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formWithoutInterestCarneFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={withoutInterestCarneFont}
                                                onChange={(e) => {
                                                    setWithoutInterestCarneFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCarneFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={
                                                    withoutInterestCarneFontColor
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCarneFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCarneFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    withoutInterestCarneFontSize
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCarneFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCarneX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={withoutInterestCarneX}
                                                onChange={(e) => {
                                                    setWithoutInterestCarneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCarneY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={withoutInterestCarneY}
                                                onChange={(e) => {
                                                    setWithoutInterestCarneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Texto (no cartão)
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formTextCreditCardFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={textCreditCardFont}
                                                onChange={(e) => {
                                                    setTextCreditCardFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCreditCardFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={textCreditCardFontColor}
                                                onChange={(e) => {
                                                    setTextCreditCardFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCreditCardFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={textCreditCardFontSize}
                                                onChange={(e) => {
                                                    setTextCreditCardFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCreditCardX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={textCreditCardX}
                                                onChange={(e) => {
                                                    setTextCreditCardX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextCreditCardY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={textCreditCardY}
                                                onChange={(e) => {
                                                    setTextCreditCardY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Sem Juros no Cartão de Crédito
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formWithoutInterestCreditCardFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={
                                                    withoutInterestCreditCardFont
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCreditCardFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCreditCardFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={
                                                    withoutInterestCreditCardFontColor
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCreditCardFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCreditCardFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    withoutInterestCreditCardFontSize
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCreditCardFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCreditCardX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={
                                                    withoutInterestCreditCardX
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCreditCardX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formWithoutInterestCreditCardY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={
                                                    withoutInterestCreditCardY
                                                }
                                                onChange={(e) => {
                                                    setWithoutInterestCreditCardY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Texto (à vista)
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formTextSightPriceFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={textSightPriceFont}
                                                onChange={(e) => {
                                                    setTextSightPriceFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formTextSightPriceFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={textSightPriceFontColor}
                                                onChange={(e) => {
                                                    setTextSightPriceFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextSightPriceFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={textSightPriceFontSize}
                                                onChange={(e) => {
                                                    setTextSightPriceFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextSightPriceX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={textSightPriceX}
                                                onChange={(e) => {
                                                    setTextSightPriceX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formTextSightPriceY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={textSightPriceY}
                                                onChange={(e) => {
                                                    setTextSightPriceY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Preço À Vista 1
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formSightPriceOneFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={sightPriceOneFont}
                                                onChange={(e) => {
                                                    setSightPriceOneFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={sightPriceOneFontColor}
                                                onChange={(e) => {
                                                    setSightPriceOneFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={sightPriceOneFontSize}
                                                onChange={(e) => {
                                                    setSightPriceOneFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={sightPriceOneX}
                                                onChange={(e) => {
                                                    setSightPriceOneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={sightPriceOneY}
                                                onChange={(e) => {
                                                    setSightPriceOneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneFontSize">
                                            <Form.Label>
                                                Tamanho Centavos
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    sightPriceOneCentsFontSize
                                                }
                                                onChange={(e) => {
                                                    setSightPriceOneCentsFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneX">
                                            <Form.Label>
                                                Posição Centavoes X
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={sightPriceOneCentsX}
                                                onChange={(e) => {
                                                    setSightPriceOneCentsX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneY">
                                            <Form.Label>
                                                Posição Centavos Y
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={sightPriceOneCentsY}
                                                onChange={(e) => {
                                                    setSightPriceOneCentsY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceOneMaxWidth">
                                            <Form.Label>
                                                Largura máxima do preço (para
                                                centralizar)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Largura Máxima do Preço"
                                                value={sightPriceOneMaxWidth}
                                                onChange={(e) => {
                                                    setSightPriceOneMaxWidth(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a largura correta.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Preço À Vista 2
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formSightPriceTwoFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={sightPriceTwoFont}
                                                onChange={(e) => {
                                                    setSightPriceTwoFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={sightPriceTwoFontColor}
                                                onChange={(e) => {
                                                    setSightPriceTwoFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={sightPriceTwoFontSize}
                                                onChange={(e) => {
                                                    setSightPriceTwoFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={sightPriceTwoX}
                                                onChange={(e) => {
                                                    setSightPriceTwoX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={sightPriceTwoY}
                                                onChange={(e) => {
                                                    setSightPriceTwoY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoFontSize">
                                            <Form.Label>
                                                Tamanho Centavos
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    sightPriceTwoCentsFontSize
                                                }
                                                onChange={(e) => {
                                                    setSightPriceTwoCentsFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoX">
                                            <Form.Label>
                                                Posição Centavoes X
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={sightPriceTwoCentsX}
                                                onChange={(e) => {
                                                    setSightPriceTwoCentsX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoY">
                                            <Form.Label>
                                                Posição Centavos Y
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={sightPriceTwoCentsY}
                                                onChange={(e) => {
                                                    setSightPriceTwoCentsY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formSightPriceTwoMaxWidth">
                                            <Form.Label>
                                                Largura máxima do preço (para
                                                centralizar)
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Largura Máxima do Preço"
                                                value={sightPriceTwoMaxWidth}
                                                onChange={(e) => {
                                                    setSightPriceTwoMaxWidth(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a largura correta.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Percentual e Estado (Preço 1)
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formPercentStateOneFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={percentStateOneFont}
                                                onChange={(e) => {
                                                    setPercentStateOneFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateOneFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={percentStateOneFontColor}
                                                onChange={(e) => {
                                                    setPercentStateOneFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateOneFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={percentStateOneFontSize}
                                                onChange={(e) => {
                                                    setPercentStateOneFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateOneX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={percentStateOneX}
                                                onChange={(e) => {
                                                    setPercentStateOneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateOneY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={percentStateOneY}
                                                onChange={(e) => {
                                                    setPercentStateOneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Percentual e Estado (Preço 2)
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formPercentStateTwoFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={percentStateTwoFont}
                                                onChange={(e) => {
                                                    setPercentStateTwoFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateTwoFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={percentStateTwoFontColor}
                                                onChange={(e) => {
                                                    setPercentStateTwoFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateTwoFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={percentStateTwoFontSize}
                                                onChange={(e) => {
                                                    setPercentStateTwoFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateTwoX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={percentStateTwoX}
                                                onChange={(e) => {
                                                    setPercentStateTwoX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formPercentStateTwoY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={percentStateTwoY}
                                                onChange={(e) => {
                                                    setPercentStateTwoY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Informação Adicional 1
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formAddtionalInformationOneFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={
                                                    addtionalInformationOneFont
                                                }
                                                onChange={(e) => {
                                                    setAddtionalInformationOneFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationOneFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={
                                                    addtionalInformationOneFontColor
                                                }
                                                onChange={(e) => {
                                                    setAddtionalInformationOneFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationOneFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    addtionalInformationOneFontSize
                                                }
                                                onChange={(e) => {
                                                    setAddtionalInformationOneFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationOneX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={addtionalInformationOneX}
                                                onChange={(e) => {
                                                    setAddtionalInformationOneX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationOneY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={addtionalInformationOneY}
                                                onChange={(e) => {
                                                    setAddtionalInformationOneY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        Dados do Informação Adicional 2
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group controlId="formAddtionalInformationTwoFont">
                                            <Form.Label>Fonte</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={
                                                    addtionalInformationTwoFont
                                                }
                                                onChange={(e) => {
                                                    setAddtionalInformationTwoFont(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Escolha...</option>
                                                {fonts.map((item, index) => (
                                                    <option
                                                        value={item.id}
                                                    >{`${item.name}`}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationTwoFontColor">
                                            <Form.Label>Cor</Form.Label>
                                            <Form.Control
                                                type="color"
                                                placeholder="Cor"
                                                value={
                                                    addtionalInformationTwoFontColor
                                                }
                                                onChange={(e) => {
                                                    setAddtionalInformationTwoFontColor(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Selecione a cor desejada.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationTwoFontSize">
                                            <Form.Label>Tamanho</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Tamanho"
                                                value={
                                                    addtionalInformationTwoFontSize
                                                }
                                                onChange={(e) => {
                                                    setAddtionalInformationTwoFontSize(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite o tamanho da fonte
                                                correta.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationTwoX">
                                            <Form.Label>Posição X</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição X"
                                                value={addtionalInformationTwoX}
                                                onChange={(e) => {
                                                    setAddtionalInformationTwoX(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição X corretamente.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formAddtionalInformationTwoY">
                                            <Form.Label>Posição Y</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Posição Y"
                                                value={addtionalInformationTwoY}
                                                onChange={(e) => {
                                                    setAddtionalInformationTwoY(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Digite a posição Y corretamente.
                                            </Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br />

                                {!isGazinLayout ? (
                                    <>
                                        <Card>
                                            <Card.Header>
                                                Dados do Texto Parcelas (Ex:
                                                1+9x)
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Group controlId="formTextPaymentXPriceFont">
                                                    <Form.Label>
                                                        Fonte
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={
                                                            textPaymentXPriceFont
                                                        }
                                                        onChange={(e) => {
                                                            setTextPaymentXPriceFont(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option>
                                                            Escolha...
                                                        </option>
                                                        {fonts.map(
                                                            (item, index) => (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >{`${item.name}`}</option>
                                                            )
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="formTextPaymentXPriceFontColor">
                                                    <Form.Label>Cor</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        placeholder="Cor"
                                                        value={
                                                            textPaymentXPriceFontColor
                                                        }
                                                        onChange={(e) => {
                                                            setTextPaymentXPriceFontColor(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Selecione a cor
                                                        desejada.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formTextPaymentXPriceFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            textPaymentXPriceFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setTextPaymentXPriceFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formTextPaymentXPriceX">
                                                    <Form.Label>
                                                        Posição X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={
                                                            textPaymentXPriceX
                                                        }
                                                        onChange={(e) => {
                                                            setTextPaymentXPriceX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formTextPaymentXPriceY">
                                                    <Form.Label>
                                                        Posição Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={
                                                            textPaymentXPriceY
                                                        }
                                                        onChange={(e) => {
                                                            setTextPaymentXPriceY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                Dados do Texto R$
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Group controlId="formDollarSignFont">
                                                    <Form.Label>
                                                        Fonte
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={dollarSignFont}
                                                        onChange={(e) => {
                                                            setDollarSignFont(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option>
                                                            Escolha...
                                                        </option>
                                                        {fonts.map(
                                                            (item, index) => (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >{`${item.name}`}</option>
                                                            )
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="formDollarSignFontColor">
                                                    <Form.Label>Cor</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        placeholder="Cor"
                                                        value={
                                                            dollarSignFontColor
                                                        }
                                                        onChange={(e) => {
                                                            setDollarSignFontColor(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Selecione a cor
                                                        desejada.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formDollarSignFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            dollarSignFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setDollarSignFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formDollarSignX">
                                                    <Form.Label>
                                                        Posição X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={dollarSignX}
                                                        onChange={(e) => {
                                                            setDollarSignX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formDollarSignY">
                                                    <Form.Label>
                                                        Posição Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={dollarSignY}
                                                        onChange={(e) => {
                                                            setDollarSignY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                Dados do Preço À Vista
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Group controlId="formSightPriceFont">
                                                    <Form.Label>
                                                        Fonte
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={sightPriceFont}
                                                        onChange={(e) => {
                                                            setSightPriceFont(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option>
                                                            Escolha...
                                                        </option>
                                                        {fonts.map(
                                                            (item, index) => (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >{`${item.name}`}</option>
                                                            )
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceFontColor">
                                                    <Form.Label>Cor</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        placeholder="Cor"
                                                        value={
                                                            sightPriceFontColor
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceFontColor(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Selecione a cor
                                                        desejada.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            sightPriceFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            sightPriceFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceX">
                                                    <Form.Label>
                                                        Posição X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={sightPriceX}
                                                        onChange={(e) => {
                                                            setSightPriceX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceY">
                                                    <Form.Label>
                                                        Posição Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={sightPriceY}
                                                        onChange={(e) => {
                                                            setSightPriceY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceCentsFontSize">
                                                    <Form.Label>
                                                        Tamanho Centavos
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            sightPriceCentsFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceCentsFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceCentsX">
                                                    <Form.Label>
                                                        Posição Centavoes X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={sightPriceCentsX}
                                                        onChange={(e) => {
                                                            setSightPriceCentsX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceCentsY">
                                                    <Form.Label>
                                                        Posição Centavos Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={sightPriceCentsY}
                                                        onChange={(e) => {
                                                            setSightPriceCentsY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceMaxWidth">
                                                    <Form.Label>
                                                        Largura máxima do preço
                                                        (para centralizar)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Largura Máxima do Preço"
                                                        value={
                                                            sightPriceMaxWidth
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceMaxWidth(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a largura
                                                        correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceFontSizeHighlight">
                                                    <Form.Label>
                                                        Tamanho (com destaque
                                                        nesse preço)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            sightPriceFontSizeHighlight
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceFontSizeHighlight(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formSightPriceCentsFontSizeHighlight">
                                                    <Form.Label>
                                                        Tamanho Centavos (com
                                                        destaque nesse preço)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            sightPriceCentsFontSizeHighlight
                                                        }
                                                        onChange={(e) => {
                                                            setSightPriceCentsFontSizeHighlight(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                Dados do Valor da Parcela
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Group controlId="formPricePaymentFont">
                                                    <Form.Label>
                                                        Fonte
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={pricePaymentFont}
                                                        onChange={(e) => {
                                                            setPricePaymentFont(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option>
                                                            Escolha...
                                                        </option>
                                                        {fonts.map(
                                                            (item, index) => (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >{`${item.name}`}</option>
                                                            )
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentFontColor">
                                                    <Form.Label>Cor</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        placeholder="Cor"
                                                        value={
                                                            pricePaymentFontColor
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentFontColor(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Selecione a cor
                                                        desejada.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            pricePaymentFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            pricePaymentFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentX">
                                                    <Form.Label>
                                                        Posição X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={pricePaymentX}
                                                        onChange={(e) => {
                                                            setPricePaymentX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentY">
                                                    <Form.Label>
                                                        Posição Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={pricePaymentY}
                                                        onChange={(e) => {
                                                            setPricePaymentY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentCentsFontSize">
                                                    <Form.Label>
                                                        Tamanho Centavos
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            pricePaymentCentsFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentCentsFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentCentsX">
                                                    <Form.Label>
                                                        Posição Centavoes X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={
                                                            pricePaymentCentsX
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentCentsX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentCentsY">
                                                    <Form.Label>
                                                        Posição Centavos Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={
                                                            pricePaymentCentsY
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentCentsY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentMaxWidth">
                                                    <Form.Label>
                                                        Largura máxima do preço
                                                        (para centralizar)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Largura Máxima do Preço"
                                                        value={
                                                            pricePaymentMaxWidth
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentMaxWidth(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a largura
                                                        correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentFontSizeHighlight">
                                                    <Form.Label>
                                                        Tamanho (com destaque
                                                        nesse preço)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            pricePaymentFontSizeHighlight
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentFontSizeHighlight(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formPricePaymentCentsFontSizeHighlight">
                                                    <Form.Label>
                                                        Tamanho Centavos (com
                                                        destaque nesse preço)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            pricePaymentCentsFontSizeHighlight
                                                        }
                                                        onChange={(e) => {
                                                            setPricePaymentCentsFontSizeHighlight(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                Dados do Total À Prazo
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Group controlId="formTotalAmountFont">
                                                    <Form.Label>
                                                        Fonte
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={totalAmountFont}
                                                        onChange={(e) => {
                                                            setTotalAmountFont(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option>
                                                            Escolha...
                                                        </option>
                                                        {fonts.map(
                                                            (item, index) => (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >{`${item.name}`}</option>
                                                            )
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId="formTotalAmountFontColor">
                                                    <Form.Label>Cor</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        placeholder="Cor"
                                                        value={
                                                            totalAmountFontColor
                                                        }
                                                        onChange={(e) => {
                                                            setTotalAmountFontColor(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Selecione a cor
                                                        desejada.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formTotalAmountFontSize">
                                                    <Form.Label>
                                                        Tamanho
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Tamanho"
                                                        value={
                                                            totalAmountFontSize
                                                        }
                                                        onChange={(e) => {
                                                            setTotalAmountFontSize(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite o tamanho da
                                                        fonte correta.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formTotalAmountX">
                                                    <Form.Label>
                                                        Posição X
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição X"
                                                        value={totalAmountX}
                                                        onChange={(e) => {
                                                            setTotalAmountX(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição X
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group controlId="formTotalAmountY">
                                                    <Form.Label>
                                                        Posição Y
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Posição Y"
                                                        value={totalAmountY}
                                                        onChange={(e) => {
                                                            setTotalAmountY(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Digite a posição Y
                                                        corretamente.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </>
                                ) : null}

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o nome desejado
                                                    para a Campanha
                                                </li>
                                                <li>
                                                    - Verifique se o CNPJ da
                                                    Empresa esta correto.
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(CardLayoutCreate);
