import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { logout } from "../../service/auth";
import {
    TOKEN_USERTYPE,
    TOKEN_NAMEUSER,
    TOKEN_BRANCHNAME,
} from "../../service/tokens";


const CustomNavbar = (props) => {
    const [useCPF, setUseCPF] = useState(false);
    const [useProducts, setUseProducts] = useState(false);
    const [useBranches, setUseBranches] = useState(false);
    const [useCampaigns, setUseCampaigns] = useState(false);
    const [useReport, setUseReport] = useState(false);
    const [useReportAdmin, setUseReportAdmin] = useState(false);
    const [useDirectMail, setUseDirectMail] = useState(false);
    const [useUser, setUseUser] = useState(false);
    const [useTabloid, setUseTabloid] = useState(false);
    const [useAd, setUseAd] = useState(false);
    const [useFont, setUseFont] = useState(false);
    const [useCardLayout, setUseCardLayout] = useState(false);
    const [useTabloidGenerateds, setUseTabloidGenerateds] = useState(false);
    const [useBigList, setUseBigList] = useState(false);
    const [useFaq, setUseFaq] = useState(false);
    const [useChangeBackground, setUseChangeBackground] = useState(false);
    const [nameUser, setNameUser] = useState("");

    useEffect(() => {
        const userType = Number(window.localStorage.getItem(TOKEN_USERTYPE));
        const userName = window.localStorage.getItem(TOKEN_NAMEUSER);

        setNameUser(userName);

        switch (userType) {
            case 0:
                setUseCPF(true);
                setUseProducts(true);
                setUseBranches(true);
                setUseCampaigns(true);
                setUseReport(true);
                setUseReportAdmin(true);
                setUseDirectMail(true);
                setUseUser(true);
                setUseTabloid(true);
                setUseAd(true);
                setUseCardLayout(true);
                setUseFont(true);
                setUseTabloidGenerateds(true);
                setUseBigList(false);
                setUseFaq(true);
                setUseChangeBackground(true);
                break;

            case 1:
                setUseCPF(true);
                setUseProducts(false);
                setUseBranches(false);
                setUseCampaigns(true);
                setUseReport(true);
                setUseReportAdmin(false);
                setUseDirectMail(false);
                setUseUser(true);
                setUseTabloid(true);
                setUseAd(true);
                setUseChangeBackground(true);
                break;

            case 3:
                setUseCPF(false);
                setUseProducts(false);
                setUseBranches(false);
                setUseCampaigns(true);
                setUseReport(false);
                setUseReportAdmin(false);
                setUseDirectMail(false);
                setUseUser(false);
                setUseTabloid(true);
                setUseAd(true);
                break;

            default:
                break;
        }
    }, []);

    return (
        <Navbar
            bg="dark"
            variant="dark"
            expand="lg"
            style={{ marginBottom: 60 }}
        >
            <Navbar.Brand
                style={{
                    cursor: "pointer",
                }}
                onClick={() => props.history.push("/dashboard")}
            >
                {Number(window.localStorage.getItem(TOKEN_USERTYPE)) === 1 ||
                Number(window.localStorage.getItem(TOKEN_USERTYPE)) === 3
                    ? `${window.localStorage.getItem(TOKEN_BRANCHNAME)} Express`
                    : "Cartão Express Partners"}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => props.history.push("/dashboard")}
                    >
                        Home
                    </Nav.Link>

                    {useCPF ? (
                        <NavDropdown
                            title="CPFs Autorizados"
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/cpf-authorized-list")
                                }
                            >
                                Listar Todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/cpf-create")
                                }
                            >
                                {" "}
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}

                    {useProducts ? (
                        <NavDropdown title="Produtos" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/product-list")
                                }
                            >
                                Listar Todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/product-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}

                    {useBranches ? (
                        <NavDropdown title="Empresas" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/branch-list")
                                }
                            >
                                Listar todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/branches-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}

                    {useFont ? (
                        <NavDropdown title="Fontes" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/fonts-list")
                                }
                            >
                                Listar todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/fonts-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}

                    {useCardLayout ? (
                        <NavDropdown title="Layouts" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/layouts-list")
                                }
                            >
                                Listar todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/layouts-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}

                    {useFaq ? (
                        <NavDropdown title="FAQs" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => props.history.push("/faq-list")}
                            >
                                Listar Todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/faq-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}

                    {useCampaigns ? (
                        <NavDropdown title="Campanha" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/campaign-list")
                                }
                            >
                                Listar Todas
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/campaign-create")
                                }
                            >
                                Adicionar Nova
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                    <NavDropdown title="Tab.Fale Vendedor" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/branch-tabloid-list")
                                }
                            >
                                Listar todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/branch-tabloid-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                    </NavDropdown>
                    {useDirectMail ? (
                        <NavDropdown
                            title="Mala Direta"
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/direct-mail-list")
                                }
                            >
                                Listar Todas
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/direct-mail-create")
                                }
                            >
                                Adicionar Nova
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                    {useBigList ? (
                        <NavDropdown title="Listão" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/biglist-list")
                                }
                            >
                                Listar Todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/biglist-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                    {useAd ? (
                        <NavDropdown title="Anúncios" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => props.history.push("/ad-list")}
                            >
                                Listar Todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => props.history.push("/ad-create")}
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                    {useTabloid ? (
                        <NavDropdown title="Tabloides" id="basic-nav-dropdown">
                            {useTabloidGenerateds ? (
                                <NavDropdown.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push(
                                            "/tabloid-list-generateds"
                                        )
                                    }
                                >
                                    Listar Gerados
                                </NavDropdown.Item>
                            ) : null}

                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/tabloid-list-projects")
                                }
                            >
                                Listar{" "}
                                {useTabloidGenerateds ? "Projetos" : "Todos"}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/tabloid-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                    
                    {useUser ? (
                        <NavDropdown title="Usuários" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => props.history.push("/user-list")}
                            >
                                Listar Todas
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/user-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                    {useReportAdmin ? (
                        <NavDropdown title="Relatórios" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/report-by-date")
                                }
                            >
                                Por Data
                            </NavDropdown.Item>
                            {/* <NavDropdown.Divider />
                                <NavDropdown.Item 
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => props.history.push('/user-create')}
                                >
                                    Adicionar Novo
                                </NavDropdown.Item> */}
                        </NavDropdown>
                    ) : null}
                    <Nav.Link
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => props.history.push("/faqs")}
                    >
                        Ajuda
                    </Nav.Link>
                    
                </Nav>
                <NavDropdown title={nameUser}>
                    <NavDropdown.Item
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            props.history.push("/user/photo");
                        }}
                    >
                        Atualizar Foto
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            props.history.push("/user/profile");
                        }}
                    >
                        Atualizar Dados
                    </NavDropdown.Item>
                    {/* <NavDropdown title="Tabloide Fale Com Vendedor" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/branch-tabloid-list")
                                }
                            >
                                Listar todos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.history.push("/branch-tabloid-create")
                                }
                            >
                                Adicionar Novo
                            </NavDropdown.Item>
                    </NavDropdown> */}
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            props.history.push("/branch/profile");
                        }}
                    >
                        Atualizar Dados da Empresa
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            props.history.push("/branch/logo");
                        }}
                    >
                        Logomarca
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {useChangeBackground ? (
                        <>
                            <NavDropdown.Item
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.history.push("/change-background");
                                }}
                            >
                                Atualizar Fundo do Vendedor
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </>
                    ) : null}
                    <NavDropdown.Item
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            logout();
                            props.history.push("/");
                        }}
                    >
                        Sair
                    </NavDropdown.Item>
                </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default withRouter(CustomNavbar);
