import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Row, Col, Form, Button, Card, CardImg } from 'react-bootstrap'

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import ShowInfo from '../../components/ShowInfo'
import MainContentSpaced from '../../components/MainContentSpaced'

import api from '../../service/api'
import { logout } from '../../service/auth'
import { TOKEN_BRANCHID, TOKEN_USERTYPE } from '../../service/tokens'

const DirectMailCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('')
    const [branches, setBranches] = useState([])
    const [name, setName] = useState(null)
    const [branch, setBranch] = useState(0)
    const [file, setFile] = useState(null)
    const [filePreview, setFilePreview] = useState(null)


    useEffect(function () {
        async function getBranches () {
            const branchId = window.localStorage.getItem(TOKEN_BRANCHID)
            const userType = window.localStorage.getItem(TOKEN_USERTYPE)

            if (branchId === null) {
                logout()
                props.history.push('/')
            }

            const responseGetBranches = await api.get('/branch')
            if (responseGetBranches.data.error) {
                alert('Erro ao buscar os CPFs. Contate o suporte!')
                setTimeout(function () {
                    props.history.push('/dashboard')
                }, 2000)
            } else {
                let branches = responseGetBranches.data.data
                if (userType === "1")
                    branches = responseGetBranches.data.data.filter(item => item.id === Number(branchId))
                
                setBranches(branches)
            }
        }
        getBranches()
    }, [props.history])


    function handleSubmit (e) {
        e.preventDefault()

        const formData = new FormData() 
        formData.append('image_direct_mail', file)
        formData.append('name', name)
        formData.append('branch', branch)

        async function postDirectMailCreate () {
            const responseDirectMailCreate = await api.post('/direct-mail', formData)            
            
            if (responseDirectMailCreate.data.error || responseDirectMailCreate.status === 500) {
                setShowAlert(true)
                setAlertTitle('Problemas ao criar a Mala Direta.')
                setAlertText(`Tivemos problemas ao tentar criar a Mala Direta. Por favor contate o suporte!`)
                setAlertVariant('danger')
            } else {
                setShowAlert(true)
                setAlertTitle('Mala Direta criada com sucesso!')
                setAlertText(`Voce sera automaticamente direcionado para a lista`)
                setAlertVariant('success')
    
                setTimeout(() => {
                    props.history.push('/direct-mail-list')
                }, 5000)
            }
        }

        postDirectMailCreate()  
    }

    return (
        <>
            <CustomNavbar/>
            <MainContentSpaced>
                <Container>
                <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome Mala Direta</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome Mala Direta"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome desejado para o fundo.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formCNPJ">
                                    <Form.Label>Empresa</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={branch}
                                        onChange={(e) => { setBranch(e.target.value) } }    
                                    >
                                        <option>Escolha...</option>
                                        {
                                            branches.map((item, index) => (
                                                <option key={index} value={item.id}>{`${item.cnpj} - ${item.email}`}</option>
                                            ))
                                        }                                        
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formFile">
                                    <Form.Label>Imagem</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            const fileUpload = e.target.files[0]
                                            setFile(fileUpload) 
                                            setFilePreview(URL.createObjectURL(fileUpload))
                                        } }    
                                    />
                                    <Form.Text className="text-muted">
                                        Escolha a imagem correta de fundo.
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>

                                {
                                    filePreview ?
                                        <Card
                                            style={{
                                                marginBottom: 40
                                            }}
                                        >
                                            <CardImg top src={filePreview} alt="Card image cap" />
                                            <Card.Body>
                                                <Card.Title>Preview da Imagem.</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    : null
                                }
                            </Form>
                        </Col>
                        {
                            showTrips ? (
                                <Col>
                                    <Card>
                                        <Card.Header>Dicas</Card.Header>
                                        <Card.Body>
                                            <Card.Title>Use essas dicas durante o cadastro:</Card.Title>
                                            <Card.Text>
                                                <div>
                                                    <ul className="list-unstyled">
                                                        <li>- Lembre-se que o nome utilizado aparecerá para o usuário escolher.</li>
                                                        <li>- Escolha a imagem correta.</li>
                                                    </ul>  
                                                </div>
                                            </Card.Text>
                                            <Button
                                                variant="warning"
                                                onClick={() => setShowTrips(false)}
                                            >
                                                Não quero mais ver as dicas!
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ) : null
                        }
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(DirectMailCreate)