import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    Form,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Dropdown,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";

import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const TabloidListProjects = (props) => {
    const [tabloids, setTabloids] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [tabloidView, setTabloidView] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [showModalGenerate, setShowModalGenerate] = useState(false);

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    const handleCloseModalGenerate = () => setShowModalGenerate(false);
    const handleShowModalGenerate = () => setShowModalGenerate(true);

    useEffect(
        function () {
            async function loadTabloids() {
                try {
                    const userType = Number(
                        window.localStorage.getItem(TOKEN_USERTYPE)
                    );
                    const branchId = Number(
                        window.localStorage.getItem(TOKEN_BRANCHID)
                    );

                    const responseGetTabloids = await api.get(
                        `/tabloid-projects${
                            userType !== 0 ? "/" + branchId : ""
                        }`
                    );
                    if (responseGetTabloids.data.error) {
                        alert(
                            "Erro ao buscar os tabloides. Contate o suporte!"
                        );
                        setTimeout(function () {
                            props.history.push("/dashboard");
                        }, 2000);
                    } else {
                        if (responseGetTabloids.data.data.length === 0)
                            setEmptyDataElement(
                                <Alert variant="dark">
                                    Não existem registros.
                                </Alert>
                            );
                        else setTabloids(responseGetTabloids.data.data);
                    }
                } catch (errorInGet) {
                    setEmptyDataElement(
                        <Alert variant="danger">
                            Problemas ao buscar dados.
                        </Alert>
                    );
                }
            }

            loadTabloids();
        },
        [props.history]
    );

    function returnYesOrNot(valueBool) {
        if (valueBool) return "Sim";
        else return "Não";
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Projetos de Tabloides
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome Projeto",
                                        sortable: true,
                                        selector: "projectName",
                                    },
                                    {
                                        name: "Data Criação",
                                        sortable: false,
                                        selector: "createdAt",
                                    },
                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                    >
                                                        Opções
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="primary"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    props.history.push(
                                                                        `/tabloid-edit/${cellInfo.id}`
                                                                    );
                                                                }}
                                                            >
                                                                ABRIR PROJETO
                                                            </Button>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="danger"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    var trash = window.confirm(
                                                                        "Para Excluir pressione OK!"
                                                                    );
                                                                    if (
                                                                        trash ===
                                                                        true
                                                                    ) {
                                                                        async function deleteProject(
                                                                            id
                                                                        ) {
                                                                            try {
                                                                                const responseDeleteProject = await api.delete(
                                                                                    `/tabloid/${id}`
                                                                                );
                                                                                if (
                                                                                    responseDeleteProject.status ===
                                                                                    204
                                                                                ) {
                                                                                    setShowAlert(
                                                                                        true
                                                                                    );
                                                                                    setAlertTitle(
                                                                                        "Projeto excluído com sucesso!"
                                                                                    );
                                                                                    setAlertText(
                                                                                        `O projeto ${cellInfo.projectName} foi excluído com sucesso!`
                                                                                    );
                                                                                    setAlertVariant(
                                                                                        "success"
                                                                                    );

                                                                                    const updatedList = tabloids.filter(
                                                                                        (
                                                                                            itemTabloidProject,
                                                                                            index
                                                                                        ) =>
                                                                                            itemTabloidProject.id !==
                                                                                            cellInfo.id
                                                                                    );
                                                                                    setTabloids(
                                                                                        updatedList
                                                                                    );
                                                                                }
                                                                            } catch (err) {
                                                                                setShowAlert(
                                                                                    true
                                                                                );
                                                                                setAlertTitle(
                                                                                    "Não foi possível excluir o projeto!"
                                                                                );
                                                                                setAlertText(
                                                                                    `O projeto ${cellInfo.projectName} não pode ser excluído. Contate o suporte!`
                                                                                );
                                                                                setAlertVariant(
                                                                                    "danger"
                                                                                );
                                                                            }
                                                                        }
                                                                        deleteProject(
                                                                            cellInfo.id
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                EXCLUIR
                                                            </Button>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            );
                                        },
                                    },
                                ]}
                                data={tabloids}
                            />
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(TabloidListProjects);
