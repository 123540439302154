import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import * as moment from "moment";

import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";
import TabloidItemList from "../../components/TabloidItemList";

import api from "../../service/api";
import { TOKEN_BRANCHID } from "../../service/tokens";

const TabloidCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [campaigns, setCampaigns] = useState([]);
    const [ads, setAds] = useState([{ id: 0, name: "Escolha..." }]);
    const [products, setProducts] = useState([]);

    const [idTabloid, setIdTabloid] = useState(undefined);

    const [qtdCarne, setQtdCarne] = useState(undefined);
    const [qtdCreditCard, setQtdCreditCard] = useState(undefined);
    const [useInterests, setUseInterests] = useState(false);
    const [withoutInterestCarne, setWithoutInterestCarne] = useState(false);
    const [withoutInterestCreditCard, setWithoutInterestCreditCard] = useState(
        false
    );
    const [campaignBase, setCampaignBase] = useState(undefined);
    const [campaignBaseName, setCampaignBaseName] = useState(undefined);
    const [distributionCenter, setDistributionCenter] = useState(undefined);
    const [validity, setValidity] = useState(undefined);
    const [juridicalText, setJuridicalText] = useState(undefined);
    const [projectName, setProjectName] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [percentSightPriceOne, setPercentSightPriceOne] = useState(undefined);
    const [percentStateSightPriceOne, setPercentStateSightPriceOne] = useState(
        undefined
    );
    const [percentSightPriceTwo, setPercentSightPriceTwo] = useState(undefined);
    const [percentStateSightPriceTwo, setPercentStateSightPriceTwo] = useState(
        undefined
    );
    const [addtionalInformationOne, setAddtionalInformationOne] = useState(
        undefined
    );
    const [addtionalInformationTwo, setAddtionalInformationTwo] = useState(
        undefined
    );
    const [clientName, setClientName] = useState(undefined);
    const [clientPhone, setClientPhone] = useState(undefined);
    const [clientEmail, setClientEmail] = useState(undefined);

    const [blobPDF, setBlobPDF] = useState(null);
    const [activeLoader, setActiveLoader] = useState(true);
    const [textLoader, setTextLoader] = useState("Carregando dados");

    const [baseItems, setBaseItems] = useState([]);
    const [tabloid, setTabloid] = useState(null);

    const [isGazinTabloid, setIsGazinTabloid] = useState(false);

    const [paymentX, setPaymentX] = useState(undefined);
    const [highlight, setHighlight] = useState(undefined);

    const [cardLayouts, setCardLayouts] = useState([]);
    const [cardLayoutSelected, setCardLayoutSelected] = useState(undefined);

    const [useCardCarne, setUseCardCarne] = useState(false);
    const [creditCard, setCreditCard] = useState(false);
    const [carne, setCarne] = useState(false);
    const [groupingFourInOne, setGroupingFourInOne] = useState(false);
    const [groupingSixteenInOne, setGroupingSixteenInOne] = useState(false);
    const [withoutMyCard, setWithoutMyCard] = useState(false);
    const [qtdCarnePartnersCover, setQtdCarnePartnersCover] = useState(
        undefined
    );
    const [
        qtdCreditCardPartnersCover,
        setQtdCreditCardPartnersCover,
    ] = useState(undefined);
    const [internalParcel, setInternalParcel] = useState(undefined);

    useEffect(
        function () {
            setIsGazinTabloid(
                parseInt(window.localStorage.getItem(TOKEN_BRANCHID)) === 1
            );
            async function getCardLayouts() {
                const responseGetCardLayouts = await api.get(
                    `/card-layout/${window.localStorage.getItem(
                        TOKEN_BRANCHID
                    )}`
                );
                if (responseGetCardLayouts.data.error) {
                    alert("Erro ao buscar as campanhas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setCardLayouts(responseGetCardLayouts.data.data);
                }
            }
            getCardLayouts();
            async function getCampaigns() {
                const responseGetCampaigns = await api.get(
                    `/campaign-tabloid/${window.localStorage.getItem(
                        TOKEN_BRANCHID
                    )}`
                );
                if (responseGetCampaigns.data.error) {
                    alert("Erro ao buscar as campanhas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setCampaigns(responseGetCampaigns.data.data);
                }
            }
            getCampaigns();
            async function getProducts() {
                const responseGetProducts = await api.get(`/product`);
                if (responseGetProducts.data.error) {
                    alert("Erro ao buscar os produtos. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    let productsToSelect = [
                        { value: "", label: "Selecione um produto" },
                    ];
                    await responseGetProducts.data.data.map((product) =>
                        productsToSelect.push({
                            value: product.id,
                            label: product.name,
                            price: product.price,
                            code: product.code,
                        })
                    );
                    setProducts((oldArray) => [
                        ...oldArray,
                        ...productsToSelect,
                    ]);
                }
            }
            getProducts();

            async function getAds() {
                const responseGetAds = await api.get(
                    `/ad-by-branch/${window.localStorage.getItem(
                        TOKEN_BRANCHID
                    )}`
                );
                if (responseGetAds.data.error) {
                    alert("Erro ao buscar as anúncios. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetAds.data.data.length > 0)
                        setAds((oldArray) => [
                            ...oldArray,
                            ...responseGetAds.data.data,
                        ]);
                }
            }
            getAds();

            setActiveLoader(false);

            if (props.match.params.id !== undefined) {
                async function getTabloidAndItems(id) {
                    const responseGetTabloidAndItems = await api.get(
                        `/tabloid/${id}`
                    );
                    if (responseGetTabloidAndItems.data.error) {
                        setActiveLoader(false);
                        alert(
                            "Erro ao buscar dados do tabloide para edição. Contate o suporte!"
                        );
                        setTimeout(function () {
                            props.history.push("/tabloid-list-projects");
                        }, 2000);
                    } else {
                        const {
                            tabloid,
                            tabloidItems,
                        } = responseGetTabloidAndItems.data.data;
                        console.log(tabloidItems);
                        setTabloid(tabloid);

                        setIdTabloid(tabloid.id);
                        setQtdCarne(tabloid.qtdCarne);
                        setQtdCreditCard(tabloid.qtdCreditCard);
                        setUseInterests(
                            tabloid.withoutInterestCarne !== undefined ||
                                tabloid.withoutInterestCreditCard !== undefined
                                ? true
                                : false
                        );
                        setWithoutInterestCarne(tabloid.withoutInterestCarne);
                        setWithoutInterestCreditCard(
                            tabloid.withoutInterestCreditCard
                        );
                        setCampaignBase(
                            tabloid.campaignBase
                                ? tabloid.campaignBase.id
                                : undefined
                        );
                        setCampaignBaseName(
                            tabloid.campaignBase
                                ? tabloid.campaignBase.name
                                : undefined
                        );
                        setDistributionCenter(tabloid.distributionCenter);
                        if (tabloid.validity) {
                            setValidity(
                                moment(tabloid.validity)
                                    .add(1, "day")
                                    .format("YYYY-MM-DD")
                            );
                        }
                        setJuridicalText(tabloid.juridicalText);
                        setProjectName(tabloid.projectName);
                        setName(tabloid.name);
                        setPercentSightPriceOne(tabloid.percentSightPriceOne);
                        setPercentSightPriceTwo(tabloid.percentSightPriceTwo);
                        setPercentStateSightPriceOne(
                            tabloid.percentStateSightPriceOne
                        );
                        setPercentStateSightPriceTwo(
                            tabloid.percentStateSightPriceTwo
                        );
                        setAddtionalInformationOne(
                            tabloid.addtionalInformationOne
                        );
                        setAddtionalInformationTwo(
                            tabloid.addtionalInformationTwo
                        );
                        setClientName(
                            tabloid.client ? tabloid.client.name : undefined
                        );
                        setClientPhone(
                            tabloid.client ? tabloid.client.phone : undefined
                        );
                        setClientEmail(
                            tabloid.client ? tabloid.client.email : undefined
                        );
                        setPaymentX(tabloid.paymentX);
                        setHighlight(tabloid.highlight);
                        setCardLayoutSelected(tabloid.cardLayout?.id);
                        setGroupingFourInOne(tabloid.groupingFourInOne);
                        setGroupingSixteenInOne(tabloid.groupingSixteenInOne);
                        setWithoutMyCard(tabloid.withoutMyCard);
                        setQtdCarnePartnersCover(tabloid.qtdCarnePartnersCover);
                        setQtdCreditCardPartnersCover(
                            tabloid.qtdCreditCardPartnersCover
                        );
                        setInternalParcel(
                            tabloid.internalParcel
                                ? tabloid.internalParcel
                                : undefined
                        );

                        let listItemsToStorage = [];

                        for (
                            let indexTabloidItem = 0;
                            indexTabloidItem < tabloidItems.length;
                            indexTabloidItem++
                        ) {
                            const tabloidItem = tabloidItems[indexTabloidItem];

                            if (tabloidItem.ad !== null) {
                                listItemsToStorage.push({
                                    type: "ad",
                                    id: tabloidItem.id,
                                    ad: {
                                        id: tabloidItem.ad.id,
                                        name: tabloidItem.ad.name,
                                    },
                                    changes: false,
                                });
                            } else {
                                listItemsToStorage.push({
                                    type: "product",
                                    id: tabloidItem.id,
                                    product: {
                                        id: tabloidItem.product.id,
                                        code: tabloidItem.product.code,
                                        name: tabloidItem.product.name,
                                    },
                                    withoutInterestCarne:
                                        tabloidItem.withoutInterestCarne,
                                    withoutInterestCreditCard:
                                        tabloidItem.withoutInterestCreditCard,
                                    creditCard: tabloidItem.creditCard,
                                    carne: tabloidItem.carne,
                                    paymentXCreditCard:
                                        tabloidItem.paymentXCreditCard,
                                    paymentXCarne: tabloidItem.paymentXCarne,
                                    sightPriceOne: Number(
                                        tabloidItem.sightPriceOne
                                    ),
                                    sightPriceTwo: Number(
                                        tabloidItem.sightPriceTwo
                                    ),
                                    percentSightPriceOne:
                                        tabloidItem.percentSightPriceOne,
                                    percentStateSightPriceOne:
                                        tabloidItem.percentStateSightPriceOne,
                                    percentSightPriceTwo:
                                        tabloidItem.percentSightPriceTwo,
                                    percentStateSightPriceTwo:
                                        tabloidItem.percentStateSightPriceTwo,
                                    distributionCenter:
                                        tabloidItem.distributionCenter,
                                    addtionalInformationOne:
                                        tabloidItem.addtionalInformationOne,
                                    addtionalInformationTwo:
                                        tabloidItem.addtionalInformationTwo,
                                    campaign:
                                        tabloidItem.campaign !== null
                                            ? {
                                                  id: tabloidItem.campaign.id,
                                                  name:
                                                      tabloidItem.campaign.name,
                                              }
                                            : undefined,
                                    paymentX: tabloidItem.paymentX,
                                    pricePayment: Number(
                                        tabloidItem.pricePayment
                                    ),
                                    totalAmount: Number(
                                        tabloidItem.totalAmount
                                    ),
                                    sightPrice: Number(tabloidItem.sightPrice),
                                    entrance: tabloidItem.entrance,
                                    onlySightPrice: tabloidItem.onlySightPrice,
                                    changes: false,
                                });
                            }
                        }

                        console.log("Eita ", listItemsToStorage);
                        window.localStorage.setItem(
                            "tabloidItems",
                            JSON.stringify(listItemsToStorage)
                        );
                        setBaseItems(listItemsToStorage);

                        setActiveLoader(false);
                    }
                }

                getTabloidAndItems(props.match.params.id);
                setActiveLoader(true);
            }
        },
        [props, props.history]
    );

    function updateOrClone(typeButton, updateOrClone) {
        async function exec(typeButton, updateOrClone) {
            setActiveLoader(true);
            setTextLoader("Gerando/Salvando tabloide...");
            const tabloidItems = JSON.parse(
                window.localStorage.getItem("tabloidItems")
            );

            const responsePostTabloidUpdate = await api.post(
                `/tabloid/${idTabloid}`,
                {
                    typeButton: typeButton,
                    qtdCarne: qtdCarne,
                    qtdCreditCard: qtdCreditCard,
                    useInterests: useInterests,
                    withoutInterestCarne: withoutInterestCarne,
                    withoutInterestCreditCard: withoutInterestCreditCard,
                    campaignBase: campaignBase,
                    distributionCenter: distributionCenter,
                    validity: validity,
                    juridicalText: juridicalText,
                    projectName: projectName,
                    name: name,
                    percentSightPriceOne: percentSightPriceOne,
                    percentStateSightPriceOne: percentStateSightPriceOne,
                    percentSightPriceTwo: percentSightPriceTwo,
                    percentStateSightPriceTwo: percentStateSightPriceTwo,
                    addtionalInformationOne: addtionalInformationOne,
                    addtionalInformationTwo: addtionalInformationTwo,
                    client: {
                        name: clientName,
                        phone: clientPhone,
                        email: clientEmail,
                    },
                    isGazinTabloid: isGazinTabloid,
                    highlight: highlight,
                    cardLayout: cardLayoutSelected,
                    paymentX: paymentX,
                    tabloidItems: tabloidItems,
                    creditCard: creditCard,
                    carne: carne,
                    groupingFourInOne: groupingFourInOne,
                    groupingSixteenInOne: groupingSixteenInOne,
                    withoutMyCard: withoutMyCard,
                    qtdCarnePartnersCover: qtdCarnePartnersCover,
                    qtdCreditCardPartnersCover: qtdCreditCardPartnersCover,
                    internalParcel: internalParcel,
                    updateOrClone: updateOrClone,
                },
                {
                    responseType:
                        typeButton === "save" ? "json" : "arraybuffer",
                }
            );

            if (
                responsePostTabloidUpdate.data.error ||
                responsePostTabloidUpdate.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao criar o tabloide.");
                setAlertText(
                    `Tivemos problemas ao tentar criar o tabloide. Por favor contate o suporte!`
                );
                setAlertVariant("danger");

                setActiveLoader(false);
                setTextLoader("Gerando tabloide...");
            } else {
                console.log(responsePostTabloidUpdate.data);
                if (typeButton === "save") {
                    if (responsePostTabloidUpdate.data.data.message) {
                        setShowAlert(true);
                        setAlertTitle("Tabloide salvo com sucesso!");
                        setAlertText(
                            `Vamos redirecionar você para a listagem de projetos.`
                        );
                        setAlertVariant("success");

                        setTimeout(() => {
                            props.history.push("/tabloid-list-projects");
                        }, 3000);

                        return;
                    }
                }

                setShowAlert(true);
                setAlertTitle("Tabloide criado com sucesso!");
                setAlertText(
                    `O download está disponível em um botão acima das Dicas.`
                );
                setAlertVariant("success");

                setActiveLoader(false);
                setTextLoader("Gerando tabloide...");

                const file = new Blob([responsePostTabloidUpdate.data], {
                    type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                setBlobPDF(fileURL);
            }
        }

        exec(typeButton, updateOrClone);
    }

    function create(typeButton) {
        async function postTabloidCreate() {
            try {
                if (
                    idTabloid !== undefined &&
                    (typeButton === "save" || typeButton === "saveGenerate")
                ) {
                    swal("O que deseja fazer?", {
                        buttons: {
                            // cancel: "Atualizar já existente!",
                            catch: {
                                text: "Salvar novo projeto!",
                                value: "catch",
                            },
                            defeat: {
                                text: "Atualizar já existente!",
                                value: "defeat",
                            },
                        },
                    }).then(async (value) => {
                        switch (value) {
                            case "catch":
                                // clone
                                if (projectName === tabloid.projectName) {
                                    swal(
                                        "Nomes de projetos iguais!",
                                        "Por favor, escolha um novo nome para salvar o projeto.",
                                        "warning"
                                    );
                                } else {
                                    updateOrClone(typeButton, 1);
                                }
                                break;

                            case "defeat":
                                // update
                                updateOrClone(typeButton, 0);
                                break;

                            default:
                                swal("Você cancelou a ação!");
                        }
                    });
                } else {
                    setActiveLoader(true);
                    setTextLoader("Gerando/Salvando tabloide...");

                    const tabloidItems = JSON.parse(
                        window.localStorage.getItem("tabloidItems")
                    );

                    const responsePostTabloidCreate = await api.post(
                        "/tabloid",
                        {
                            typeButton: typeButton,
                            qtdCarne: qtdCarne,
                            qtdCreditCard: qtdCreditCard,
                            useInterests: useInterests,
                            withoutInterestCarne: withoutInterestCarne,
                            withoutInterestCreditCard: withoutInterestCreditCard,
                            campaignBase: campaignBase,
                            distributionCenter: distributionCenter,
                            validity: validity,
                            juridicalText: juridicalText,
                            projectName: projectName,
                            name: name,
                            percentSightPriceOne: percentSightPriceOne,
                            percentStateSightPriceOne: percentStateSightPriceOne,
                            percentSightPriceTwo: percentSightPriceTwo,
                            percentStateSightPriceTwo: percentStateSightPriceTwo,
                            addtionalInformationOne: addtionalInformationOne,
                            addtionalInformationTwo: addtionalInformationTwo,
                            client: {
                                name: clientName,
                                phone: clientPhone,
                                email: clientEmail,
                            },
                            isGazinTabloid: isGazinTabloid,
                            highlight: highlight,
                            cardLayout: cardLayoutSelected,
                            paymentX: paymentX,
                            creditCard: creditCard,
                            carne: carne,
                            groupingFourInOne: groupingFourInOne,
                            groupingSixteenInOne: groupingSixteenInOne,
                            withoutMyCard: withoutMyCard,
                            qtdCarnePartnersCover: qtdCarnePartnersCover,
                            qtdCreditCardPartnersCover: qtdCreditCardPartnersCover,
                            internalParcel: internalParcel,
                            tabloidItems: tabloidItems,
                        },
                        {
                            responseType:
                                typeButton === "save" ? "json" : "arraybuffer",
                        }
                    );

                    if (
                        responsePostTabloidCreate.data.error ||
                        responsePostTabloidCreate.status === 500
                    ) {
                        setShowAlert(true);
                        setAlertTitle("Problemas ao criar o tabloide.");
                        setAlertText(
                            `Tivemos problemas ao tentar criar o tabloide. Por favor contate o suporte!`
                        );
                        setAlertVariant("danger");

                        setActiveLoader(false);
                        setTextLoader("Gerando tabloide...");
                    } else {
                        if (typeButton === "save") {
                            if (responsePostTabloidCreate.data.data.message) {
                                setShowAlert(true);
                                setAlertTitle("Tabloide salvo com sucesso!");
                                setAlertText(
                                    `Vamos redirecionar você para a listagem de projetos.`
                                );
                                setAlertVariant("success");

                                setTimeout(() => {
                                    props.history.push(
                                        "/tabloid-list-projects"
                                    );
                                }, 3000);

                                return;
                            }
                        }
                        setShowAlert(true);
                        setAlertTitle("Tabloide criado com sucesso!");
                        setAlertText(
                            `O download está disponível em um botão acima das Dicas.`
                        );
                        setAlertVariant("success");

                        setActiveLoader(false);
                        setTextLoader("Gerando tabloide...");

                        const file = new Blob(
                            [responsePostTabloidCreate.data],
                            {
                                type: "application/pdf",
                            }
                        );
                        const fileURL = URL.createObjectURL(file);
                        setBlobPDF(fileURL);
                    }
                }
            } catch (err) {
                console.log(err);
                setShowAlert(true);
                setAlertTitle("Problemas ao criar o tabloide.");
                setAlertText(
                    `Tivemos problemas ao tentar criar o tabloide. Por favor contate o suporte!`
                );
                setAlertVariant("danger");

                setActiveLoader(false);
                setTextLoader("Gerando tabloide...");
            }
        }

        postTabloidCreate();
    }

    function forceChanges() {
        setActiveLoader(true);
        setTextLoader("Forçando modificações...");

        const tabloidItems = JSON.parse(
            window.localStorage.getItem("tabloidItems")
        );

        let newTabloidItems = [];
        for (
            let indexTabloidItem = 0;
            indexTabloidItem < tabloidItems.length;
            indexTabloidItem++
        ) {
            const tabloidItem = tabloidItems[indexTabloidItem];

            tabloidItem.carne =
                parseInt(qtdCarne) === 0
                    ? false
                    : qtdCarne !== undefined && qtdCarne !== null
                    ? true
                    : false;
            tabloidItem.creditCard =
                parseInt(qtdCreditCard) === 0
                    ? false
                    : qtdCreditCard !== undefined && qtdCreditCard !== null
                    ? true
                    : false;
            tabloidItem.paymentXCreditCard = isGazinTabloid
                ? qtdCreditCard
                : undefined;
            tabloidItem.paymentXCarne = isGazinTabloid ? qtdCarne : undefined;
            tabloidItem.paymentX = !isGazinTabloid ? paymentX : undefined;
            tabloidItem.withoutInterestCarne = withoutInterestCarne;
            tabloidItem.withoutInterestCreditCard = withoutInterestCreditCard;
            tabloidItem.percentSightPriceOne = percentSightPriceOne;
            tabloidItem.percentStateSightPriceOne = percentStateSightPriceOne;
            tabloidItem.percentSightPriceTwo = percentSightPriceTwo;
            tabloidItem.percentStateSightPriceTwo = percentStateSightPriceTwo;

            newTabloidItems.push(tabloidItem);
        }

        window.localStorage.setItem("isUpdated", true);
        window.localStorage.setItem(
            "tabloidItems",
            JSON.stringify(newTabloidItems)
        );
        setBaseItems(newTabloidItems);

        setActiveLoader(false);
    }

    return (
        <>
            <LoadingOverlay active={activeLoader} spinner text={textLoader}>
                <CustomNavbar />
                <MainContentSpaced>
                    <Container>
                        <Row>
                            <Col>
                                <ShowInfo
                                    title={alertTitle}
                                    text={alertText}
                                    show={showAlert}
                                    variant={alertVariant}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TabloidItemList
                                    baseData={{
                                        qtdCarne: qtdCarne,
                                        qtdCreditCard: qtdCreditCard,
                                        useInterests: useInterests,
                                        withoutInterestCarne: withoutInterestCarne,
                                        withoutInterestCreditCard: withoutInterestCreditCard,
                                        campaignBase: campaignBase,
                                        distributionCenter: distributionCenter,
                                        validity: validity,
                                        juridicalText: juridicalText,
                                        projectName: projectName,
                                        campaigns: campaigns,
                                        products: products,
                                        percentSightPriceOne: percentSightPriceOne,
                                        percentStateSightPriceOne: percentStateSightPriceOne,
                                        percentSightPriceTwo: percentSightPriceTwo,
                                        percentStateSightPriceTwo: percentStateSightPriceTwo,
                                        addtionalInformationOne: addtionalInformationOne,
                                        addtionalInformationTwo: addtionalInformationTwo,
                                        ads: ads,
                                        isGazinTabloid: isGazinTabloid,
                                        paymentX: paymentX,
                                    }}
                                    baseItems={baseItems}
                                    creating={idTabloid === undefined}
                                />
                            </Col>
                            <Col>
                                <Form
                                    onSubmit={(event) => event.preventDefault()}
                                >
                                    {isGazinTabloid ? (
                                        <>
                                            <Form.Group controlId="formQtdCarne">
                                                <Form.Label>
                                                    Qtd. Parcelas Carnê
                                                    (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex: 5"
                                                    value={qtdCarne}
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "")
                                                            setQtdCarne(
                                                                undefined
                                                            );
                                                        else setQtdCarne(value);
                                                    }}
                                                />
                                                <Form.Text className="text-muted">
                                                    Digite a quantidade de
                                                    parcelas corretamente.
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group controlId="formQtdCreditCard">
                                                <Form.Label>
                                                    Qtd. Parcelas Cartão de
                                                    Crédito (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex: 3"
                                                    value={qtdCreditCard}
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "")
                                                            setQtdCreditCard(
                                                                undefined
                                                            );
                                                        else
                                                            setQtdCreditCard(
                                                                event.target
                                                                    .value
                                                            );
                                                    }}
                                                />
                                                <Form.Text className="text-muted">
                                                    Digite a quantidade de
                                                    parcelas corretamente.
                                                </Form.Text>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>
                                            <Form.Group controlId="formQtdCarne">
                                                <Form.Label>
                                                    Qtd. Parcelas Carnê (apenas
                                                    capa)
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex: 5"
                                                    value={
                                                        qtdCarnePartnersCover
                                                    }
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "")
                                                            setQtdCarnePartnersCover(
                                                                undefined
                                                            );
                                                        else
                                                            setQtdCarnePartnersCover(
                                                                value
                                                            );
                                                    }}
                                                />
                                                <Form.Text className="text-muted">
                                                    Digite a quantidade de
                                                    parcelas corretamente.
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group controlId="formQtdCreditCard">
                                                <Form.Label>
                                                    Qtd. Parcelas Cartão de
                                                    Crédito (apenas capa)
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex: 3"
                                                    value={
                                                        qtdCreditCardPartnersCover
                                                    }
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "")
                                                            setQtdCreditCardPartnersCover(
                                                                undefined
                                                            );
                                                        else
                                                            setQtdCreditCardPartnersCover(
                                                                value
                                                            );
                                                    }}
                                                />
                                                <Form.Text className="text-muted">
                                                    Digite a quantidade de
                                                    parcelas corretamente.
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="formPaymentX">
                                                <Form.Label>
                                                    Qtd. Parcelas (interno)
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex: 5"
                                                    value={paymentX}
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "")
                                                            setPaymentX(
                                                                undefined
                                                            );
                                                        else setPaymentX(value);
                                                    }}
                                                />
                                                <Form.Text className="text-muted">
                                                    Digite a quantidade de
                                                    parcelas corretamente.
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group controlId="formCampaignBase">
                                                <Form.Label>
                                                    Parcelamento Interno
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "escolha")
                                                            setInternalParcel(
                                                                undefined
                                                            );
                                                        else
                                                            setInternalParcel(
                                                                value
                                                            );
                                                    }}
                                                >
                                                    <option value="escolha">
                                                        Escolha...
                                                    </option>
                                                    <option
                                                        value="carne"
                                                        selected={
                                                            internalParcel ===
                                                            "carne"
                                                        }
                                                    >
                                                        Carnê
                                                    </option>
                                                    <option
                                                        value="creditCard"
                                                        selected={
                                                            internalParcel ===
                                                            "creditCard"
                                                        }
                                                    >
                                                        Cartão de Crédito
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId="formCampaignBase">
                                                <Form.Label>
                                                    Destaque (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "escolha")
                                                            setHighlight(
                                                                undefined
                                                            );
                                                        else
                                                            setHighlight(
                                                                event.target
                                                                    .value
                                                            );
                                                    }}
                                                >
                                                    <option value="escolha">
                                                        Escolha...
                                                    </option>
                                                    <option
                                                        value="0"
                                                        selected={
                                                            highlight === 0
                                                        }
                                                    >
                                                        Preço À Vista
                                                    </option>
                                                    <option
                                                        value="1"
                                                        selected={
                                                            highlight === 1
                                                        }
                                                    >
                                                        Valor da Parcela
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId="formUseCardCarne">
                                                <Form.Check
                                                    id="formUseCardCarne"
                                                    type="checkbox"
                                                    label="Usar carnê e cartão? (Isso habilitará os 2 campos abaixo)"
                                                    checked={useCardCarne}
                                                    onChange={() =>
                                                        setUseCardCarne(
                                                            !useCardCarne
                                                        )
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formCreditCard">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Habilitar cartão de crédito!"
                                                    disabled={!useCardCarne}
                                                    checked={creditCard}
                                                    onChange={() =>
                                                        setCreditCard(
                                                            !creditCard
                                                        )
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formCarne">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Habilitar carnê!"
                                                    disabled={!useCardCarne}
                                                    checked={carne}
                                                    onChange={() =>
                                                        setCarne(!carne)
                                                    }
                                                />
                                            </Form.Group>
                                        </>
                                    )}

                                    <Form.Group controlId="formGroupingFourInOne">
                                        <Form.Check
                                            type="checkbox"
                                            label="Agrupar 4 em 1"
                                            checked={groupingFourInOne}
                                            onChange={() => {
                                                if (!groupingFourInOne) {
                                                    setGroupingSixteenInOne(
                                                        false
                                                    );
                                                }

                                                setGroupingFourInOne(
                                                    !groupingFourInOne
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formGroupingSixteenInOne">
                                        <Form.Check
                                            type="checkbox"
                                            label="Agrupar 16 em 1"
                                            checked={groupingSixteenInOne}
                                            onChange={() => {
                                                if (!groupingSixteenInOne) {
                                                    setGroupingFourInOne(false);
                                                }

                                                setGroupingSixteenInOne(
                                                    !groupingSixteenInOne
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formWithoutMyCard">
                                        <Form.Check
                                            type="checkbox"
                                            label="Gerar sem meu cartão pessoal"
                                            checked={withoutMyCard}
                                            onChange={() => {
                                                setWithoutMyCard(
                                                    !withoutMyCard
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formUseInterests">
                                        <Form.Check
                                            type="checkbox"
                                            label="Usar sem juros? (Isso habilitará os 2 campos abaixo)"
                                            checked={useInterests}
                                            onChange={() => {
                                                if (useInterests) {
                                                    setWithoutInterestCarne(
                                                        false
                                                    );
                                                    setWithoutInterestCreditCard(
                                                        false
                                                    );
                                                }
                                                setUseInterests(!useInterests);
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formWithoutInterestCarne">
                                        <Form.Check
                                            type="checkbox"
                                            label="Sem juros no carnê!"
                                            disabled={!useInterests}
                                            checked={withoutInterestCarne}
                                            onChange={() =>
                                                setWithoutInterestCarne(
                                                    !withoutInterestCarne
                                                )
                                            }
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formWithoutInterestCreditCard">
                                        <Form.Check
                                            type="checkbox"
                                            label="Sem juros no cartão de crédito!"
                                            disabled={!useInterests}
                                            checked={withoutInterestCreditCard}
                                            onChange={() =>
                                                setWithoutInterestCreditCard(
                                                    !withoutInterestCreditCard
                                                )
                                            }
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formCampaignBase">
                                        <Form.Label>
                                            Campanha Base (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={campaignBaseName}
                                            onChange={(event) => {
                                                const valueSplited = event.target.value.split(
                                                    "-"
                                                );
                                                if (
                                                    valueSplited[0] ===
                                                    "escolha"
                                                )
                                                    setCampaignBase(undefined);
                                                else {
                                                    setCampaignBase(
                                                        valueSplited[0]
                                                    );
                                                    setCampaignBaseName(
                                                        valueSplited[1]
                                                    );
                                                }
                                            }}
                                            list="selectCampaigns"
                                        />
                                        <datalist id="selectCampaigns">
                                            <option value="escolha">
                                                Escolha...
                                            </option>
                                            {campaigns.map(
                                                (campaignItem, index) => (
                                                    <option
                                                        value={`${campaignItem.id}-${campaignItem.name}`}
                                                    >{`${campaignItem.name}`}</option>
                                                )
                                            )}
                                        </datalist>
                                    </Form.Group>

                                    <Form.Group controlId="formValidity">
                                        <Form.Label>Validade</Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="29/11/2020"
                                            value={validity}
                                            onChange={(event) =>
                                                setValidity(event.target.value)
                                            }
                                        />
                                        <Form.Text className="text-muted">
                                            Prencha a validade corretamente.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formCardLayout">
                                        <Form.Label>Layout</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "escolha")
                                                    setCardLayoutSelected(
                                                        undefined
                                                    );
                                                else
                                                    setCardLayoutSelected(
                                                        event.target.value
                                                    );
                                            }}
                                        >
                                            <option value="escolha">
                                                Escolha...
                                            </option>
                                            {cardLayouts.map(
                                                (cardLayoutItem, index) => (
                                                    <option
                                                        value={
                                                            cardLayoutItem.id
                                                        }
                                                        selected={
                                                            cardLayoutItem.id ===
                                                            cardLayoutSelected
                                                        }
                                                    >{`${cardLayoutItem.name}`}</option>
                                                )
                                            )}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="formJuridicalText">
                                        <Form.Label>
                                            Texto Jurídico (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Texto Jurídico"
                                            value={juridicalText}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "")
                                                    setJuridicalText(undefined);
                                                else
                                                    setJuridicalText(
                                                        event.target.value
                                                    );
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite o texto jurídico
                                            corretamente.
                                        </Form.Text>
                                    </Form.Group>

                                    {isGazinTabloid ? (
                                        <>
                                            <Form.Group controlId="formPercentSightPriceOne">
                                                <Form.Label>
                                                    Percentual Preço À Vista 1
                                                    (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;

                                                        if (value === "") {
                                                            setPercentSightPriceOne(
                                                                undefined
                                                            );
                                                        } else {
                                                            setPercentSightPriceOne(
                                                                event.target
                                                                    .value
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <option value="">
                                                        Escolha...
                                                    </option>
                                                    <option
                                                        value="5"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "5"
                                                        }
                                                    >
                                                        5%
                                                    </option>
                                                    <option
                                                        value="7"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "7"
                                                        }
                                                    >
                                                        7%
                                                    </option>
                                                    <option
                                                        value="9"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "9"
                                                        }
                                                    >
                                                        9%
                                                    </option>
                                                    <option
                                                        value="12"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "12"
                                                        }
                                                    >
                                                        12%
                                                    </option>
                                                    <option
                                                        value="13"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "13"
                                                        }
                                                    >
                                                        13%
                                                    </option>
                                                    <option
                                                        value="EXT"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "EXT"
                                                        }
                                                    >
                                                        EXT
                                                    </option>
                                                    <option
                                                        value="INT"
                                                        selected={
                                                            percentSightPriceOne ===
                                                            "INT"
                                                        }
                                                    >
                                                        INT
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId="formPercentStateSightPriceOne">
                                                <Form.Label>
                                                    Estado Percentual Preço À
                                                    Vista 1 (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "0") {
                                                            setPercentStateSightPriceOne(
                                                                undefined
                                                            );
                                                        } else {
                                                            setPercentStateSightPriceOne(
                                                                value
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <option value="0">
                                                        Escolha...
                                                    </option>
                                                    <option
                                                        value="AC"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "AC"
                                                        }
                                                    >
                                                        Acre
                                                    </option>
                                                    <option
                                                        value="AL"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "AL"
                                                        }
                                                    >
                                                        Alagoas
                                                    </option>
                                                    <option
                                                        value="AP"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "AP"
                                                        }
                                                    >
                                                        Amapá
                                                    </option>
                                                    <option
                                                        value="AM"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "AM"
                                                        }
                                                    >
                                                        Amazonas
                                                    </option>
                                                    <option
                                                        value="BA"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "BA"
                                                        }
                                                    >
                                                        Bahia
                                                    </option>
                                                    <option
                                                        value="CE"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "CE"
                                                        }
                                                    >
                                                        Ceará
                                                    </option>
                                                    <option
                                                        value="DF"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "DF"
                                                        }
                                                    >
                                                        Distrito Federal
                                                    </option>
                                                    <option
                                                        value="ES"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "ES"
                                                        }
                                                    >
                                                        Espírito Santo
                                                    </option>
                                                    <option
                                                        value="GO"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "GO"
                                                        }
                                                    >
                                                        Goiás
                                                    </option>
                                                    <option
                                                        value="MA"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "MA"
                                                        }
                                                    >
                                                        Maranhão
                                                    </option>
                                                    <option
                                                        value="MT"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "MT"
                                                        }
                                                    >
                                                        Mato Grosso
                                                    </option>
                                                    <option
                                                        value="MS"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "MS"
                                                        }
                                                    >
                                                        Mato Grosso do Sul
                                                    </option>
                                                    <option
                                                        value="MG"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "MG"
                                                        }
                                                    >
                                                        Minas Gerais
                                                    </option>
                                                    <option
                                                        value="PA"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "PA"
                                                        }
                                                    >
                                                        Pará
                                                    </option>
                                                    <option
                                                        value="PB"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "PB"
                                                        }
                                                    >
                                                        Paraíba
                                                    </option>
                                                    <option
                                                        value="PR"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "PR"
                                                        }
                                                    >
                                                        Paraná
                                                    </option>
                                                    <option
                                                        value="PE"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "PE"
                                                        }
                                                    >
                                                        Pernambuco
                                                    </option>
                                                    <option
                                                        value="PI"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "PI"
                                                        }
                                                    >
                                                        Piauí
                                                    </option>
                                                    <option
                                                        value="RJ"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "RJ"
                                                        }
                                                    >
                                                        Rio de Janeiro
                                                    </option>
                                                    <option
                                                        value="RN"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "RN"
                                                        }
                                                    >
                                                        Rio Grande do Norte
                                                    </option>
                                                    <option
                                                        value="RS"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "RS"
                                                        }
                                                    >
                                                        Rio Grande do Sul
                                                    </option>
                                                    <option
                                                        value="RO"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "RO"
                                                        }
                                                    >
                                                        Rondônia
                                                    </option>
                                                    <option
                                                        value="RR"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "RR"
                                                        }
                                                    >
                                                        Roraima
                                                    </option>
                                                    <option
                                                        value="SC"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "SC"
                                                        }
                                                    >
                                                        Santa Catarina
                                                    </option>
                                                    <option
                                                        value="SP"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "SP"
                                                        }
                                                    >
                                                        São Paulo
                                                    </option>
                                                    <option
                                                        value="SE"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "SE"
                                                        }
                                                    >
                                                        Sergipe
                                                    </option>
                                                    <option
                                                        value="TO"
                                                        selected={
                                                            percentStateSightPriceOne ===
                                                            "TO"
                                                        }
                                                    >
                                                        Tocantins
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId="formPercentSightPriceTwo">
                                                <Form.Label>
                                                    Percentual Preço À Vista 2
                                                    (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;

                                                        if (value === "") {
                                                            setPercentSightPriceTwo(
                                                                null
                                                            );
                                                        } else {
                                                            setPercentSightPriceTwo(
                                                                event.target
                                                                    .value
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <option value="">
                                                        Escolha...
                                                    </option>
                                                    <option
                                                        value="5"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "5"
                                                        }
                                                    >
                                                        5%
                                                    </option>
                                                    <option
                                                        value="7"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "7"
                                                        }
                                                    >
                                                        7%
                                                    </option>
                                                    <option
                                                        value="9"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "9"
                                                        }
                                                    >
                                                        9%
                                                    </option>
                                                    <option
                                                        value="12"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "12"
                                                        }
                                                    >
                                                        12%
                                                    </option>
                                                    <option
                                                        value="13"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "13"
                                                        }
                                                    >
                                                        13%
                                                    </option>
                                                    <option
                                                        value="EXT"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "EXT"
                                                        }
                                                    >
                                                        EXT
                                                    </option>
                                                    <option
                                                        value="INT"
                                                        selected={
                                                            percentSightPriceTwo ===
                                                            "INT"
                                                        }
                                                    >
                                                        INT
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId="formPercentStateSightPriceTwo">
                                                <Form.Label>
                                                    Estado Percentual Preço À
                                                    Vista 2 (opcional)
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(event) => {
                                                        const {
                                                            value,
                                                        } = event.target;
                                                        if (value === "0") {
                                                            setPercentStateSightPriceTwo(
                                                                undefined
                                                            );
                                                        } else {
                                                            setPercentStateSightPriceTwo(
                                                                value
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <option value="0">
                                                        Escolha...
                                                    </option>
                                                    <option
                                                        value="AC"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "AC"
                                                        }
                                                    >
                                                        Acre
                                                    </option>
                                                    <option
                                                        value="AL"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "AL"
                                                        }
                                                    >
                                                        Alagoas
                                                    </option>
                                                    <option
                                                        value="AP"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "AP"
                                                        }
                                                    >
                                                        Amapá
                                                    </option>
                                                    <option
                                                        value="AM"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "AM"
                                                        }
                                                    >
                                                        Amazonas
                                                    </option>
                                                    <option
                                                        value="BA"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "BA"
                                                        }
                                                    >
                                                        Bahia
                                                    </option>
                                                    <option
                                                        value="CE"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "CE"
                                                        }
                                                    >
                                                        Ceará
                                                    </option>
                                                    <option
                                                        value="DF"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "DF"
                                                        }
                                                    >
                                                        Distrito Federal
                                                    </option>
                                                    <option
                                                        value="ES"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "ES"
                                                        }
                                                    >
                                                        Espírito Santo
                                                    </option>
                                                    <option
                                                        value="GO"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "GO"
                                                        }
                                                    >
                                                        Goiás
                                                    </option>
                                                    <option
                                                        value="MA"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "MA"
                                                        }
                                                    >
                                                        Maranhão
                                                    </option>
                                                    <option
                                                        value="MT"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "MT"
                                                        }
                                                    >
                                                        Mato Grosso
                                                    </option>
                                                    <option
                                                        value="MS"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "MS"
                                                        }
                                                    >
                                                        Mato Grosso do Sul
                                                    </option>
                                                    <option
                                                        value="MG"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "MG"
                                                        }
                                                    >
                                                        Minas Gerais
                                                    </option>
                                                    <option
                                                        value="PA"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "PA"
                                                        }
                                                    >
                                                        Pará
                                                    </option>
                                                    <option
                                                        value="PB"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "PB"
                                                        }
                                                    >
                                                        Paraíba
                                                    </option>
                                                    <option
                                                        value="PR"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "PR"
                                                        }
                                                    >
                                                        Paraná
                                                    </option>
                                                    <option
                                                        value="PE"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "PE"
                                                        }
                                                    >
                                                        Pernambuco
                                                    </option>
                                                    <option
                                                        value="PI"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "PI"
                                                        }
                                                    >
                                                        Piauí
                                                    </option>
                                                    <option
                                                        value="RJ"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "RJ"
                                                        }
                                                    >
                                                        Rio de Janeiro
                                                    </option>
                                                    <option
                                                        value="RN"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "RN"
                                                        }
                                                    >
                                                        Rio Grande do Norte
                                                    </option>
                                                    <option
                                                        value="RS"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "RS"
                                                        }
                                                    >
                                                        Rio Grande do Sul
                                                    </option>
                                                    <option
                                                        value="RO"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "RO"
                                                        }
                                                    >
                                                        Rondônia
                                                    </option>
                                                    <option
                                                        value="RR"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "RR"
                                                        }
                                                    >
                                                        Roraima
                                                    </option>
                                                    <option
                                                        value="SC"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "SC"
                                                        }
                                                    >
                                                        Santa Catarina
                                                    </option>
                                                    <option
                                                        value="SP"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "SP"
                                                        }
                                                    >
                                                        São Paulo
                                                    </option>
                                                    <option
                                                        value="SE"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "SE"
                                                        }
                                                    >
                                                        Sergipe
                                                    </option>
                                                    <option
                                                        value="TO"
                                                        selected={
                                                            percentStateSightPriceTwo ===
                                                            "TO"
                                                        }
                                                    >
                                                        Tocantins
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                    ) : null}

                                    <Form.Group controlId="formAddtionalInformationOne">
                                        <Form.Label>
                                            Informação adicional 1 (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ex: 110V"
                                            maxLength="30"
                                            value={addtionalInformationOne}
                                            onChange={(event) => {
                                                setAddtionalInformationOne(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            O preenchimento é opcional.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formAddtionalInformationTwo">
                                        <Form.Label>
                                            Informação adicional 2 (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ex: Branco"
                                            maxLength="30"
                                            value={addtionalInformationTwo}
                                            onChange={(event) => {
                                                setAddtionalInformationTwo(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            O preenchimento é opcional.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formProjectName">
                                        <Form.Label>
                                            Nome do Projeto (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Tabloide 80 itens ELETRO"
                                            value={projectName}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "")
                                                    setProjectName(undefined);
                                                else setProjectName(value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite o nome do projeto de maneira
                                            fácil para ser encontrado depois.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formProjectName">
                                        <Form.Label>
                                            Nome do Tabloide (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Tabloide 80 itens ELETRO"
                                            value={name}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "")
                                                    setName(undefined);
                                                else setName(value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Campo opcional apenas na opção{" "}
                                            <b>salvar</b>. Nos outros modos, se
                                            faz necessário o preenchimento deste
                                            campo.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formProjectName">
                                        <Form.Label>
                                            Nome Cliente (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ex: ELETROJU"
                                            maxLength="16"
                                            value={clientName}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "")
                                                    setClientName(undefined);
                                                else setClientName(value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Este campo é opcional apenas no modo{" "}
                                            <b>SALVAR</b>. Nos outros modos, se
                                            faz necessário o preenchimento deste
                                            campo.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formProjectName">
                                        <Form.Label>
                                            Telefone Cliente (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ex: (11) 3030-2424"
                                            value={clientPhone}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "")
                                                    setClientPhone(undefined);
                                                else setClientPhone(value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Este campo é opcional apenas no modo{" "}
                                            <b>SALVAR</b>. Nos outros modos, se
                                            faz necessário o preenchimento deste
                                            campo.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formProjectName">
                                        <Form.Label>
                                            E-mail Cliente (opcional)
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ex: moveismoveis@moveis.com"
                                            value={clientEmail}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                if (value === "")
                                                    setClientEmail(undefined);
                                                else setClientEmail(value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Este campo é opcional apenas no modo{" "}
                                            <b>SALVAR</b>. Nos outros modos, se
                                            faz necessário o preenchimento deste
                                            campo.
                                        </Form.Text>
                                    </Form.Group>

                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            forceChanges();
                                        }}
                                    >
                                        Forçar modificações externas nos
                                        produtos!
                                    </Button>

                                    <br />
                                    <br />

                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            create("save");
                                        }}
                                    >
                                        Salvar!
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            create("saveGenerate");
                                        }}
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        Salvar e Gerar!
                                    </Button>
                                    <Button
                                        variant="warning"
                                        onClick={() => {
                                            create("generate");
                                        }}
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        Gerar!
                                    </Button>
                                    {blobPDF !== null ? (
                                        <Row>
                                            <Col>
                                                <a
                                                    href={blobPDF}
                                                    download="TabloideGerado.pdf"
                                                    className="btn btn-success"
                                                    style={{
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    Download
                                                </a>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Form>
                                {showTrips ? (
                                    <Card
                                        style={{
                                            marginTop: 20,
                                        }}
                                    >
                                        <Card.Header>Dicas</Card.Header>
                                        <Card.Body>
                                            <Card.Title>
                                                Use essas dicas durante o
                                                cadastro:
                                            </Card.Title>
                                            <Card.Text>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        - Praticamente todos os
                                                        dados são
                                                        personalizáveis. Então,
                                                        os campos que possuirem
                                                        a descrição{" "}
                                                        <b>opcional</b>, basta
                                                        não preencher para que
                                                        ele não apareça no
                                                        tabloide.
                                                    </li>
                                                    <li>
                                                        - Alguns dados que você
                                                        preencher aqui serão
                                                        usados como padrão para
                                                        preenchimento de dados
                                                        da oferta.
                                                    </li>
                                                    <li>
                                                        - Verifique possíveis
                                                        erros de ortografia.
                                                    </li>
                                                    <li>
                                                        - Na hora de finalizar
                                                        escolha a opção correta:
                                                        <b>salvar</b> (apenas
                                                        salva o projeto para
                                                        próximos usos),
                                                        <b>
                                                            salvar e gerar
                                                        </b>{" "}
                                                        (salva o projeto e gera
                                                        o PDF logo em seguida
                                                        para o cliente
                                                        preenchido) e{" "}
                                                        <b>gerar</b> (esse modo
                                                        apenas gera sem salva o
                                                        tabloide como projeto
                                                        para próximos usos).
                                                    </li>
                                                </ul>
                                            </Card.Text>
                                            <Button
                                                variant="warning"
                                                onClick={() =>
                                                    setShowTrips(false)
                                                }
                                            >
                                                Não quero mais ver as dicas!
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                ) : null}
                            </Col>
                        </Row>
                    </Container>
                </MainContentSpaced>
                <CustomFooter />
            </LoadingOverlay>
        </>
    );
};

export default withRouter(TabloidCreate);
