import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./service/auth";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

// Lists

import BranchList from "./pages/BranchList";
import CPFAuthorizedList from "./pages/CPFAuthorizedList";
import CampaignList from "./pages/CampaignList";
import ProductList from "./pages/ProductsList";
import ProductCreate from "./pages/ProductCreate";
import RegisteredToday from "./pages/RegisteredToday";
import DirectMailList from "./pages/DirectMailList";
import UserList from "./pages/UserList";
import TabloidList from "./pages/TabloidList";
import TabloidListProjects from "./pages/TabloidListProjects";
import AdList from "./pages/AdList";
import FontList from "./pages/FontList";
import CardLayoutList from "./pages/CardLayoutList";
import BigListList from "./pages/BigListList";
import FaqList from "./pages/FaqList";

// Creates

import BranchCreate from "./pages/BranchCreate";
import CampaignCreate from "./pages/CampaignCreate";
import CpfAuthorizedCreate from "./pages/CPFAuthorizedCreate";
import DirectMailCreate from "./pages/DirectMailCreate";
import UserCreate from "./pages/UserCreate";
import TabloidCreate from "./pages/TabloidCreate";
import AdCreate from "./pages/AdCreate";
import FontCreate from "./pages/FontCreate";
import CardLayoutCreate from "./pages/CardLayoutCreate";
import BigListCreate from "./pages/BigListCreate";
import FaqCreate from "./pages/FaqCreate";

// Edits

import ProductEdit from "./pages/ProductEdit";
import CardLayoutEdit from "./pages/CardLayoutEdit";

// Detail

import UserDetail from "./pages/UserDetail";

// Report

import ReportByDate from "./pages/ReportByDate";

// Others

import UserPhoto from "./pages/UserPhoto";
import UserProfile from "./pages/UserProfile";
import BranchProfile from "./pages/BranchProfile";
import Faqs from "./pages/Faqs";
import ChangeBackgroundSeller from "./pages/ChangeBackgroundSeller";
import BranchTabloidList from "./pages/BranchTabloidList";
import BranchLogo from "./pages/BranchLogo";
import BranchTabloidCreate from "./pages/BranchTabloidCreate";
import BranchTabloidEdit from "./pages/BranchTabloidEdit";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        exact
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/", state: { from: props.location } }}
                />
            )
        }
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />

            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/branch-list" component={BranchList} />
            <PrivateRoute path="/branch-tabloid-list" component={BranchTabloidList} />
            <PrivateRoute path="/branch-tabloid-create" component={BranchTabloidCreate} />
            <PrivateRoute path="/branch-tabloid-edit" component={BranchTabloidEdit} />
            <PrivateRoute
                path="/cpf-authorized-list"
                component={CPFAuthorizedList}
            />
            <PrivateRoute path="/campaign-list" component={CampaignList} />
            <PrivateRoute path="/product-list" component={ProductList} />
            <PrivateRoute
                path="/registered-today"
                component={RegisteredToday}
            />
            <PrivateRoute path="/direct-mail-list" component={DirectMailList} />
            <PrivateRoute path="/user-list" component={UserList} />
            <PrivateRoute
                path="/tabloid-list-generateds"
                component={TabloidList}
            />
            <PrivateRoute
                path="/tabloid-list-projects"
                component={TabloidListProjects}
            />
            <PrivateRoute path="/ad-list" component={AdList} />
            <PrivateRoute path="/fonts-list" component={FontList} />
            <PrivateRoute path="/layouts-list" component={CardLayoutList} />
            <PrivateRoute path="/biglist-list" component={BigListList} />
            <PrivateRoute path="/faq-list" component={FaqList} />

            <PrivateRoute path="/branches-create" component={BranchCreate} />
            <PrivateRoute path="/campaign-create" component={CampaignCreate} />
            <PrivateRoute path="/cpf-create" component={CpfAuthorizedCreate} />
            <PrivateRoute path="/product-create" component={ProductCreate} />
            <PrivateRoute
                path="/direct-mail-create"
                component={DirectMailCreate}
            />
            <PrivateRoute path="/user-create" component={UserCreate} />
            <PrivateRoute path="/tabloid-create" component={TabloidCreate} />
            <PrivateRoute path="/ad-create" component={AdCreate} />
            <PrivateRoute path="/fonts-create" component={FontCreate} />
            <PrivateRoute path="/layouts-create" component={CardLayoutCreate} />
            <PrivateRoute path="/biglist-create" component={BigListCreate} />
            <PrivateRoute path="/faq-create" component={FaqCreate} />

            <PrivateRoute path="/tabloid-edit/:id" component={TabloidCreate} />

            <PrivateRoute path="/product/:id/edit" component={ProductEdit} />
            <PrivateRoute path="/layouts/:id/edit" component={CardLayoutEdit} />

            <PrivateRoute path="/user/:id/detail" component={UserDetail} />

            <PrivateRoute path="/user/photo" component={UserPhoto} />
            <PrivateRoute path="/user/profile" component={UserProfile} />
            <PrivateRoute path="/branch/profile" component={BranchProfile} />
            <PrivateRoute path="/branch/logo" component={BranchLogo} />

            <PrivateRoute path="/report-by-date" component={ReportByDate} />

            <PrivateRoute path="/faqs" component={Faqs} />
            <PrivateRoute
                path="/change-background"
                component={ChangeBackgroundSeller}
            />

            <Route path="*" component={() => <h1>Página não encontrada.</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
