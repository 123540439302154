import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Spinner,
    Card,
    CardImg,
    Form,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_USERID } from "../../service/tokens";

const UserPhoto = (props) => {
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );

    const [user, setUser] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    useEffect(
        function () {
            async function loadUser() {
                const userId = Number(
                    window.localStorage.getItem(TOKEN_USERID)
                );

                const responseGetUser = await api.get(`/user/${userId}`);

                if (responseGetUser.data.error) {
                    alert(
                        "Erro ao buscar os dados do usuário. Contate o suporte!"
                    );
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setUser(responseGetUser.data.data.user);
                }
            }

            loadUser();
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function postUserPhoto() {
            const formData = new FormData();
            formData.append("photo", file);

            const responsePostUserPhoto = await api.post(
                `/user/${user.id}/upload-photo`,
                formData
            );

            if (
                responsePostUserPhoto.data.error ||
                responsePostUserPhoto.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar a foto.");
                setAlertText(
                    `Tivemos problemas ao atualizar sua foto. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Foto atualizada com sucesso!");
                setAlertText(
                    `Você será redirecionado automaticamente para a home.`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/dashboard");
                }, 3000);
            }
        }

        postUserPhoto();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Atualizar foto
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFile">
                                    <Form.Label>Imagem</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            const fileUpload =
                                                e.target.files[0];
                                            setFile(fileUpload);
                                            setFilePreview(
                                                URL.createObjectURL(fileUpload)
                                            );
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        A foto precisa ter o tamanho mínimo de
                                        680px Largura e 715px Altura.
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Enviar!
                                </Button>

                                {filePreview ? (
                                    <Card
                                        style={{
                                            marginBottom: 40,
                                        }}
                                    >
                                        <CardImg
                                            top
                                            src={filePreview}
                                            alt="Card image cap"
                                        />
                                        <Card.Body>
                                            <Card.Title>
                                                Preview da Foto.
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                ) : null}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(UserPhoto);
