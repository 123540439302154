import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";

const BranchCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [cities, setCities] = useState([]);
    const [citySelected, setCitySelected] = useState(undefined);
    const [branchName, setBranchName] = useState("");
    const [branchCnpj, setBranchCnpj] = useState("");
    const [branchEmail, setBranchEmail] = useState("");
    const [branchAddress, setBranchAddress] = useState("");
    const [branchRegion, setBranchRegion] = useState("");
    const [branchPhone, setBranchPhone] = useState("");
    const [branchLatitude, setBranchLatitude] = useState("")
    const [branchLongitude, setBranchLongitude] = useState("")
    const [branchMix, setBranchMix] = useState(1);
    const [branchIsCarne, setBranchIsCarne] = useState(false);

    useEffect(
        function () {
            async function getCities() {
                const responseGetCity = await api.get("/city");
                if (responseGetCity.data.error) {
                    alert("Erro ao buscar as cidades. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setCities(responseGetCity.data.data);
                }
            }
            getCities();
        },
        []
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function postBranchCreate() {
            try {
                const responsePostBranchCreate = await api.post("/branch", {
                    branch: {
                        name: branchName,
                        city: citySelected,
                        cnpj: branchCnpj,
                        email: branchEmail,
                        address: branchAddress,
                        region: branchRegion,
                        phone: branchPhone,
                        latitude: branchLatitude,
                        longitude: branchLongitude,
                        mix: branchMix,
                        isCarne: branchIsCarne,
                    },
                });

                if (
                    responsePostBranchCreate.data.error ||
                    responsePostBranchCreate.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas ao criar a Empresa.");
                    setAlertText(
                        `Tivemos problemas ao tentar criar a empresa. Por favor contate o suporte!`
                    );
                    setAlertVariant("danger");
                } else {
                    setShowAlert(true);
                    setAlertTitle("Empresa criada com sucesso!");
                    setAlertText(
                        `Voce sera automaticamente direcionado para a lista`
                    );
                    setAlertVariant("success");

                    setTimeout(() => {
                        props.history.push("/branch-list");
                    }, 5000);
                }
            } catch (err) {
                setShowAlert(true);
                setAlertTitle("Problemas ao criar a Empresa.");
                setAlertText(
                    `Tivemos problemas ao tentar criar a empresa. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            }
        }

        postBranchCreate();
    }

    return (
        <>
            <CustomNavbar />
            <main>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={branchName}
                                        onChange={(e) => {
                                            setBranchName(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formCnpj">
                                    <Form.Label>CNPJ</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="CNPJ"
                                        value={branchCnpj}
                                        onChange={(e) => {
                                            setBranchCnpj(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o CNPJ da Empresa.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formEmail">
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="E-Mail"
                                        value={branchEmail}
                                        onChange={(e) => {
                                            setBranchEmail(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCity">
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={citySelected}
                                        onChange={(e) => {
                                            setCitySelected(e.target.value);
                                        }}
                                    >
                                        <option>Escolha...</option>
                                        {cities.map((cityItem, index) => (
                                            <option
                                                value={cityItem.id}
                                                key={index}
                                            >{`${cityItem.name} - ${cityItem.state.abbreviation}`}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formAddress">
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Endereço"
                                        value={branchAddress}
                                        onChange={(e) => {
                                            setBranchAddress(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formRegion">
                                    <Form.Label>Bairro</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Bairro"
                                        value={branchRegion}
                                        onChange={(e) => {
                                            setBranchRegion(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPhone">
                                    <Form.Label>Telefone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Telefone"
                                        value={branchPhone}
                                        onChange={(e) => {
                                            setBranchPhone(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLatitude">
                                    <Form.Label>Latitude</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Latitude"
                                        value={branchLatitude}
                                        onChange={(e) => {
                                            setBranchLatitude(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLongitude">
                                    <Form.Label>Longitude</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Longitude"
                                        value={branchLongitude}
                                        onChange={(e) => {
                                            setBranchLongitude(e.target.value);
                                        }}
                                    />
                                </Form.Group>        

                                <Form.Group controlId="formMix">
                                    <Form.Check
                                        type="radio"
                                        name="mix"
                                        label="Atacado"
                                        value="1"
                                        onChange={(e) => setBranchMix(1)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        name="mix"
                                        label="Colchões"
                                        value="0"
                                        onChange={(e) => setBranchMix(0)}
                                    />
                                    <Form.Text className="text-muted">
                                        Escolha o MIX de produtos a qual essa
                                        empresa irá usar.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formIsCarne">
                                    <Form.Check
                                        id="formIsCarne"
                                        type="checkbox"
                                        label="Usar texto: Carnê?"
                                        checked={branchIsCarne}
                                        onChange={() =>
                                            setBranchIsCarne(!branchIsCarne)
                                        }
                                    />
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o CNPJ com apenas
                                                    números
                                                </li>
                                                <li>
                                                    - Verifique se o e-mail foi
                                                    digitado corretamente.
                                                </li>
                                                <li>
                                                    - Preencha o endereço
                                                    completo com número.
                                                </li>
                                                <li>
                                                    - Digite o telefone da forma
                                                    como quer que apareça no
                                                    cartão gerado. Ex: (55)
                                                    5555-6666
                                                </li>
                                                <li>
                                                    - O campo latitude e longitude
                                                    deve ser escrito de acordo com o padrão
                                                    latitude: -00.000000
                                                    longitude: -00.000000
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(BranchCreate);
