import React, { Component, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import api from "../../service/api";
import { login, isAuthenticated } from "../../service/auth";
import {
    TOKEN_BRANCHID,
    TOKEN_USERTYPE,
    TOKEN_NAMEUSER,
    TOKEN_USERID,
    TOKEN_BRANCHNAME,
} from "../../service/tokens";

import { Button, Form, Container, Row, Col } from "react-bootstrap";

// mano procura usar as function components assim:
const Login = (props) => {
    const [cpf, setCpf] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);

    useEffect(
        function () {
            if (isAuthenticated()) {
                props.history.push("/dashboard");
                return;
            }
        },
        [props.history]
    );

    async function handleLogin(e) {
        e.preventDefault();

        if (!cpf || !password) {
            setError("Preencha CPF e senha para continuar!");
        } else {
            try {
                const response = await api.post("/login", { cpf, password });

                if (response.data.user_data.user_type === 2) {
                    setError("Seu login é para acesso apenas no APP.");
                    return;
                }

                login(response.data.token);
                window.localStorage.setItem(
                    TOKEN_BRANCHID,
                    response.data.branch_data.id
                );
                window.localStorage.setItem(
                    TOKEN_USERTYPE,
                    response.data.user_data.user_type
                );
                window.localStorage.setItem(
                    TOKEN_NAMEUSER,
                    response.data.user_data.name
                );
                window.localStorage.setItem(
                    TOKEN_USERID,
                    response.data.user_data.id
                );
                window.localStorage.setItem(
                    TOKEN_BRANCHNAME,
                    response.data.branch_data.name
                );
                props.history.push("/dashboard");
            } catch (err) {
                setError(
                    "Houve um problema com o login, verifique suas credenciais"
                );
            }
        }
    }

    return (
        <Container>
            <h1 className="text-center">Cartão Express Partners</h1>
            <Row>
                <Col></Col>
                <Col
                    style={{
                        marginTop: 50,
                    }}
                >
                    <Form onSubmit={handleLogin}>
                        {error && <p>{error}</p>}
                        <Form.Group controlId="formCPF">
                            <Form.Label>CPF</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Digite seu CPF"
                                onChange={(e) => setCpf(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Digite seu CPF sem pontuação.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Digite sua senha"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            type="submit"
                            size="lg"
                            variant="outline-primary"
                        >
                            Entrar
                        </Button>
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default withRouter(Login);
