import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const FontCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [fontName, setFontName] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(
        function () {
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append("font_file", file);
        formData.append("name", fontName);

        async function postFontCreate() {
            const responsePostFontCreate = await api.post("/font", formData);

            if (
                responsePostFontCreate.data.error ||
                responsePostFontCreate.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao criar a fonte.");
                setAlertText(
                    `Tivemos problemas ao tentar criar a fonte. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Fonte criada com sucesso!");
                setAlertText(
                    `Voce sera automaticamente direcionado para a lista`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/fonts-list");
                }, 5000);
            }
        }

        postFontCreate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFont">
                                    <Form.Label>Nome Fonte</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome Fonte"
                                        value={fontName}
                                        onChange={(e) => {
                                            setFontName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome desejado para a Fonte.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formFile">
                                    <Form.Label>Fonte</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".ttf,.otf"
                                        onChange={(e) => {
                                            setFile(e.target.files[0]);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Escolha o arquivo da fonte correta.
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o nome desejado
                                                    para a Fonte
                                                </li>
                                                <li>
                                                    - Escolha o arquivo correto da fonte, formato .ttf ou .otf
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(FontCreate);
