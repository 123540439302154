import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    Form,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Dropdown,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const FontList = (props) => {
    const [fonts, setFonts] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [fontView, setFontView] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    useEffect(
        function () {
            async function loadFonts() {
                const responseGetFonts = await api.get(
                    `/font`
                );
                if (responseGetFonts.data.error) {
                    alert("Erro ao buscar as fontes. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetFonts.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else setFonts(responseGetFonts.data.data);
                }
            }

            loadFonts();
        },
        [props.history]
    );

    return (
        <>
            <CustomNavbar />
            <main>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações da Fonte</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Nome: {fontView.name}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Fontes
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome",
                                        sortable: true,
                                        selector: "name",
                                    },
                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                    >
                                                        Opções
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="primary"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setFontView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalInfo();
                                                                }}
                                                            >
                                                                Visualizar
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                variant="danger"
                                                                onClick={() => {
                                                                    var trash = window.confirm(
                                                                        "Para Excluir pressione OK!"
                                                                    );
                                                                    if (
                                                                        trash ===
                                                                        true
                                                                    ) {
                                                                        async function deleteFont(
                                                                            id
                                                                        ) {
                                                                            try {
                                                                                const responseDeleteFont = await api.delete(
                                                                                    `/font/${id}`
                                                                                );
                                                                                if (
                                                                                    responseDeleteFont.status ===
                                                                                    204
                                                                                ) {
                                                                                    setShowAlert(
                                                                                        true
                                                                                    );
                                                                                    setAlertTitle(
                                                                                        "Fonte excluída com sucesso!"
                                                                                    );
                                                                                    setAlertText(
                                                                                        `A fonte ${cellInfo.name} foi excluída com sucesso!`
                                                                                    );
                                                                                    setAlertVariant(
                                                                                        "success"
                                                                                    );

                                                                                    const updatedList = fonts.filter(
                                                                                        (
                                                                                            itemFont,
                                                                                            index
                                                                                        ) =>
                                                                                            itemFont.id !==
                                                                                            cellInfo.id
                                                                                    );
                                                                                    setFonts(
                                                                                        updatedList
                                                                                    );
                                                                                }
                                                                            } catch (err) {
                                                                                setShowAlert(
                                                                                    true
                                                                                );
                                                                                setAlertTitle(
                                                                                    "Não foi possível excluir a fonte!"
                                                                                );
                                                                                setAlertText(
                                                                                    `A fonte ${cellInfo.name} não pode ser excluída. Contate o suporte!`
                                                                                );
                                                                                setAlertVariant(
                                                                                    "danger"
                                                                                );
                                                                            }
                                                                        }
                                                                        deleteFont(
                                                                            cellInfo.id
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Excluir
                                                            </Button>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            );
                                        },
                                    },
                                ]}
                                data={fonts}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(FontList);
