import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Form,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";

import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const UserList = (props) => {
    const [users, setUsers] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [showModalChangeBranch, setShowModalChangeBranch] = useState(false);
    const [showModalChangePassword, setShowModalChangePassword] = useState(
        false
    );
    const [userView, setUserView] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [branches, setBranches] = useState([]);
    const [branchSelected, setBranchSelected] = useState(null);
    const [newUserPassword, setNewUserPassword] = useState("");

    const [userType, setUserType] = useState(
        Number(window.localStorage.getItem(TOKEN_USERTYPE))
    );

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    const handleCloseModalChangeBranch = () => setShowModalChangeBranch(false);
    const handleShowModalChangeBranch = () => setShowModalChangeBranch(true);

    const handleCloseModalChangePassword = () =>
        setShowModalChangePassword(false);
    const handleShowModalChangePassword = () =>
        setShowModalChangePassword(true);

    useEffect(
        function () {
            async function loadUsers() {
                const branchId = Number(
                    window.localStorage.getItem(TOKEN_BRANCHID)
                );

                const responseGetUsers = await api.get(
                    userType !== 0 ? `/user-by-branch/${branchId}` : "user"
                );
                if (responseGetUsers.data.error) {
                    alert("Erro ao buscar os usuários. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetUsers.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else {
                        const getUsersBeautiful = async () => {
                            return Promise.all(
                                responseGetUsers.data.data.map(
                                    (userItem, index) => ({
                                        ...userItem,
                                        branch_email: userItem.branch.email,
                                    })
                                )
                            );
                        };
                        getUsersBeautiful().then((usersBeautiful) => {
                            setUsers(usersBeautiful);
                        });
                    }
                }
            }

            loadUsers();

            async function getBranches() {
                const responseGetBranches = await api.get("/branch");
                if (responseGetBranches.data.error) {
                    alert("Erro ao buscar os CPFs. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setBranches(responseGetBranches.data.data);
                }
            }
            getBranches();
        },
        [props.history, userType]
    );

    function returnYesOrNot(valueBool) {
        if (valueBool) return "Sim";
        else return "Não";
    }

    function returnTypeOfUser(mix) {
        if (mix === 0) return "ADMIN";
        if (mix === 1) return "EMPRESA";
        if (mix === 2) return "VENDEDOR";
    }

    function handleSubmitChangeBranch(e) {
        e.preventDefault();

        async function patchUserChangeBranch() {
            const responsePatchUserChangeBranch = await api.patch(
                `/user-update-branch/${userView.id}`,
                {
                    cpf: userView.cpf,
                    user: {
                        branch: {
                            id: branchSelected,
                        },
                    },
                }
            );

            if (
                responsePatchUserChangeBranch.data.error ||
                responsePatchUserChangeBranch.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao trocar a empresa.");
                setAlertText(
                    `Tivemos problemas ao tentar trocar a empresa para o usuário selecionado. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Empresa trocada com sucesso!");
                setAlertText(``);
                setAlertVariant("success");

                const updatedList = users.filter((itemUser, index) =>
                    itemUser.id === userView.id
                        ? responsePatchUserChangeBranch.data.data.user
                        : itemUser
                );
                setUsers(updatedList);
            }
        }

        patchUserChangeBranch();
    }

    function handleSubmitChangePassword(e) {
        e.preventDefault();

        async function patchUserChangePassword() {
            const responsePatchUserChangePassword = await api.patch(
                `/user-update-password/${userView.id}`,
                {
                    user: {
                        password: newUserPassword,
                    },
                }
            );

            if (
                responsePatchUserChangePassword.data.error ||
                responsePatchUserChangePassword.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao trocar a senha.");
                setAlertText(
                    `Tivemos problemas ao tentar trocar a senha para o usuário selecionado. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Senha trocada com sucesso!");
                setAlertText(``);
                setAlertVariant("success");
            }
        }

        patchUserChangePassword();
    }

    function optionsColumnMenu() {
        if (userType !== 0) return {};
        return {
            name: "Opções",
            cell: function (cellInfo) {
                return (
                    <ButtonGroup aria-label="Basic example">
                        <Button
                            variant="primary"
                            onClick={() => {
                                setUserView(cellInfo);
                                handleShowModalInfo();
                            }}
                        >
                            Visualizar
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => {
                                props.history.push(
                                    `/user/${cellInfo.id}/detail`
                                );
                            }}
                        >
                            Detalhamento
                        </Button>
                        <Button
                            variant="warning"
                            onClick={() => {
                                setUserView(cellInfo);
                                handleShowModalChangeBranch();
                            }}
                        >
                            Trocar Empresa
                        </Button>
                        <Button
                            variant="warning"
                            onClick={() => {
                                setUserView(cellInfo);
                                handleShowModalChangePassword();
                            }}
                        >
                            Nova Senha
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                var trash = window.confirm(
                                    "Para Excluir pressione OK!"
                                );
                                if (trash === true) {
                                    async function deleteUser(id) {
                                        try {
                                            const responseDeleteBranch = await api.delete(
                                                `/user/${id}`
                                            );
                                            if (
                                                responseDeleteBranch.status ===
                                                204
                                            ) {
                                                setShowAlert(true);
                                                setAlertTitle(
                                                    "Usuário excluído com sucesso!"
                                                );
                                                setAlertText(
                                                    `O Usuário ${cellInfo.name} foi excluído com sucesso!`
                                                );
                                                setAlertVariant("success");

                                                const updatedList = users.filter(
                                                    (itemUser, index) =>
                                                        itemUser.id !==
                                                        cellInfo.id
                                                );
                                                setUsers(updatedList);
                                            }
                                        } catch (err) {
                                            setShowAlert(true);
                                            setAlertTitle(
                                                "Não foi possível excluir o Usuário!"
                                            );
                                            setAlertText(
                                                `O Usuário ${cellInfo.name} não pode ser excluído. Contate o suporte!`
                                            );
                                            setAlertVariant("danger");
                                        }
                                    }
                                    deleteUser(cellInfo.id);
                                }
                            }}
                        >
                            Excluir
                        </Button>
                    </ButtonGroup>
                );
            },
        };
    }

    return (
        <>
            <CustomNavbar />
            <main>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações do Usuário</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Ativo?: {returnYesOrNot(userView.active)}</p>
                        <p>Nome: {userView.name}</p>
                        <p>CPF: {userView.cpf}</p>
                        <p>E-mail: {userView.email}</p>
                        <p>
                            Tipo Usuário: {returnTypeOfUser(userView.user_type)}
                        </p>
                        <p>
                            E-mail - CNPJ Empresa:{" "}
                            {`${userView.branch?.email} - ${userView.branch?.cnpj}`}
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showModalChangeBranch}
                    onHide={handleCloseModalChangeBranch}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Trocar empresa do Usuário</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmitChangeBranch}>
                                    <Form.Group controlId="formCNPJ">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={branchSelected}
                                            onChange={(e) => {
                                                setBranchSelected(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option>Escolha...</option>
                                            {branches.map((item, index) => (
                                                <option
                                                    value={item.id}
                                                >{`${item.cnpj} - ${item.email}`}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Button variant="success" type="submit">
                                        Trocar!
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalChangeBranch}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showModalChangePassword}
                    onHide={handleCloseModalChangePassword}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Nova senha do Usuário</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmitChangePassword}>
                                    <Form.Group controlId="formPassword">
                                        <Form.Label>Nova Senha</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nova Senha"
                                            value={newUserPassword}
                                            onChange={(e) => {
                                                setNewUserPassword(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite a senha corretamente.
                                        </Form.Text>
                                    </Form.Group>

                                    <Button variant="success" type="submit">
                                        Trocar!
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalChangePassword}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Usuários
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome",
                                        sortable: true,
                                        selector: "name",
                                    },
                                    {
                                        name: "CPF",
                                        sortable: true,
                                        selector: "cpf",
                                    },
                                    {
                                        name: "Empresa E-mail",
                                        sortable: true,
                                        selector: "branch_email",
                                    },
                                    optionsColumnMenu(),
                                ]}
                                data={users}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(UserList);
