import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Spinner,
    Card,
    CardImg,
    Form,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import InputMask from "react-input-mask";

import api from "../../service/api";
import { TOKEN_USERID, TOKEN_BRANCHID } from "../../service/tokens";

const BranchProfile = (props) => {
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [site, setSite] = useState(null);
    const [facebook, setFacebook] = useState(null);
    const [name, setName] = useState(null);
    const [isCarne, setIsCarne] = useState(null);

    useEffect(
        function () {
            async function loadUser() {
                const branchId = Number(
                    window.localStorage.getItem(TOKEN_BRANCHID)
                );

                const responseGetBranchData = await api.get(
                    `/branch/${branchId}`
                );

                if (responseGetBranchData.data.error) {
                    alert(
                        "Erro ao buscar os dados da empresa. Contate o suporte!"
                    );
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setSite(responseGetBranchData.data.data.branch.site);
                    setFacebook(
                        responseGetBranchData.data.data.branch.facebook
                    );
                    setName(responseGetBranchData.data.data.branch.name);
                    setIsCarne(responseGetBranchData.data.data.branch.isCarne);
                }
            }

            loadUser();
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function postBranchProfile() {
            const branchId = Number(
                window.localStorage.getItem(TOKEN_BRANCHID)
            );

            const responsePutBranchProfile = await api.put(
                `/branch/${branchId}/my-card`,
                {
                    branch: {
                        site: site,
                        facebook: facebook,
                        name: name,
                        isCarne: isCarne,
                    },
                }
            );

            if (
                responsePutBranchProfile.data.error ||
                responsePutBranchProfile.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar seus dados.");
                setAlertText(
                    `Tivemos problemas ao atualizar seus dados. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Dados atualizados com sucesso!");
                setAlertText(
                    `Você será redirecionado automaticamente para a home.`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/dashboard");
                }, 3000);
            }
        }

        postBranchProfile();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Atualizar dados da empresa
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formSite">
                                    <Form.Label>Site</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={site}
                                        onChange={(e) => {
                                            setSite(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o site corretamente corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formFacebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={facebook}
                                        onChange={(e) => {
                                            setFacebook(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o facebook corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formName">
                                    <Form.Label>Nome da Empresa</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formIsCarne">
                                    <Form.Check
                                        id="formIsCarne"
                                        type="checkbox"
                                        label="Usar texto: Carnê?"
                                        checked={isCarne}
                                        onChange={() => setIsCarne(!isCarne)}
                                    />
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Atualizar!
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(BranchProfile);
