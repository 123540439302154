import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    Form,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Dropdown,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";

const BranchList = (props) => {
    const [branches, setBranches] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [branchView, setBranchView] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [uploadType, setUploadType] = useState(0);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [site, setSite] = useState(null);
    const [facebook, setFacebook] = useState(null);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("")

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    const handleCloseModalUpload = () => setShowModalUpload(false);
    const handleShowModalUpload = () => setShowModalUpload(true);

    const handleCloseModalUpdate = () => setShowModalUpdate(false);
    const handleShowModalUpdate = () => setShowModalUpdate(true);

    useEffect(
        function () {
            async function loadBranches() {
                const responseGetBranches = await api.get("/branch");
                if (responseGetBranches.data.error) {
                    alert("Erro ao buscar as empresas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    if (responseGetBranches.data.data.length === 0)
                        setEmptyDataElement(
                            <Alert variant="dark">Não existem registros.</Alert>
                        );
                    else setBranches(responseGetBranches.data.data);
                }
            }

            loadBranches();
        },
        [props.history]
    );

    function returnTypeOfBranch(mix) {
        if (mix === 0) return "Colchões";
        if (mix === 1) return "Atacado/Lojista";
    }

    function returnTypeOfUpload(typeUpload) {
        if (typeUpload === 0) return "Fundo";
        if (typeUpload === 1) return "Fundo Campanha";
        if (typeUpload === 2) return "Fundo C.D";
        if (typeUpload === 3) return "Fundo Tabloide";
        if (typeUpload === 4) return "Capa Tabloide";
        if (typeUpload === 5) return "Fundo Car. Ven.";
        if (typeUpload === 6) return "Logo";
        if (typeUpload === 7) return "Tabloide Conte Comigo";
    }

    function returnYesOrNot(valueBool) {
        if (valueBool) return "Sim";
        else return "Não";
    }

    async function disableCampaign(id, use_campaign) {
        try {
            const responseDisableCampaign = await api.patch(
                `/branch/${id}/enable-disable-campaign`,
                {
                    branch: {
                        use_campaign,
                    },
                }
            );
            if (responseDisableCampaign.status === 200) {
                setShowAlert(true);
                if (use_campaign)
                    setAlertTitle("Campanha habilitada com sucesso!");
                else setAlertTitle("Campanha desabilitada com sucesso!");
                setAlertVariant("success");

                const updatedList = branches.map((itemBranches, index) =>
                    itemBranches.id !== id
                        ? itemBranches
                        : { ...itemBranches, use_campaign: use_campaign }
                );
                setBranches(updatedList);
            }
        } catch (err) {
            setShowAlert(true);
            setAlertTitle("Não foi possível desabilitar a Campanha!");
            setAlertVariant("danger");
        }
    }

    async function disableDirectMail(id, use_direct_mail) {
        try {
            const responseDisableDirectMail = await api.patch(
                `/branch/${id}/enable-disable-direct-mail`,
                {
                    branch: {
                        use_direct_mail,
                    },
                }
            );
            if (responseDisableDirectMail.status === 200) {
                setShowAlert(true);
                if (use_direct_mail)
                    setAlertTitle("Mala Direta habilitada com sucesso!");
                else setAlertTitle("Mala Direta desabilitada com sucesso!");
                setAlertVariant("success");

                const updatedList = branches.map((itemBranches, index) =>
                    itemBranches.id !== id
                        ? itemBranches
                        : { ...itemBranches, use_direct_mail: use_direct_mail }
                );
                setBranches(updatedList);
            }
        } catch (err) {
            setShowAlert(true);
            setAlertTitle("Não foi possível desabilitar a Mala direta!");
            setAlertVariant("danger");
        }
    }

    function handleSubmitUpdate(e) {
        e.preventDefault();

        const updateInfoMyCard = async () => {
            const responseUpdateInfoMyCard = await api.put(
                `/branch/${branchView.id}/my-card`,
                {
                    branch: {
                        site: site,
                        facebook: facebook,
                        latitude: latitude,
                        longitude: longitude
                    },
                }
            );

            if (responseUpdateInfoMyCard.status === 200) {
                setShowAlert(true);
                setAlertTitle("Informações da empresa atualizados.");
                setAlertVariant("success");
            } else {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar informações da empresa.");
                setAlertVariant("danger");
            }
        };

        updateInfoMyCard();
    }

    function handleSubmitChangeBackground(e) {
        e.preventDefault();

        const formData = new FormData();
        let urlSufixo = "";

        switch (uploadType) {
            case 0:
                formData.append("background_branch", file);
                urlSufixo = "background";
                break;

            case 1:
                formData.append("background_branch_campaign", file);
                urlSufixo = "background-campaign";
                break;

            case 2:
                formData.append("background_branch_distribution_center", file);
                urlSufixo = "background-distribution-center";
                break;

            case 3:
                formData.append("background_branch_tabloid", file);
                urlSufixo = "background-tabloid";
                break;

            case 4:
                formData.append("cover_branch_tabloid", file);
                urlSufixo = "cover-tabloid";
                break;

            case 5:
                formData.append("background_seller_card", file);
                urlSufixo = "background-seller-card";
                break;

            case 6:
                formData.append("logo_branch", file);
                urlSufixo = "logo-branch";
                break;

            case 7:
                formData.append("tabloide_digital", file);
                urlSufixo = "register-tabloid";
                break;    
            default:
                formData.append("background_branch", file);
                urlSufixo = "background";
                break;
        }

        async function postUploadBackground() {
            const responseUploadBackgound = await api.post(
                `/branch/${branchView.id}/${urlSufixo}`,
                formData
            );

            if (
                responseUploadBackgound.data.error ||
                responseUploadBackgound.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao fazer o upload da imagem.");
                setAlertText(
                    `Tivemos problemas ao fazer o upload da imagem. Por favor contate o suporte!`
                );
                setAlertVariant("danger");

                handleCloseModalUpload();
                setFile(null);
                setFilePreview(null);
                setBranchView({});
            } else {
                setShowAlert(true);
                setAlertTitle("Upload feito com sucesso!");
                setAlertText(
                    `Voce sera automaticamente direcionado para a lista`
                );
                setAlertVariant("success");

                handleCloseModalUpload();
                setFile(null);
                setFilePreview(null);
                setBranchView({});
            }
        }

        postUploadBackground();
    }

    return (
        <>
            <CustomNavbar />
            <main>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações da Empresa</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>CNPJ: {branchView.cnpj}</p>
                        <p>E-mail: {branchView.email}</p>
                        <p>Endereço: {branchView.address}</p>
                        <p>Bairro: {branchView.region}</p>
                        <p>Telefone: {branchView.phone}</p>
                        <p>Tipo: {returnTypeOfBranch(branchView.mix)}</p>
                        <p>
                            Usa CD?:{" "}
                            {returnYesOrNot(branchView.use_distribution_center)}
                        </p>
                        <p>
                            Usa Campanha?:{" "}
                            {returnYesOrNot(branchView.use_campaign)}
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModalUpdate} onHide={handleCloseModalUpdate}>
                    <Modal.Header closeButton>
                        <Modal.Title>Atualizat Infos da Empresa</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmitUpdate}>
                                    <Form.Group controlId="formSite">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={site}
                                            onChange={(e) => {
                                                setSite(e.target.value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite o site corretamente com
                                            http:// antes do site.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formFacebook">
                                        <Form.Label>Facebook</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={facebook}
                                            onChange={(e) => {
                                                setFacebook(e.target.value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite o facebook corretamente com
                                            http:// antes do site.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formLatitude">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={latitude}
                                            onChange={(e) => {
                                                setLatitude(e.target.value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite a Latitude corretamente com
                                            -00.000000
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formLongitude">
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={longitude}
                                            onChange={(e) => {
                                                setLongitude(e.target.value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite a Longitude corretamente com
                                            -00.000000
                                        </Form.Text>
                                    </Form.Group>

                                    <Button variant="success" type="submit">
                                        Enviar!
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalUpdate}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModalUpload} onHide={handleCloseModalUpload}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Alterar {returnTypeOfUpload(uploadType)} empresa{" "}
                            {branchView.email}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmitChangeBackground}>
                                    <Form.Group controlId="formFile">
                                        <Form.Label>Imagem</Form.Label>
                                        <Form.Control
                                            type="file"
                                            onChange={(e) => {
                                                const fileUpload =
                                                    e.target.files[0];
                                                setFile(fileUpload);
                                                setFilePreview(
                                                    URL.createObjectURL(
                                                        fileUpload
                                                    )
                                                );
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Escolha a imagem correta de fundo.
                                        </Form.Text>
                                    </Form.Group>

                                    <Button variant="success" type="submit">
                                        Enviar!
                                    </Button>

                                    {filePreview ? (
                                        <Card
                                            style={{
                                                marginBottom: 40,
                                            }}
                                        >
                                            <CardImg
                                                top
                                                src={filePreview}
                                                alt="Card image cap"
                                            />
                                            <Card.Body>
                                                <Card.Title>
                                                    Preview da Imagem.
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    ) : null}
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalUpload}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Empresas
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome",
                                        sortable: true,
                                        selector: "name",
                                    },
                                    {
                                        name: "E-mail",
                                        sortable: true,
                                        selector: "email",
                                    },
                                    {
                                        name: "CNPJ",
                                        sortable: false,
                                        selector: "cnpj",
                                    },
                                    {
                                        name: "Opções",
                                        cell: function (cellInfo) {
                                            return (
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                    >
                                                        Opções
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="primary"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalInfo();
                                                                }}
                                                            >
                                                                Visualizar
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        0
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Alt. Fundo
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        6
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Adicionar Logo
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        1
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Alt. Fundo
                                                                Campanha
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        2
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Alt. Fundo C.D.
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        3
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Alt. Fundo Tab.
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        4
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Alt. Capa Tab.
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        5
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Alt. Fun. Car.
                                                                Vend.
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="success"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setUploadType(
                                                                        7
                                                                    );
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    handleShowModalUpload();
                                                                }}
                                                            >
                                                                Tabloide Conte Comigo
                                                            </Button>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                variant="warning"
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                onClick={() => {
                                                                    setBranchView(
                                                                        cellInfo
                                                                    );
                                                                    setSite(
                                                                        cellInfo.site
                                                                    );
                                                                    setFacebook(
                                                                        cellInfo.facebook
                                                                    );
                                                                    handleShowModalUpdate();
                                                                }}
                                                            >
                                                                Atual. Infos
                                                                Empresa
                                                            </Button>
                                                        </Dropdown.Item>

                                                        {cellInfo.use_campaign ? (
                                                            <Dropdown.Item
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="warning"
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                        borderRadius: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        var campaing = window.confirm(
                                                                            "Para DESABILITAR a Campanha pressione OK!"
                                                                        );
                                                                        if (
                                                                            campaing ===
                                                                            true
                                                                        ) {
                                                                            disableCampaign(
                                                                                cellInfo.id,
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Des.
                                                                    Campanha
                                                                </Button>
                                                            </Dropdown.Item>
                                                        ) : (
                                                            <Dropdown.Item
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="success"
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                        borderRadius: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        var campaing = window.confirm(
                                                                            "Para HABILITAR a Campanha Precione OK!"
                                                                        );
                                                                        if (
                                                                            campaing ===
                                                                            true
                                                                        ) {
                                                                            disableCampaign(
                                                                                cellInfo.id,
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Hab.
                                                                    Campanha
                                                                </Button>
                                                            </Dropdown.Item>
                                                        )}

                                                        {cellInfo.use_direct_mail ? (
                                                            <Dropdown.Item
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="warning"
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                        borderRadius: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        var direct_mail = window.confirm(
                                                                            "Para DESABILITAR a Mala Direta pressione OK!"
                                                                        );
                                                                        if (
                                                                            direct_mail ===
                                                                            true
                                                                        ) {
                                                                            disableDirectMail(
                                                                                cellInfo.id,
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Des. Mala
                                                                    Direta
                                                                </Button>
                                                            </Dropdown.Item>
                                                        ) : (
                                                            <Dropdown.Item
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="success"
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                        borderRadius: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        var direct_mail = window.confirm(
                                                                            "Para HABILITAR a Mala Direta Precione OK!"
                                                                        );
                                                                        if (
                                                                            direct_mail ===
                                                                            true
                                                                        ) {
                                                                            disableDirectMail(
                                                                                cellInfo.id,
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Hab. Mala
                                                                    Direta
                                                                </Button>
                                                            </Dropdown.Item>
                                                        )}

                                                        <Dropdown.Item
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    borderRadius: 0,
                                                                }}
                                                                variant="danger"
                                                                onClick={() => {
                                                                    var trash = window.confirm(
                                                                        "Para Excluir pressione OK!"
                                                                    );
                                                                    if (
                                                                        trash ===
                                                                        true
                                                                    ) {
                                                                        async function deleteBranch(
                                                                            id
                                                                        ) {
                                                                            try {
                                                                                const responseDeleteBranch = await api.delete(
                                                                                    `/branch/${id}`
                                                                                );
                                                                                if (
                                                                                    responseDeleteBranch.status ===
                                                                                    204
                                                                                ) {
                                                                                    setShowAlert(
                                                                                        true
                                                                                    );
                                                                                    setAlertTitle(
                                                                                        "Empresa excluída com sucesso!"
                                                                                    );
                                                                                    setAlertText(
                                                                                        `A Empresa ${cellInfo.email} foi excluída com sucesso!`
                                                                                    );
                                                                                    setAlertVariant(
                                                                                        "success"
                                                                                    );

                                                                                    const updatedList = branches.filter(
                                                                                        (
                                                                                            itemBranches,
                                                                                            index
                                                                                        ) =>
                                                                                            itemBranches.id !==
                                                                                            cellInfo.id
                                                                                    );
                                                                                    setBranches(
                                                                                        updatedList
                                                                                    );
                                                                                }
                                                                            } catch (err) {
                                                                                setShowAlert(
                                                                                    true
                                                                                );
                                                                                setAlertTitle(
                                                                                    "Não foi possível excluir a Empresa!"
                                                                                );
                                                                                setAlertText(
                                                                                    `A Empresa ${cellInfo.email} não pode ser excluído. Contate o suporte!`
                                                                                );
                                                                                setAlertVariant(
                                                                                    "danger"
                                                                                );
                                                                            }
                                                                        }
                                                                        deleteBranch(
                                                                            cellInfo.id
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Excluir
                                                            </Button>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                //                                                     <ButtonGroup aria-label="Basic example">
                                                //                                                         <Button
                                                //                                                             variant="primary"
                                                //                                                             onClick={() => {
                                                //                                                                 setBranchView(cellInfo)
                                                //                                                                 handleShowModalInfo()
                                                //                                                             }}
                                                //                                                         >
                                                //                                                             Visualizar
                                                //                                                         </Button>
                                                //                                                         <Button
                                                //                                                             variant="success"
                                                //                                                             onClick={() => {

                                                //                                                             }}
                                                //                                                         >
                                                //                                                             Alt. Fundo
                                                //                                                         </Button>
                                                //                                                         <Button
                                                //                                                             variant="success"
                                                //                                                             onClick={() => {

                                                //                                                             }}
                                                //                                                         >
                                                //                                                             Alt. Fundo Campanha
                                                //                                                         </Button>
                                                //                                                         <Button
                                                //                                                             variant="success"
                                                //                                                             onClick={() => {

                                                //                                                             }}
                                                //                                                         >
                                                //                                                             Alt. Fundo C.D.
                                                //                                                         </Button>

                                                //                                                     </ButtonGroup>
                                            );
                                        },
                                    },
                                ]}
                                data={branches}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            {/* <CustomFooter /> */}
        </>
    );
};

export default withRouter(BranchList);
