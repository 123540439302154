import api from "../api";

export class FaqService {
    async get() {
        const response = await api.get(`/faq`);
        if (response.data.error) {
            return null;
        } else {
            if (response.data.data.length === 0) return [];
            else return response.data.data;
        }
    }

    async create(title, isVideo, answer, videoLink) {
        const responsePost = await api.post("/faq", {
            title,
            isVideo,
            answer,
            videoLink,
        });

        if (responsePost.data.error || responsePost.status === 500) {
            return false;
        } else {
            return true;
        }
    }

    async delete(id) {
        const responseDelete = await api.delete(`/faq/${id}`);
        if (responseDelete.status === 204) {
            return true;
        } else {
            return false;
        }
    }
}
