import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@empd/reactable'
import moment from 'moment'
import '@empd/reactable/lib/styles.css'

import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import MainContentSpaced from '../../components/MainContentSpaced'

import api from "../../service/api";

const Dashboard = (props) => {
    const [sendedToday, setSendedToday] = useState([])
    const [emptyDataElement, setEmptyDataElement] = useState(<Spinner animation="grow" />)
    
    useEffect(function () {
        async function loadDashboard () {
            const responseGetSendedToday = await api.get('/dashboard/sended-today')
            if (responseGetSendedToday.data.error) {
                alert('Erro ao buscar as informacoes para o Dashboard. Contate o suporte!')
            } else {
                if (responseGetSendedToday.data.data.length === 0)
                    setEmptyDataElement(
                        <Alert variant="dark">
                            Não existem registros.
                        </Alert>
                    )
                else {
                    const getSendedTodayBeautiful = async () => {
                        return Promise.all(responseGetSendedToday.data.data.map((sendedTodayItem, index) => ({
                            product: sendedTodayItem.product.name,
                            seller: `${sendedTodayItem.user.name} - ${sendedTodayItem.user.cpf}`,
                            client: sendedTodayItem.client.name,
                            branch: `${sendedTodayItem.user.branch.email} - ${sendedTodayItem.user.branch.cnpj}`,
                            send_to: sendedTodayItem.send_to === 0 ? 'WhatsApp' : sendedTodayItem.send_to === 1 ? 'SMS' : sendedTodayItem.send_to === 2 ? 'E-mail' : '',
                            sended_hour: moment(sendedTodayItem.created_at).locale('pt-br').format('HH:mm')
                        })))
                    }
                    getSendedTodayBeautiful().then((sendedTodayBeautiful) => {
                        setSendedToday(sendedTodayBeautiful)
                    })
                    
                    
                }
                    
            }
        }

        loadDashboard()
    }, [])

    return (
        <>
            <CustomNavbar/>
            <MainContentSpaced>
                <Container>
                    <h3>Enviados Hoje (Total: { sendedToday.length })</h3>
                    <Row>
                        <Col> 
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}                                
                                columns={
                                    [
                                        {
                                            name: 'Produto',
                                            sortable: true,
                                            selector: 'product'
                                        },
                                        { 
                                            name: 'Vendedor',
                                            sortable: true,
                                            selector: 'seller'
                                        },
                                        { 
                                            name: 'Cliente',
                                            sortable: true,
                                            selector: 'client'
                                        },
                                        {
                                            name: 'Empresa',
                                            sortable: true,
                                            selector: 'branch'
                                        },
                                        {
                                            name: 'Forma de Envio',
                                            sortable: true,
                                            selector: 'send_to'
                                        },
                                        {
                                            name: 'Horario de Envio',
                                            sortable: true,
                                            selector: 'sended_hour'
                                        },
                                    ]
                                }
                               data={sendedToday}
                            />
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(Dashboard)