import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";

const ProductEdit = (props) => {
    const [showTrips, setShowTrips] = useState(true);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [lines, setLines] = useState([]);
    const [brands, setBrands] = useState([]);

    const [productID, setProductID] = useState(0);
    const [productActive, setProductActive] = useState(false);
    const [productName, setProductName] = useState("");
    const [productCode, setProductCode] = useState("");
    const [productModel, setProductModel] = useState("");
    const [productNameAdvertisisng, setProductNameAdvertising] = useState("");
    const [productCarac1, setProductCarac1] = useState("");
    const [productCarac2, setProductCarac2] = useState("");
    const [productCarac3, setProductCarac3] = useState("");
    const [productCarac4, setProductCarac4] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productCost, setProductCost] = useState("");
    const [productMix, setProductMix] = useState("");
    const [productHaveVideo, setProductHaveVideo] = useState(false);
    const [productLink, setProductLink] = useState("");

    const [lineSelected, setLineSelected] = useState(0);
    const [brandSelected, setBrandSelected] = useState(0);

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(" ");
    }

    useEffect(
        function () {
            async function getLines() {
                const responseGetLines = await api.get("/product/lines");
                if (responseGetLines.data.error) {
                    alert("Erro ao buscar as Linhas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    const getLinesCapitalize = async () => {
                        return Promise.all(
                            responseGetLines.data.data.map(
                                (lineItem, index) => ({
                                    line: titleCase(lineItem.line),
                                })
                            )
                        );
                    };
                    getLinesCapitalize().then((linesBeautiful) => {
                        setLines(linesBeautiful);
                    });
                }
            }

            async function getBrands() {
                const responseGetBrands = await api.get("/product/brands");
                if (responseGetBrands.data.error) {
                    alert("Erro ao buscar as Marcas. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    const getBrandsCapitalize = async () => {
                        return Promise.all(
                            responseGetBrands.data.data.map(
                                (brandItem, index) => ({
                                    brand: titleCase(brandItem.brand),
                                })
                            )
                        );
                    };
                    getBrandsCapitalize().then((brandsBeautiful) => {
                        setBrands(brandsBeautiful);
                    });
                }
            }

            getLines();
            getBrands();

            const state = props.location.state;

            if (state.product !== undefined) {
                const { product } = state;

                // Remove code from init of string
                let nameSplitedAndRemovedCode = product.name.split(" ");
                nameSplitedAndRemovedCode.shift();
                nameSplitedAndRemovedCode = nameSplitedAndRemovedCode.join(" ");

                console.log(product);

                setProductID(product.id);
                setProductActive(product.active);
                setProductName(nameSplitedAndRemovedCode);
                setProductCode(product.code);
                setProductModel(product.model);
                setProductNameAdvertising(product.name_advertising);
                setProductCarac1(product.carac_1);
                setProductCarac2(product.carac_2);
                setProductCarac3(product.carac_3);
                setProductCarac4(product.carac_4);
                setProductPrice(product.price);
                setProductCost(product.cost);
                setProductMix(product.mix);
                setLineSelected(product.line);
                setBrandSelected(product.brand);
                setProductHaveVideo(product.haveVideo);
                setProductLink(product.link);
            } else {
                setShowAlert(true);
                setAlertTitle("Problemas para atualizar o Produto.");
                setAlertText(
                    `Tivemos problemas para buscar informações do Produto. Por favor contate o suporte!`
                );
                setAlertVariant("danger");

                setTimeout(() => {
                    props.history.push("/product-list");
                }, 5000);
            }
        },
        [props.history, props.location.state]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function putProductUpdate() {
            try {
                const responsePostProductUpdate = await api.put(
                    `/product/${productID}`,
                    {
                        product: {
                            active: productActive,
                            name: productName,
                            code: productCode,
                            model: productModel,
                            name_advertising: productNameAdvertisisng,
                            carac_1: productCarac1,
                            carac_2: productCarac2,
                            carac_3: productCarac3,
                            carac_4: productCarac4,
                            price: productPrice,
                            cost: productCost,
                            line: lineSelected,
                            brand: brandSelected,
                            mix: productMix,
                            haveVideo: productHaveVideo,
                            link: productLink,
                        },
                    }
                );

                if (
                    responsePostProductUpdate.data.error ||
                    responsePostProductUpdate.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas ao atualizar Produto.");
                    setAlertText(
                        `Tivemos problemas ao tentar atualizar o Produto. Por favor contate o suporte!`
                    );
                    setAlertVariant("danger");
                } else {
                    setShowAlert(true);
                    setAlertTitle("Produto atualizado com sucesso!");
                    setAlertText(
                        `Voce sera automaticamente redirecionado para a lista`
                    );
                    setAlertVariant("success");

                    setTimeout(() => {
                        props.history.push("/product-list");
                    }, 5000);
                }
            } catch (err) {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar Produto.");
                setAlertText(
                    `Tivemos problemas ao tentar atualizar o Produto. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            }
        }

        putProductUpdate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={productName}
                                        onChange={(e) => {
                                            setProductName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formCode">
                                    <Form.Label>Código</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Codigo"
                                        value={productCode}
                                        onChange={(e) => {
                                            setProductCode(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formModel">
                                    <Form.Label>Modelo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Modelo"
                                        value={productModel}
                                        onChange={(e) => {
                                            setProductModel(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNameAdvertising">
                                    <Form.Label>Nome de Publicidade</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome de Publicidade"
                                        value={productNameAdvertisisng}
                                        onChange={(e) => {
                                            setProductNameAdvertising(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCarac1">
                                    <Form.Label>Caracteristica 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Caracteristica 1"
                                        value={productCarac1}
                                        onChange={(e) => {
                                            setProductCarac1(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formCarac2">
                                    <Form.Label>Caracteristica 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Caracteristica 2"
                                        value={productCarac2}
                                        onChange={(e) => {
                                            setProductCarac2(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCarac3">
                                    <Form.Label>Caracteristica 3</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Caracteristica 3"
                                        value={productCarac3}
                                        onChange={(e) => {
                                            setProductCarac3(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCarac4">
                                    <Form.Label>Caracteristica 4</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Caracteristica 4"
                                        value={productCarac4}
                                        onChange={(e) => {
                                            setProductCarac4(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPrice">
                                    <Form.Label>Preço</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Preço"
                                        value={productPrice}
                                        onChange={(e) => {
                                            setProductPrice(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCost">
                                    <Form.Label>Custo</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Custo"
                                        value={productCost}
                                        onChange={(e) => {
                                            setProductCost(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLine">
                                    <Form.Label>Linha</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={lineSelected}
                                        onChange={(e) => {
                                            setLineSelected(e.target.value);
                                        }}
                                    >
                                        <option>Escolha...</option>
                                        {lines.map((item, index) => (
                                            <option
                                                key={index}
                                                value={item.line}
                                                selected={
                                                    item.line === lineSelected
                                                }
                                            >
                                                {`${item.line}`}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formBrand">
                                    <Form.Label>Marca</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={brandSelected}
                                        onChange={(e) => {
                                            setBrandSelected(e.target.value);
                                        }}
                                    >
                                        <option>Escolha...</option>
                                        {brands.map((item, index) => (
                                            <option
                                                key={index}
                                                value={item.brand}
                                                selected={
                                                    item.brand === brandSelected
                                                }
                                            >
                                                {`${item.brand}`}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formMix">
                                    <Form.Check
                                        type="radio"
                                        name="mix"
                                        label="Atacado"
                                        value="1"
                                        checked={
                                            productMix === "1" ||
                                            productMix === 1
                                        }
                                        onChange={(e) => setProductMix(1)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        name="mix"
                                        label="Colchões"
                                        value="0"
                                        checked={
                                            productMix === "0" ||
                                            productMix === 0
                                        }
                                        onChange={(e) => setProductMix(0)}
                                    />
                                    <Form.Text className="text-muted">
                                        Escolha o MIX de produtos a qual esse
                                        produto irá pertencer.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formProductHaveVideo">
                                    <Form.Check
                                        type="checkbox"
                                        label="Produto possui vídeo"
                                        checked={productHaveVideo}
                                        onChange={() => {
                                            setProductHaveVideo(
                                                !productHaveVideo
                                            );
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formProductLink">
                                    <Form.Label>Link para o vídeo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="http://youtube.com"
                                        value={productLink}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            setProductLink(value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o link corretamente.
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Atualizar Produto!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite corretamente o
                                                    Codigo de Produto.
                                                </li>
                                                <li>
                                                    - Confira as
                                                    características.
                                                </li>
                                                <li>
                                                    - Escolha o mix correto.
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(ProductEdit);
