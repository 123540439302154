import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Spinner,
    Form,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_BRANCHID } from "../../service/tokens";

const BranchTabloidCreate = (props) => {
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );

    const [branch, setBranch] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [name, setName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tabloid, setTabloid] = useState("");
    // const [filePreview, setFilePreview] = useState(null);
    
    useEffect(
        function () {
            async function loadBranch() {
                const branchId = Number(
                    window.localStorage.getItem(TOKEN_BRANCHID)
                );

                const responseGetBranch = await api.get(`/branch/${branchId}`);

                if (responseGetBranch.data.error) {
                    alert(
                        "Erro ao buscar os dados da Filial. Contate o suporte!"
                    );
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setBranch(responseGetBranch.data.data.branch);
                }
            }

            loadBranch();
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function postBranchTabloid() {
            let urlPost = "/branch-tabloid";
            // const startDateSplit = startDate.split('/')
            // const endDateSplit = endDate.split('/')
            const formData = new FormData();
            formData.append("tabloide_ofertas", tabloid);
            formData.append("name", name)
            formData.append("startDate", startDate)
            formData.append("endDate", endDate)
            formData.append("branch", branch.id)
            
            const responsePostBranchTabloid = await api.post(
                urlPost,
                formData
            );

            if (
                responsePostBranchTabloid.data.error ||
                responsePostBranchTabloid.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao Adicionar Tabloide.");
                setAlertText(
                    `Tivemos problemas ao Adicionar o Tabloide. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Tabloide adicionado com sucesso!");
                setAlertText(
                    `Você será redirecionado automaticamente para a home.`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/branch-tabloid-list");
                }, 3000);
            }
        }

        postBranchTabloid();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Adicionar Tabloide Fale com Vendedor
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-2">
                                    <Form.Group as={Col} controlId="formGridCpf">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="name"
                                            
                                            placeholder="Nome Tabloide" 
                                            onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                            />
                                    </Form.Group>
                                    {/* <Form.Group as={Col} controlId="formGridCnpj">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="branch"
                                            value={branch.id}
                                            placeholder={branch.name}
                                            disabled 
                                            onChange={(e) => {setBranch(e.target.value)}}
                                        />
                                    </Form.Group> */}
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridCnpj">
                                        <Form.Label>Data Inicio</Form.Label>
                                        <Form.Control 
                                            type="date"
                                            name="startDate"
                                            
                                            placeholder="01/01/2022" 
                                            onChange={(e) => {
                                                setStartDate(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridCpf">
                                        <Form.Label>Data Final</Form.Label>
                                        <Form.Control 
                                            type="date"
                                            name="endDate"
                                            
                                            placeholder="01/01/2022" 
                                            onChange={(e) => {
                                                setEndDate(e.target.value)
                                            }}
                                            />
                                    </Form.Group>
                                </Row>
                                <Form.Group controlId="formFile">
                                    <Form.Label>Arquivo PDF</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="tabloid"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                            setTabloid(e.target.files[0]);
                                        }}
                                    />
                                </Form.Group>
                                <Button variant="success" type="submit">
                                    Salvar!
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(BranchTabloidCreate);
