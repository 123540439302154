import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import { FaqService } from "../../service/faq";

const FaqCreate = (props) => {
    const service = new FaqService();

    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [faqTitle, setFaqTitle] = useState("");
    const [faqIsVideo, setFaqIsVideo] = useState(false);
    const [faqAnswer, setFaqAnswer] = useState(null);
    const [faqVideoLink, setFaqVideoLink] = useState(null);

    useEffect(function () {}, [props.history]);

    function handleSubmit(e) {
        e.preventDefault();

        if (faqTitle === "") {
            alert("Título não foi preenchido!");
            return;
        }

        if (faqIsVideo === true && faqVideoLink === null) {
            alert("Link do vídeo não foi preenchido!");
            return;
        }

        if (faqIsVideo === false && faqAnswer === null) {
            alert("Resposta não foi preenchida!");
            return;
        }

        const created = service.create(
            faqTitle,
            faqIsVideo,
            faqAnswer,
            faqVideoLink
        );
        if (created) {
            setShowAlert(true);
            setAlertTitle("O FAQ foi cadastrado com sucesso!");
            setAlertText(`O FAQ foi cadastrado com sucesso!`);
            setAlertVariant("success");

            setTimeout(() => {
                props.history.push("/faq-list");
            }, 5000);
        } else {
            setShowAlert(true);
            setAlertTitle("Problemas para cadastrar o FAQ.");
            setAlertText(
                `Tivemos problemas ao tentar cadastrar o FAQ. Por favor contate o suporte!`
            );
            setAlertVariant("danger");
        }
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFaqTitle">
                                    <Form.Label>Título</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Título FAQ"
                                        value={faqTitle}
                                        onChange={(e) => {
                                            setFaqTitle(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o título desejado para o FAQ.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formFaqIsVideo">
                                    <Form.Check
                                        id="formFaqIsVideo"
                                        type="checkbox"
                                        label="FAQ em vídeo"
                                        checked={faqIsVideo}
                                        onChange={() =>
                                            setFaqIsVideo(!faqIsVideo)
                                        }
                                    />
                                </Form.Group>

                                {faqIsVideo ? (
                                    <Form.Group controlId="formFaqVideoLink">
                                        <Form.Label>Link do Vídeo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Link do Vídeo"
                                            value={faqVideoLink}
                                            onChange={(e) => {
                                                setFaqVideoLink(e.target.value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite o link do vídeo corretamente.
                                        </Form.Text>
                                    </Form.Group>
                                ) : (
                                    <Form.Group controlId="formFaqAnswer">
                                        <Form.Label>Resposta</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Resposta..."
                                            rows="5"
                                            onChange={(e) => {
                                                setFaqAnswer(e.target.value);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Digite a resposta corretamente.
                                        </Form.Text>
                                    </Form.Group>
                                )}

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o titulo
                                                    corretamente
                                                </li>
                                                <li>
                                                    - Verifique se o link do
                                                    vídeo está correto.
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(FaqCreate);
