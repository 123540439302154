import React from 'react'

import { Container } from 'react-bootstrap'

import Footer from './style'

const CustomFooter = () => {
    return (
        <Footer>
            <Container>
                <p>Cartão Express Partners ® Todos os direitos reservados 2020.</p>
            </Container>
        </Footer>
    )
}

export default CustomFooter