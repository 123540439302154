import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";

import { TOKEN_USERTYPE } from "../../service/tokens";

const UserCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [userCPF, setUserCPF] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userType, setUserType] = useState("");
    const [userPassword, setUserPassword] = useState("");

    const [userTypeLogged, setUserTypeLogged] = useState(
        Number(window.localStorage.getItem(TOKEN_USERTYPE))
    );

    useEffect(function () {}, [props.history]);

    function handleSubmit(e) {
        e.preventDefault();

        async function postUserCreate() {
            try {
                const responsePostUserCreate = await api.post("/registration", {
                    cpf: userCPF,
                    name: userName,
                    email: userEmail,
                    phone: userPhone,
                    onesignal_id: "123",
                    user_type: userTypeLogged === 1 ? 3 : userType,
                    password: userPassword,
                });

                if (
                    responsePostUserCreate.data.error ||
                    responsePostUserCreate.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas ao criar o Usuário.");
                    setAlertText(
                        `Tivemos problemas ao tentar criar o usuário. Por favor contate o suporte!`
                    );
                    setAlertVariant("danger");
                } else {
                    setShowAlert(true);
                    setAlertTitle("Usuário criado com sucesso!");
                    setAlertText(
                        `Voce sera automaticamente direcionado para a lista`
                    );
                    setAlertVariant("success");

                    setTimeout(() => {
                        props.history.push("/user-list");
                    }, 5000);
                }
            } catch (err) {
                setShowAlert(true);
                setAlertTitle("Problemas ao criar o Usuário.");
                setAlertText(
                    `Tivemos problemas ao tentar criar o usuário. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            }
        }

        postUserCreate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formCPF">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="CPF"
                                        value={userCPF}
                                        onChange={(e) => {
                                            setUserCPF(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o CPF do Usuário.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome Completo"
                                        value={userName}
                                        onChange={(e) => {
                                            setUserName(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formEmail">
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="E-Mail"
                                        value={userEmail}
                                        onChange={(e) => {
                                            setUserEmail(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPhone">
                                    <Form.Label>Telefone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Telefone"
                                        value={userPhone}
                                        onChange={(e) => {
                                            setUserPhone(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPassword">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Senha"
                                        value={userPassword}
                                        onChange={(e) => {
                                            setUserPassword(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                {userTypeLogged === 0 ? (
                                    <Form.Group controlId="formUserType">
                                        <Form.Check
                                            type="radio"
                                            name="user_type"
                                            label="ADMIN"
                                            value="0"
                                            onChange={(e) => setUserType(0)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="user_type"
                                            label="EMPRESA"
                                            value="1"
                                            onChange={(e) => setUserType(1)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="user_type"
                                            label="VENDEDOR"
                                            value="2"
                                            onChange={(e) => setUserType(2)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="user_type"
                                            label="VENDEDOR WEB"
                                            value="3"
                                            onChange={(e) => setUserType(3)}
                                        />
                                        <Form.Text className="text-muted">
                                            Escolha o tipo correto do usuário.
                                        </Form.Text>
                                    </Form.Group>
                                ) : null}
                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Lembre-se de já ter
                                                    liberado o CPF para a
                                                    empresa correta.
                                                </li>
                                                <li>
                                                    - Digite o CPF com apenas
                                                    números
                                                </li>
                                                <li>
                                                    - Verifique se o e-mail foi
                                                    digitado corretamente.
                                                </li>
                                                <li>
                                                    - Digite o telefone da forma
                                                    como quer que apareça no
                                                    cartão gerado. Ex: (55)
                                                    5555-6666
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(UserCreate);
