import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const CampaignCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [branches, setBranches] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const [branchSelected, setBranchSelected] = useState(null);
    const [file, setFile] = useState(null);
    const [campaignTabloid, setCampaignTabloid] = useState(false);
    const [campaignCard, setCampaignCard] = useState(false);
    const [campaignBigList, setCampaignBigList] = useState(false);
    const [fileCover, setFileCover] = useState(null);
    const [fileBackground, setFileBackground] = useState(null);
    const [fileCoverBigList, setFileCoverBigList] = useState(null);
    const [fileBackgroundBigList, setFileBackgroundBigList] = useState(null);
    const [showBranches, setShowBranches] = useState(false);

    useEffect(
        function () {
            async function getBranches() {
                const responseGetBranches = await api.get("/branch");
                if (responseGetBranches.data.error) {
                    alert("Erro ao buscar os CPFs. Contate o suporte!");
                    setTimeout(function () {
                        props.history.push("/dashboard");
                    }, 2000);
                } else {
                    setBranches(responseGetBranches.data.data);
                }
            }

            const userType = Number(
                window.localStorage.getItem(TOKEN_USERTYPE)
            );
            const branchId = Number(
                window.localStorage.getItem(TOKEN_BRANCHID)
            );
            if (userType === 0) {
                getBranches();
                setShowBranches(true);
            } else {
                setBranchSelected(branchId);
            }
        },
        [props.history]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function postCampaignCreate() {
            const formData = new FormData();
            let urlPost = "";

            if (campaignTabloid) {
                urlPost = "/campaign-tabloid";
                formData.append("image_cover", fileCover);
                formData.append("image_background", fileBackground);
            } else if (campaignCard) {
                urlPost = "/campaign";
                formData.append("image_campaign", file);
            } else {
                urlPost = "/campaign-biglist";
                formData.append("image_cover_biglist", fileCoverBigList);
                formData.append(
                    "image_background_biglist",
                    fileBackgroundBigList
                );
            }

            formData.append("name", campaign);
            formData.append("branch", branchSelected);

            const responsePostCampaignCreate = await api.post(
                urlPost,
                formData
            );

            if (
                responsePostCampaignCreate.data.error ||
                responsePostCampaignCreate.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao criar a Campanha.");
                setAlertText(
                    `Tivemos problemas ao tentar criar a Campanha para a empresa selecionada. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Campanha criada com sucesso!");
                setAlertText(
                    `Voce sera automaticamente direcionado para a lista`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/campaign-list");
                }, 5000);
            }
        }

        postCampaignCreate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formCampaign">
                                    <Form.Label>Campanha</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Campanha"
                                        value={campaign}
                                        onChange={(e) => {
                                            setCampaign(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome desejado para a Campanha.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formCampaignTabloid">
                                    <Form.Check
                                        id="formCampaignTabloid"
                                        type="checkbox"
                                        label="Campanha para o Tabloide?"
                                        checked={campaignTabloid}
                                        onChange={() =>
                                            setCampaignTabloid(!campaignTabloid)
                                        }
                                    />
                                </Form.Group>

                                {campaignTabloid ? (
                                    <>
                                        <Form.Group controlId="formFileCover">
                                            <Form.Label>
                                                Capa para Tabloide
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                disabled={!campaignTabloid}
                                                onChange={(e) => {
                                                    setFileCover(
                                                        e.target.files[0]
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Escolha a imagem correta de
                                                capa.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFileBackground">
                                            <Form.Label>
                                                Fundo para Tabloide
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                disabled={!campaignTabloid}
                                                onChange={(e) => {
                                                    setFileBackground(
                                                        e.target.files[0]
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Escolha a imagem correta de
                                                fundo.
                                            </Form.Text>
                                        </Form.Group>
                                    </>
                                ) : null}

                                <Form.Group controlId="formCampaignNormal">
                                    <Form.Check
                                        id="formCampaignNormal"
                                        type="checkbox"
                                        label="Campanha para o Cartão?"
                                        checked={campaignCard}
                                        onChange={() =>
                                            setCampaignCard(!campaignCard)
                                        }
                                    />
                                </Form.Group>

                                {campaignCard ? (
                                    <Form.Group controlId="formFile">
                                        <Form.Label>Imagem</Form.Label>
                                        <Form.Control
                                            type="file"
                                            disabled={campaignTabloid}
                                            onChange={(e) => {
                                                setFile(e.target.files[0]);
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            Escolha a imagem correta de fundo.
                                        </Form.Text>
                                    </Form.Group>
                                ) : null}

                                <Form.Group controlId="formCampaignBigList">
                                    <Form.Check
                                        id="formCampaignBigList"
                                        type="checkbox"
                                        label="Campanha para o Listão?"
                                        checked={campaignBigList}
                                        onChange={() =>
                                            setCampaignBigList(!campaignBigList)
                                        }
                                    />
                                </Form.Group>

                                {campaignBigList ? (
                                    <>
                                        <Form.Group controlId="formFileCoverBigList">
                                            <Form.Label>
                                                Capa para Listão
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                disabled={!campaignBigList}
                                                onChange={(e) => {
                                                    setFileCoverBigList(
                                                        e.target.files[0]
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Escolha a imagem correta de
                                                capa.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="formFileBackgroundBigList">
                                            <Form.Label>
                                                Fundo para Listão
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                disabled={!campaignBigList}
                                                onChange={(e) => {
                                                    setFileBackgroundBigList(
                                                        e.target.files[0]
                                                    );
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Escolha a imagem correta de
                                                fundo.
                                            </Form.Text>
                                        </Form.Group>
                                    </>
                                ) : null}

                                {showBranches ? (
                                    <Form.Group controlId="formCNPJ">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={branchSelected}
                                            onChange={(e) => {
                                                setBranchSelected(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option>Escolha...</option>
                                            {branches.map((item, index) => (
                                                <option
                                                    value={item.id}
                                                >{`${item.cnpj} - ${item.email}`}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                ) : null}

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Digite o nome desejado
                                                    para a Campanha
                                                </li>
                                                <li>
                                                    - Verifique se o CNPJ da
                                                    Empresa esta correto.
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(CampaignCreate);
