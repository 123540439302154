import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@empd/reactable'
import '@empd/reactable/lib/styles.css'

import { Container, Row, Col, ButtonGroup, Button, Breadcrumb, Spinner, Alert, Modal } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import ShowInfo from '../../components/ShowInfo'
import MainContentSpaced from '../../components/MainContentSpaced'

import api from "../../service/api";

const ProductList = (props) => {
    const [product, setProduct] = useState([])
    const [emptyDataElement, setEmptyDataElement] = useState(<Spinner animation="grow" />)
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [productView, setProductView] = useState({});
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('')
    const [productsCountHaveImage, setProductsCountHaveImage] = useState(0)
    const [productsCountHaveVideo, setProductsCountHaveVideo] = useState(0)

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);
    
    useEffect(function () {
        async function loadProduct () {
            const responseGetProduct = await api.get('/product/dash')
            if (responseGetProduct.data.error) {
                alert('Erro ao buscar os Produtos. Contate o suporte!')
                setTimeout(function () {
                    props.history.push('/dashboard')
                }, 2000)
            } else {
                if (responseGetProduct.data.data.products.length === 0)
                    setEmptyDataElement(
                        <Alert variant="dark">
                            Não existem registros.
                        </Alert>
                    )
                else {
                    setProduct(responseGetProduct.data.data.products)
                    setProductsCountHaveImage(responseGetProduct.data.data.productsCountHaveImage)
                    setProductsCountHaveVideo(responseGetProduct.data.data.productsCountHaveVideo)
                }
            }
        }

        loadProduct()
    }, [props.history])
    
    async function disableImage(id, have_image) {
        try{
            const responseDisableImage = await api.post(`/product/${id}/set-have-image`, {
                product: {
                    have_image
                }
            })
            if (responseDisableImage.status === 200){
                setShowAlert(true)
                if (have_image)
                    setAlertTitle('Imagem habilitada com sucesso!')
                else
                    setAlertTitle('Imagem desabilitada com sucesso!')
                setAlertVariant('success')

                const updatedList = product.map((itemProduct, index) => itemProduct.id !== id ? itemProduct : { ...itemProduct, have_image: have_image } )
                setProduct(updatedList)
            }
        }catch(err) {
            setShowAlert(true)
            setAlertTitle('Não foi possível desabilitar a Imagem!')
            setAlertVariant('danger')  
        }
    }
    

    return (
        <>
            <CustomNavbar/>
            <MainContentSpaced>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações dos Produtos</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>ID: {productView.id}</p>
                        <p>Código: {productView.code}</p>
                        <p>Nome: {productView.name_advertising}</p>
                        <p>Marca: {productView.brand}</p>
                        <p>Preço: {productView.price}</p>
                        <p>Preço Custo: {productView.cost}</p>
                        
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/dashboard')}
                            >
                                Dashboard
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Listar Produtos</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Total de produtos com foto: { productsCountHaveImage }</h4>
                        </Col>
                        <Col>
                            <h4>Total de produtos com vídeo: { productsCountHaveVideo }</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}                                
                                columns={
                                    [
                                        {
                                            name: 'ID',
                                            sortable: true,
                                            selector: 'id'
                                        },
                                        {
                                            name: 'Código',
                                            sortable: true,
                                            selector: 'code'
                                        },
                                        {
                                            name: 'Nome',
                                            sortable: true,
                                            selector: 'name_advertising'
                                        },
                                        {
                                            name: 'Marca',
                                            sortable: true,
                                            selector: 'brand'
                                        },
                                        
                                        
                                        {
                                            name: 'Opções',
                                            cell: function (cellInfo) {
                                                return (
                                                    <ButtonGroup aria-label="Basic example">
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                setProductView(cellInfo)
                                                                handleShowModalInfo()
                                                            }}
                                                        >
                                                            Visualizar
                                                        </Button>
                                                        <Button
                                                            variant="secondary"
                                                            onClick={() => {
                                                                props.history.push(
                                                                    `/product/${cellInfo.id}/edit`,
                                                                    {
                                                                        product: cellInfo
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            Editar
                                                        </Button>
                                                        {
                                                            cellInfo.have_image ?
                                                                <Button 
                                                                    variant="warning"
                                                                    onClick={() => {
                                                                        var img = window.confirm('Para DESABILITAR a Imagem pressione OK!')
                                                                        if(img === true){
                                                                            disableImage(cellInfo.id, false)
                                                                        }
                                                                    }}    
                                                                >
                                                                    Des. Imagem
                                                                </Button>
                                                            :
                                                                <Button
                                                                    variant="success"
                                                                    onClick={() => {
                                                                        var img = window.confirm('Para HABILITAR a Imagem Precione OK!')
                                                                        if(img === true){
                                                                            disableImage(cellInfo.id, true)
                                                                        }
                                                                    }}
                                                                >
                                                                    Hab. Imagem
                                                                </Button>
                                                        }
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                var trash = window.confirm('Para Excluir pressione OK!')
                                                                if(trash === true){
                                                                    async function deleteProduct(id) {
                                                                        try {
                                                                            const responseDeleteProduct = await api.delete(`/product/${id}`)
                                                                            if (responseDeleteProduct.status === 204) {
                                                                                setShowAlert(true)
                                                                                setAlertTitle('Produto excluído com sucesso!')
                                                                                setAlertText(`O Produto ${cellInfo.name_advertising} foi excluído com sucesso!`)
                                                                                setAlertVariant('success')

                                                                                const updatedList = product.filter((itemProduct, index) => itemProduct.id !== cellInfo.id)
                                                                                setProduct(updatedList)
                                                                            }
                                                                        } catch (err) {
                                                                            setShowAlert(true)
                                                                            setAlertTitle('Não foi possível excluir o Produto!')
                                                                            setAlertText(`O Produto ${cellInfo.name_advertising} não pode ser excluído. Contate o suporte!`)
                                                                            setAlertVariant('danger')
                                                                        }
                                                                    }
                                                                    deleteProduct(cellInfo.id)
                                                                }
                                                                
                                                            }}
                                                        >                                                        
                                                            Excluir
                                                        </Button>
                                                    </ButtonGroup>
                                                )
                                            }
                                        }
                                    ]
                                }
                                data={product}
                            />
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(ProductList)