import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Spinner,
    Card,
    CardImg,
    Form,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";
import { TOKEN_BRANCHID } from "../../service/tokens";

const ChangeBackgroundSeller = (props) => {
    const branchId = Number(window.localStorage.getItem(TOKEN_BRANCHID));

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    useEffect(function () {}, [props.history]);

    function handleSubmit(e) {
        e.preventDefault();

        async function postBackgroundSeller() {
            const formData = new FormData();
            formData.append("background_seller_card", file);

            const responsePostBackgroundSeller = await api.post(
                `/branch/${branchId}/background-seller-card`,
                formData
            );

            if (
                responsePostBackgroundSeller.data.error ||
                responsePostBackgroundSeller.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar o fundo.");
                setAlertText(
                    `Tivemos problemas ao atualizar seu fundo. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle("Fundo atualizado com sucesso!");
                setAlertText(
                    `Você será redirecionado automaticamente para a home.`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/dashboard");
                }, 3000);
            }
        }

        postBackgroundSeller();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Atualizar Fundo do Vendedor
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFile">
                                    <Form.Label>Fundo Vendedor</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            const fileUpload =
                                                e.target.files[0];
                                            setFile(fileUpload);
                                            setFilePreview(
                                                URL.createObjectURL(fileUpload)
                                            );
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Selecione a imagem correta.
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Enviar!
                                </Button>

                                {filePreview ? (
                                    <Card
                                        style={{
                                            marginBottom: 40,
                                        }}
                                    >
                                        <CardImg
                                            top
                                            src={filePreview}
                                            alt="Novo Fundo"
                                        />
                                        <Card.Body>
                                            <Card.Title>
                                                Preview da Foto.
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                ) : null}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(ChangeBackgroundSeller);
