import axios from "axios";
import { getToken } from "./auth";

const urlAPI = "https://painelcartao.rx2.com.br/api";
// const urlAPI = "http://192.168.3.7:3050";

const api = axios.create({
    baseURL: urlAPI,
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers["x-access-token"] = `${token}`;
    }
    return config;
});

export default api;

export { urlAPI };
