import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@empd/reactable";
import "@empd/reactable/lib/styles.css";

import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    Form,
    Button,
    Breadcrumb,
    Spinner,
    Alert,
    Modal,
    Dropdown,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";

import { TOKEN_USERTYPE, TOKEN_BRANCHID } from "../../service/tokens";

const TabloidList = (props) => {
    const [tabloids, setTabloids] = useState([]);
    const [emptyDataElement, setEmptyDataElement] = useState(
        <Spinner animation="grow" />
    );
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [tabloidView, setTabloidView] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [showModalGenerate, setShowModalGenerate] = useState(false);

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);

    const handleCloseModalGenerate = () => setShowModalGenerate(false);
    const handleShowModalGenerate = () => setShowModalGenerate(true);

    useEffect(
        function () {
            async function loadTabloids() {
                try {
                    const userType = Number(
                        window.localStorage.getItem(TOKEN_USERTYPE)
                    );
                    const branchId = Number(
                        window.localStorage.getItem(TOKEN_BRANCHID)
                    );

                    const responseGetTabloids = await api.get(
                        `/tabloid${
                            userType !== 0 ? "/" + branchId + "/by-branch" : ""
                        }`
                    );
                    if (responseGetTabloids.data.error) {
                        alert(
                            "Erro ao buscar os tabloides. Contate o suporte!"
                        );
                        setTimeout(function () {
                            props.history.push("/dashboard");
                        }, 2000);
                    } else {
                        if (responseGetTabloids.data.data.length === 0)
                            setEmptyDataElement(
                                <Alert variant="dark">
                                    Não existem registros.
                                </Alert>
                            );
                        else setTabloids(responseGetTabloids.data.data);
                    }
                } catch (errorInGet) {
                    setEmptyDataElement(
                        <Alert variant="danger">
                            Problemas ao buscar dados.
                        </Alert>
                    );
                }
            }

            loadTabloids();
        },
        [props.history]
    );

    function returnYesOrNot(valueBool) {
        if (valueBool) return "Sim";
        else return "Não";
    }

    return (
        <>
            <CustomNavbar />
            <main>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações do Tabloide</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {/* <p>CNPJ: {branchView.cnpj}</p>
                        <p>E-mail: {branchView.email}</p>
                        <p>Endereço: {branchView.address}</p>
                        <p>Bairro: {branchView.region}</p>
                        <p>Telefone: {branchView.phone}</p> */}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showModalGenerate}
                    onHide={handleCloseModalGenerate}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Gerar novamente</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col></Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalGenerate}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Listar Tabloides Gerados
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}
                                columns={[
                                    {
                                        name: "ID",
                                        sortable: true,
                                        selector: "id",
                                    },
                                    {
                                        name: "Nome Tabloide",
                                        sortable: true,
                                        selector: "name",
                                    },
                                    {
                                        name: "Data",
                                        sortable: false,
                                        selector: "createdAt",
                                    },
                                    {
                                        name: "Usuário",
                                        sortable: false,
                                        cell: function (cellInfo) {
                                            return cellInfo.user.name;
                                        },
                                    },
                                    // {
                                    //     name: "Opções",
                                    //     cell: function (cellInfo) {
                                    //         return (
                                    //             <Dropdown>
                                    //                 <Dropdown.Toggle
                                    //                     variant="success"
                                    //                     id="dropdown-basic"
                                    //                 >
                                    //                     Opções
                                    //                 </Dropdown.Toggle>

                                    //                 <Dropdown.Menu
                                    //                     style={{
                                    //                         padding: 0,
                                    //                     }}
                                    //                 >
                                    //                     <Dropdown.Item
                                    //                         style={{
                                    //                             padding: 0,
                                    //                         }}
                                    //                     >
                                    //                         <Button
                                    //                             variant="primary"
                                    //                             style={{
                                    //                                 width:
                                    //                                     "100%",
                                    //                                 borderRadius: 0,
                                    //                             }}
                                    //                             onClick={() => {
                                    //                                 setTabloidView(
                                    //                                     cellInfo
                                    //                                 );
                                    //                                 handleShowModalInfo();
                                    //                             }}
                                    //                         >
                                    //                             Visualizar
                                    //                         </Button>
                                    //                     </Dropdown.Item>

                                    //                     <Dropdown.Item
                                    //                         style={{
                                    //                             padding: 0,
                                    //                         }}
                                    //                     >
                                    //                         <Button
                                    //                             variant="success"
                                    //                             style={{
                                    //                                 width:
                                    //                                     "100%",
                                    //                                 borderRadius: 0,
                                    //                             }}
                                    //                             onClick={() => {
                                    //                                 setTabloidView(
                                    //                                     cellInfo
                                    //                                 );
                                    //                                 handleShowModalGenerate();
                                    //                             }}
                                    //                         >
                                    //                             Regerar
                                    //                         </Button>
                                    //                     </Dropdown.Item>

                                    //                     <Dropdown.Item
                                    //                         style={{
                                    //                             padding: 0,
                                    //                         }}
                                    //                     >
                                    //                         <Button
                                    //                             style={{
                                    //                                 width:
                                    //                                     "100%",
                                    //                                 borderRadius: 0,
                                    //                             }}
                                    //                             variant="danger"
                                    //                             onClick={() => {
                                    //                                 var trash = window.confirm(
                                    //                                     "Para Excluir pressione OK!"
                                    //                                 );
                                    //                                 if (
                                    //                                     trash ===
                                    //                                     true
                                    //                                 ) {
                                    //                                     async function deleteTabloid(
                                    //                                         id
                                    //                                     ) {
                                    //                                         try {
                                    //                                             const responseDeleteTabloid = await api.delete(
                                    //                                                 `/tabloid/${id}`
                                    //                                             );
                                    //                                             if (
                                    //                                                 responseDeleteTabloid.status ===
                                    //                                                 204
                                    //                                             ) {
                                    //                                                 setShowAlert(
                                    //                                                     true
                                    //                                                 );
                                    //                                                 setAlertTitle(
                                    //                                                     "Tabloide excluído com sucesso!"
                                    //                                                 );
                                    //                                                 setAlertText(
                                    //                                                     `O tabloide foi excluída com sucesso!`
                                    //                                                 );
                                    //                                                 setAlertVariant(
                                    //                                                     "success"
                                    //                                                 );

                                    //                                                 const updatedList = tabloids.filter(
                                    //                                                     (
                                    //                                                         itemTabloids,
                                    //                                                         index
                                    //                                                     ) =>
                                    //                                                         itemTabloids.id !==
                                    //                                                         cellInfo.id
                                    //                                                 );
                                    //                                                 setTabloids(
                                    //                                                     updatedList
                                    //                                                 );
                                    //                                             }
                                    //                                         } catch (err) {
                                    //                                             setShowAlert(
                                    //                                                 true
                                    //                                             );
                                    //                                             setAlertTitle(
                                    //                                                 "Não foi possível excluir o Tabloide!"
                                    //                                             );
                                    //                                             setAlertText(
                                    //                                                 `O tabloide não pode ser excluído. Contate o suporte!`
                                    //                                             );
                                    //                                             setAlertVariant(
                                    //                                                 "danger"
                                    //                                             );
                                    //                                         }
                                    //                                     }
                                    //                                     deleteTabloid(
                                    //                                         cellInfo.id
                                    //                                     );
                                    //                                 }
                                    //                             }}
                                    //                         >
                                    //                             Excluir
                                    //                         </Button>
                                    //                     </Dropdown.Item>
                                    //                 </Dropdown.Menu>
                                    //             </Dropdown>
                                    //         );
                                    //     },
                                    // },
                                ]}
                                data={tabloids}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter />
        </>
    );
};

export default withRouter(TabloidList);
