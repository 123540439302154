import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@empd/reactable'
import moment from 'moment'
import '@empd/reactable/lib/styles.css'

import { Container, Row, Col, Spinner, Alert, Button } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import MainContentSpaced from '../../components/MainContentSpaced'

import api from "../../service/api";

const Registered = (props) => {
    const [registeredToday, setRegisteredToday] = useState([])
    const [emptyDataElement, setEmptyDataElement] = useState(<Spinner animation="grow" />)
    
    useEffect(function () {
        async function loadRegistered () {
            const responseGetRegisteredToday = await api.get('/dashboard/registered-today')
            if (responseGetRegisteredToday.data.error) {
                alert('Erro ao buscar as informacoes para o Dashboard. Contate o suporte!')
            } else {
                if (responseGetRegisteredToday.data.data.length === 0)
                    setEmptyDataElement(
                        <Alert variant="dark">
                            Não existem registros.
                        </Alert>
                    )
                else {
                    const getRegisteredTodayBeautiful = async () => {
                        return Promise.all(responseGetRegisteredToday.data.data.map((registeredTodayItem, index) => ({
                            name: `${registeredTodayItem.user.name}`,
                            cpf:   `${registeredTodayItem.user.cpf}`,
                            branch: `${registeredTodayItem.user.branch.email} - ${registeredTodayItem.user.branch.cnpj}`,
                        })))
                    }
                    getRegisteredTodayBeautiful().then((registeredTodayBeautiful) => {
                        setRegisteredToday(registeredTodayBeautiful)
                    })
                    
                    
                }
                    
            }
        }

        loadRegistered()
    }, [])

    return (
        <>
            <CustomNavbar/>
            <MainContentSpaced>
                <Container>
                    <h3>Registrados Hoje (Total: { registeredToday.length })</h3>
                    <Row>
                        <Col> 
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}                                
                                columns={[
                                    { 
                                        name: 'Vendedor',
                                        sortable: true,
                                        selector: 'name'
                                    },
                                    {
                                        name: 'CPF',
                                        sortable: true,
                                        selector: 'cpf'
                                    },
                                    
                                    {
                                        name: 'Empresa',
                                        sortable: true,
                                        selector: 'branch'
                                    }
                                ]}
                               data={registeredToday}
                            />
                        </Col>
                    </Row>
                    <Button variant="success">Exportar .csv</Button>
                </Container>
            </MainContentSpaced>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(Registered)