import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import "@empd/reactable/lib/styles.css";

import ReactPlayer from "react-player";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Card,
    Accordion,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import InputMask from "react-input-mask";

import { FaqService } from "../../service/faq";

const Faqs = (props) => {
    const service = new FaqService();

    const [faqs, setFaqs] = useState([]);

    useEffect(
        function () {
            async function load() {
                const faqsToGet = await service.get();
                setFaqs(faqsToGet);
            }

            load();
        },
        [props.history]
    );

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>FAQs</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Accordion defaultActiveKey="0">
                                {faqs.map((faq, index) => (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="button"
                                                eventKey={index}
                                            >
                                                {faq.title}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body>
                                                <ReactPlayer
                                                    width="100%"
                                                    url={faq.videoLink}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(Faqs);
