import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";

const BranchTabloidEdit = (props) => {
    const [showTrips, setShowTrips] = useState(true);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    
    const [branchTabloidID, setBranchTabloidID] = useState(0)
    const [name, setName] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(" ");
    }

    useEffect(
        function () {


            const state = props.location.state;

            if (state.branchTabloid !== undefined) {
                const { branchTabloid } = state;

                // Remove code from init of string
                let nameSplitedAndRemovedCode = branchTabloid.name.split(" ");
                nameSplitedAndRemovedCode.shift();
                nameSplitedAndRemovedCode = nameSplitedAndRemovedCode.join(" ");

                console.log(branchTabloid);

                setBranchTabloidID(branchTabloid.id)
                setName(branchTabloid.name)
                setStartDate(branchTabloid.startDate)
                setEndDate(branchTabloid.endDate)

            } else {
                setShowAlert(true);
                setAlertTitle("Problemas para atualizar o Tabloide.");
                setAlertText(
                    `Tivemos problemas para buscar informações do Tabloide. Por favor contate o suporte!`
                );
                setAlertVariant("danger");

                setTimeout(() => {
                    props.history.push("/dashboard");
                }, 5000);
            }
        },
        [props.history, props.location.state]
    );

    function handleSubmit(e) {
        e.preventDefault();

        async function putBranchTabloidUpdate() {
            try {
                const responsePostBranchTabloidUpdate = await api.put(
                    `/branch-tabloid/${branchTabloidID}`,
                    {
                        name: name,
                        startDate: startDate,
                        endDate: endDate
                    }
                );

                if (
                    responsePostBranchTabloidUpdate.data.error ||
                    responsePostBranchTabloidUpdate.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas ao atualizar o Tabloide.");
                    setAlertText(
                        `Tivemos problemas ao tentar atualizar o Tabloide. Por favor contate o suporte!`
                    );
                    setAlertVariant("danger");
                } else {
                    setShowAlert(true);
                    setAlertTitle("Tabloide atualizado com sucesso!");
                    setAlertText(
                        `Voce sera automaticamente redirecionado para a lista`
                    );
                    setAlertVariant("success");

                    setTimeout(() => {
                        props.history.push("/branch-tabloid-list");
                    }, 5000);
                }
            } catch (err) {
                setShowAlert(true);
                setAlertTitle("Problemas ao atualizar Tabloide.");
                setAlertText(
                    `Tivemos problemas ao tentar atualizar o Tabloide. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            }
        }

        putBranchTabloidUpdate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formCode">
                                    <Form.Label>Data Inicio</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Data de Inicio"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formModel">
                                    <Form.Label>Data Final</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Data Final"
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Button variant="success" type="submit">
                                    Atualizar Tabloide!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <ul className="list-unstyled">
                                                <li>
                                                    - Edite apenas se for continuar usando o mesmo tabloide
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(BranchTabloidEdit);
