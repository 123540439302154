import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import { Container, Row, Col, Button, Breadcrumb, Spinner, Card, Form, Alert } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import MainContentSpaced from '../../components/MainContentSpaced'
import ShowInfo from '../../components/ShowInfo'

import api from "../../service/api";

const ReportByDate = (props) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('')
    const [fileURLCards, setFileURLCards] = useState('')
    const [fileNameCards, setFileNameCards] = useState('')
    const [fileURLDirectMailSends, setFileURLDirectMailSends] = useState('')
    const [fileNameDirectMailSends, setFileNameDirectMailSends] = useState('')
    
    const dateBeginCard = useRef(null);
    const dateEndCard = useRef(null);
    const dateBeginDirectMailSend = useRef(null);
    const dateEndDirectMailSend = useRef(null);

    useEffect(function () {
        var date = new Date();

        var formatedDate = `${date.getFullYear()}-${(date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1)}-${date.getDate()}`

        dateBeginCard.current.value = formatedDate
        dateEndCard.current.value = formatedDate
        dateBeginDirectMailSend.current.value = formatedDate
        dateEndDirectMailSend.current.value = formatedDate
    }, [props.history])

    function onSubmitReportCards (event) {
        event.preventDefault()

        async function getReportCards () {
            try {
                const responseGetReportCardByDate = await api.get(`/reports/cards-by-date/${dateBeginCard.current.value}/${dateEndCard.current.value}`, {
                    responseType: 'blob'
                })
                
                if (responseGetReportCardByDate.data.error || responseGetReportCardByDate.status === 500) {
                    setShowAlert(true)
                    setAlertTitle('Problemas ao gerar o relatório.')
                    setAlertText(responseGetReportCardByDate.data.data.message)
                    setAlertVariant('danger')
                } else {
                    let fileName = responseGetReportCardByDate.headers['content-disposition'].split(';')[1]
                    fileName = fileName.slice(fileName.indexOf('"'), fileName.length - 1)

                    setFileURLCards(window.URL.createObjectURL(new Blob([responseGetReportCardByDate.data])))
                    setFileNameCards(fileName)

                    setShowAlert(true)
                    setAlertTitle('Relatório de cartões gerado com sucesso!')
                    setAlertText(`Clique no botão abaixo para fazer download!`)
                    setAlertVariant('success')
                }
            } catch (err) {
                console.log(err)
                setShowAlert(true)
                setAlertTitle('Problemas ao gerar o relatório.')
                setAlertText('Por favor contate o suporte!')
                setAlertVariant('danger')
            }
        }

        getReportCards()  
    }

    function onSubmitReportDirectMailSends (event) {
        event.preventDefault()

        async function getReportDirectMailSends () {
            try {
                const responseGetReportDirectMailSendsByDate = await api.get(`/reports/direct-mail-sends-by-date/${dateBeginDirectMailSend.current.value}/${dateEndDirectMailSend.current.value}`, {
                    responseType: 'blob'
                })
                
                if (responseGetReportDirectMailSendsByDate.data.error || responseGetReportDirectMailSendsByDate.status === 500) {
                    setShowAlert(true)
                    setAlertTitle('Problemas ao gerar o relatório.')
                    setAlertText(responseGetReportDirectMailSendsByDate.data.data.message)
                    setAlertVariant('danger')
                } else {
                    let fileName = responseGetReportDirectMailSendsByDate.headers['content-disposition'].split(';')[1]
                    fileName = fileName.slice(fileName.indexOf('"'), fileName.length - 1)

                    setFileURLDirectMailSends(window.URL.createObjectURL(new Blob([responseGetReportDirectMailSendsByDate.data])))
                    setFileNameDirectMailSends(fileName)

                    setShowAlert(true)
                    setAlertTitle('Relatório de malas diretas gerado com sucesso!')
                    setAlertText(`Clique no botão abaixo para fazer download!`)
                    setAlertVariant('success')
                }
            } catch (err) {
                console.log(err)
                setShowAlert(true)
                setAlertTitle('Problemas ao gerar o relatório.')
                setAlertText('Por favor contate o suporte!')
                setAlertVariant('danger')
            }
        }

        getReportDirectMailSends()  
    }

    return (
        <>
            <CustomNavbar/>
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/dashboard')}
                            >
                                Dashboard
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Relatório por Data</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Cartões</Card.Header>
                                <Card.Body>
                                    <Card.Title>Escolha as Datas</Card.Title>
                                    <Form
                                        onSubmit={onSubmitReportCards}
                                    >
                                        <Form.Row>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Data Inicial"
                                                    type="date"
                                                    ref={dateBeginCard}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Data Final"
                                                    type="date"
                                                    ref={dateEndCard}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Row
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            <Col>
                                                <Button variant="primary" type="submit">
                                                    Gerar!
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {
                                        fileURLCards !== '' ?
                                            <Row
                                                style={{
                                                    marginTop: 20
                                                }}
                                            >
                                                <Col>
                                                    <a
                                                        href={fileURLCards}
                                                        download={fileNameCards}
                                                    >
                                                        <Button variant="success">Download .xlsx</Button>
                                                    </a>
                                                </Col>
                                            </Row>
                                        : null
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 20
                        }}
                    >
                        <Col>
                            <Card>
                                <Card.Header>Malas Diretas</Card.Header>
                                <Card.Body>
                                    <Card.Title>Escolha as Datas</Card.Title>
                                    <Form
                                        onSubmit={onSubmitReportDirectMailSends}
                                    >
                                        <Form.Row>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Data Inicial"
                                                    type="date"
                                                    ref={dateBeginDirectMailSend}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Data Final"
                                                    type="date"
                                                    ref={dateEndDirectMailSend}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Row
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            <Col>
                                                <Button variant="primary" type="submit">
                                                    Gerar!
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {
                                        fileURLDirectMailSends !== '' ?
                                            <Row
                                                style={{
                                                    marginTop: 20
                                                }}
                                            >
                                                <Col>
                                                    <a
                                                        href={fileURLDirectMailSends}
                                                        download={fileNameDirectMailSends}
                                                    >
                                                        <Button variant="success">Download .xlsx</Button>
                                                    </a>
                                                </Col>
                                            </Row>
                                        : null
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(ReportByDate)