import React, { useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import Table from '@empd/reactable'
import '@empd/reactable/lib/styles.css'
import moment from 'moment'

import { Container, Row, Col, ButtonGroup, Button, Breadcrumb, Spinner, Alert, Modal } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import MainContentSpaced from '../../components/MainContentSpaced'

import api from "../../service/api";

const UserDetail = (props) => {
    const [user, setUser] = useState({})
    const [userBranch, setUserBranch] = useState({})
    const [userNotifications, setUserNotifications] = useState([])
    const [userCards, setUserCards] = useState([])
    const [userDirectMailSends, setUserDirectMailSends] = useState([])
    const [emptyDataElementNotifications, setEmptyDataElementNotifications] = useState(<Spinner animation="grow" />)
    const [emptyDataElementCards, setEmptyDataElementCards] = useState(<Spinner animation="grow" />)
    const [emptyDataElementDirectMailSends, setEmptyDataElementDirectMailSends] = useState(<Spinner animation="grow" />)

    let { id } = useParams();
    
    useEffect(function () {
        async function loadUser (productId) {
            const responseGetUser = await api.get(`/user/${productId}`)
            if (responseGetUser.data.error) {
                alert('Erro ao buscar detalhes do usuário. Contate o suporte!')
                setTimeout(function () {
                    props.history.push('/dashboard')
                }, 2000)
            } else {
                setUser(responseGetUser.data.data.user)
                setUserBranch(responseGetUser.data.data.user.branch)

                if (responseGetUser.data.data.user.notifications.length === 0)
                    setEmptyDataElementNotifications(
                        <Alert variant="dark">
                            Não existem notificações.
                        </Alert>
                    )
                else
                    setUserNotifications(responseGetUser.data.data.user.notifications)
                
                if (responseGetUser.data.data.cards.length === 0)
                    setEmptyDataElementCards(
                        <Alert variant="dark">
                            Não existem cartões enviados.
                        </Alert>
                    )
                else {
                    const getCardsBeautiful = async () => {
                        return Promise.all(responseGetUser.data.data.cards.map((cardItem, index) => ({
                            validity: moment(cardItem.validity).locale('pt-br').format('D/M/YYYY'),
                            created_at: moment(cardItem.created_at).locale('pt-br').format('D/M/YYYY HH:mm'),
                            interest: returnYesOrNot(cardItem.interest),
                            entrance: returnYesOrNot(cardItem.entrance),
                            credit_card: returnYesOrNot(cardItem.credit_card),
                            carne: returnYesOrNot(cardItem.carne),
                            product: cardItem.product.name,
                            client: cardItem.client.name,
                            send_to: cardItem.send_to === 0 ? 'WhatsApp' : cardItem.send_to === 1 ? 'SMS' : cardItem.send_to === 2 ? 'E-mail' : '',
                            original_price: numberToReal(cardItem.original_price),
                            sight_price: numberToReal(cardItem.sight_price),
                            price_payment: numberToReal(cardItem.price_payment),
                            total_amount: numberToReal(cardItem.total_amount),
                            highlight_price: cardItem.highlight_price === 0 ? 'Preço À Vista' : cardItem.highlight_price === 1 ? 'Preço Parcela' : ''
                        })))
                    }
                    getCardsBeautiful().then((cardsBeautiful) => {
                        setUserCards(cardsBeautiful)
                    })
                }

                if (responseGetUser.data.data.direct_mail_sends.length === 0)
                    setEmptyDataElementDirectMailSends(
                        <Alert variant="dark">
                            Não existem malas diretas enviadas.
                        </Alert>
                    )
                else {
                    const getDirectMailSendsBeautiful = async () => {
                        return Promise.all(responseGetUser.data.data.direct_mail_sends.map((directMailSendItem, index) => ({
                            id: directMailSendItem.id,
                            name: directMailSendItem.name,
                            phone: directMailSendItem.phone,
                            validity: moment(directMailSendItem.validity).locale('pt-br').format('D/M/YYYY'),
                            created_at: moment(directMailSendItem.created_at).locale('pt-br').format('D/M/YYYY HH:mm'),                            
                            sended: returnYesOrNot(directMailSendItem.sended),
                            direct_mail: directMailSendItem.direct_mail !== null ? directMailSendItem.direct_mail.name : 'Não selecionada'
                        })))
                    }
                    getDirectMailSendsBeautiful().then((directMailSendsBeautiful) => {
                        console.log(directMailSendsBeautiful)
                        setUserDirectMailSends(directMailSendsBeautiful)
                    })
                }
            }
        }

        loadUser(id)
    }, [id, props.history])

    function returnYesOrNot(valueBool) {
        if (valueBool) 
            return 'Sim' 
        else
            return 'Não'
    }

    function returnTypeOfUser(mix) {
        if (mix === 0) return 'ADMIN'
        if (mix === 1) return 'EMPRESA'
        if (mix === 2) return 'VENDEDOR'
    }

    function numberToReal(numero) {
        var numero = parseFloat(numero).toFixed(2).split('.');
        numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    return (
        <>
            <CustomNavbar/>
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/dashboard')}
                            >
                                Dashboard
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/user-list')}
                            >
                                Listar Usuários
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Detalhamento Usuário: {user.name}</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <p><b>Ativo?:</b> {returnYesOrNot(user.active)}</p>
                        <p><b>Nome:</b> {user.name}</p>
                        <p><b>CPF:</b> {user.cpf}</p>
                        <p><b>E-mail:</b> {user.email}</p>
                        <p><b>Tipo Usuário:</b> {returnTypeOfUser(user.user_type)}</p>
                        <br />
                        <h4>Empresa</h4>
                        <p><b>E-mail:</b> {userBranch.email}</p>
                        <p><b>CNPJ:</b> {userBranch.cnpj}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Notificações</h3>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElementNotifications}                                
                                columns={
                                    [
                                        {
                                            name: 'ID',
                                            sortable: true,
                                            selector: 'id'
                                        },
                                        {
                                            name: 'Título',
                                            sortable: true,
                                            selector: 'title'
                                        },
                                        {
                                            name: 'Texto',
                                            sortable: true,
                                            selector: 'text'
                                        }
                                    ]
                                }
                                data={userNotifications}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Cartões</h3>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElementCards}                                
                                columns={
                                    [
                                        {
                                            name: 'ID',
                                            sortable: true,
                                            selector: 'id'
                                        },
                                        {
                                            name: 'Validade',
                                            sortable: true,
                                            selector: 'validity'
                                        },
                                        {
                                            name: 'Sem juros?',
                                            sortable: false,
                                            selector: 'interest'
                                        },
                                        {
                                            name: 'Sem entrada?',
                                            sortable: false,
                                            selector: 'entrance'
                                        },
                                        {
                                            name: 'No cartão?',
                                            sortable: false,
                                            selector: 'credit_card'
                                        },
                                        {
                                            name: 'No Carnê?',
                                            sortable: false,
                                            selector: 'carne'
                                        },
                                        {
                                            name: 'Produto',
                                            sortable: true,
                                            selector: 'product'
                                        },
                                        {
                                            name: 'Preço Original',
                                            sortable: false,
                                            selector: 'original_price'
                                        },
                                        {
                                            name: 'Preço À Vista',
                                            sortable: false,
                                            selector: 'sight_price'
                                        },
                                        {
                                            name: 'Preço À Prazo',
                                            sortable: false,
                                            selector: 'total_amount'
                                        },
                                        {
                                            name: 'Qtd. Parcelas',
                                            sortable: false,
                                            selector: 'payment_x'
                                        },
                                        {
                                            name: 'Preço Parcela',
                                            sortable: false,
                                            selector: 'price_payment'
                                        },
                                        {
                                            name: 'Nome Client',
                                            sortable: true,
                                            selector: 'client'
                                        },
                                        {
                                            name: 'Centro de Distribuição',
                                            sortable: true,
                                            selector: 'distribution_center'
                                        },
                                        {
                                            name: 'Frase de Chamada',
                                            sortable: false,
                                            selector: 'call_phrase'
                                        },
                                        {
                                            name: 'Destaque do preço',
                                            sortable: true,
                                            selector: 'highlight_price'
                                        },
                                        {
                                            name: 'Enviado por:',
                                            sortable: true,
                                            selector: 'send_to'
                                        },
                                        {
                                            name: 'Data do Envio',
                                            sortable: true,
                                            selector: 'created_at'
                                        }
                                    ]
                                }
                                data={userCards}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Malas Diretas</h3>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElementDirectMailSends}                                
                                columns={
                                    [
                                        {
                                            name: 'ID',
                                            sortable: true,
                                            selector: 'id'
                                        },
                                        {
                                            name: 'Nome',
                                            sortable: true,
                                            selector: 'name'
                                        },
                                        {
                                            name: 'Telefone',
                                            sortable: true,
                                            selector: 'phone'
                                        },
                                        {
                                            name: 'Validade',
                                            sortable: true,
                                            selector: 'validity'
                                        },
                                        {
                                            name: 'Enviado?',
                                            sortable: false,
                                            selector: 'sended'
                                        },
                                        {
                                            name: 'Mala Direta',
                                            sortable: true,
                                            selector: 'direct_mail'
                                        },
                                        {
                                            name: 'Data do Envio',
                                            sortable: true,
                                            selector: 'created_at'
                                        }
                                    ]
                                }
                                data={userDirectMailSends}
                            />
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(UserDetail)